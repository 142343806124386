import React from 'react';
import { CreditCard, Package } from 'lucide-react';

interface BillingSettingsProps {
  organization: any;
  onUpdate: () => void;
}

export default function BillingSettings({ organization }: BillingSettingsProps) {
  const plan = organization.settings?.billing?.plan || 'free';
  const trialEnds = organization.settings?.billing?.trialEnds;

  return (
    <div className="space-y-6">
      <div className="bg-white shadow-sm rounded-lg border border-gray-200">
        <div className="px-4 py-5 sm:p-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">Current Plan</h3>
          <div className="mt-4 flex items-center">
            <Package className="h-5 w-5 text-brand-600 mr-2" />
            <span className="text-sm font-medium text-gray-900 capitalize">{plan} Plan</span>
            {trialEnds && new Date(trialEnds) > new Date() && (
              <span className="ml-2 px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
                Trial ends {new Date(trialEnds).toLocaleDateString()}
              </span>
            )}
          </div>
        </div>
      </div>

      <div className="bg-white shadow-sm rounded-lg border border-gray-200">
        <div className="px-4 py-5 sm:p-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">Payment Method</h3>
          <div className="mt-4">
            <button className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500">
              <CreditCard className="-ml-1 mr-2 h-5 w-5 text-gray-400" />
              Add Payment Method
            </button>
          </div>
        </div>
      </div>

      <div className="bg-white shadow-sm rounded-lg border border-gray-200">
        <div className="px-4 py-5 sm:p-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">Usage & Limits</h3>
          <dl className="mt-4 grid grid-cols-1 gap-5 sm:grid-cols-3">
            <div className="px-4 py-5 bg-gray-50 shadow rounded-lg overflow-hidden sm:p-6">
              <dt className="text-sm font-medium text-gray-500 truncate">Members</dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">
                {organization.settings?.features?.maxUsers || 5}
              </dd>
            </div>
            <div className="px-4 py-5 bg-gray-50 shadow rounded-lg overflow-hidden sm:p-6">
              <dt className="text-sm font-medium text-gray-500 truncate">Projects</dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">
                {organization.settings?.features?.maxProjects || 10}
              </dd>
            </div>
            <div className="px-4 py-5 bg-gray-50 shadow rounded-lg overflow-hidden sm:p-6">
              <dt className="text-sm font-medium text-gray-500 truncate">Storage</dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">
                {organization.settings?.features?.storage || '5GB'}
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  );
}