import { useState, useEffect, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { Task } from '../../types/task';
import { useParams } from 'react-router-dom';

// Subtask cache
const subtaskCache = new Map<number, { data: Task[]; timestamp: number }>();
const CACHE_TTL = 30000; // 30 seconds

export function useTaskSubtasks(taskId: number) {
  const { getAccessTokenSilently } = useAuth0();
  const { token } = useParams<{ token?: string }>();
  const [subtasks, setSubtasks] = useState<Task[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchSubtasks = useCallback(async (skipCache = false) => {
    if (!taskId) return;

    console.log('[useTaskSubtasks] Fetching subtasks for task:', taskId);
    setIsLoading(true);
    setError(null);

    // Check cache first if not using magic link
    if (!token && !skipCache) {
      const cached = subtaskCache.get(taskId);
      if (cached && Date.now() - cached.timestamp < CACHE_TTL) {
        console.log('[useTaskSubtasks] Using cached subtasks');
        setSubtasks(cached.data);
        setIsLoading(false);
        return;
      }
    }

    try {
      let response;
      if (token) {
        // Use magic link endpoint
        response = await axios.get(
          `${import.meta.env.VITE_API_URL}/api/tasks/share/${token}/subtasks`
        );
      } else {
        // Use authenticated endpoint
        const authToken = await getAccessTokenSilently();
        response = await axios.get(
          `${import.meta.env.VITE_API_URL}/api/tasks/${taskId}/subtasks`,
          {
            headers: {
              Authorization: `Bearer ${authToken}`
            }
          }
        );
      }

      console.log('[useTaskSubtasks] Received subtasks:', response.data);

      // Update cache if not using magic link
      if (!token) {
        subtaskCache.set(taskId, {
          data: response.data,
          timestamp: Date.now()
        });
      }

      setSubtasks(response.data);
    } catch (error: any) {
      console.error('[useTaskSubtasks] Error fetching subtasks:', error);
      setError(error.response?.data?.message || 'Failed to fetch subtasks');
    } finally {
      setIsLoading(false);
    }
  }, [taskId, token, getAccessTokenSilently]);

  useEffect(() => {
    fetchSubtasks();
  }, [fetchSubtasks]);

  const refreshSubtasks = async () => {
    console.log('[useTaskSubtasks] Refreshing subtasks');
    return fetchSubtasks(true); // Skip cache when refreshing
  };

  const invalidateCache = () => {
    console.log('[useTaskSubtasks] Invalidating subtask cache');
    subtaskCache.delete(taskId);
  };

  return {
    subtasks,
    isLoading,
    error,
    refreshSubtasks,
    invalidateCache
  };
}