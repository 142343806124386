import { useMemo } from 'react';
import { useTaskList } from '../../../../hooks/task';
import { DashboardWidget } from '../../DashboardWidget';
import { PriorityTaskList } from './PriorityTaskList';
import { usePriorityTasks } from './hooks/usePriorityTasks';

export function PriorityTasksWidget() {
  const { tasks } = useTaskList();
  const { 
    highPriorityTasks,
    blockedTasks,
    overdueTasks
  } = usePriorityTasks(tasks);

  return (
    <DashboardWidget title="Priority Tasks">
      <div className="space-y-6">
        {highPriorityTasks.length > 0 && (
          <PriorityTaskList
            title="High Priority"
            tasks={highPriorityTasks}
            type="high-priority"
          />
        )}

        {blockedTasks.length > 0 && (
          <PriorityTaskList
            title="Blocked Tasks"
            tasks={blockedTasks}
            type="blocked"
          />
        )}

        {overdueTasks.length > 0 && (
          <PriorityTaskList
            title="Overdue Tasks"
            tasks={overdueTasks}
            type="overdue"
          />
        )}

        {highPriorityTasks.length === 0 && 
         blockedTasks.length === 0 && 
         overdueTasks.length === 0 && (
          <div className="text-center py-8 text-gray-500">
            No priority tasks at the moment
          </div>
        )}
      </div>
    </DashboardWidget>
  );
}