import { DatePicker } from '../../../../common/DatePicker';

interface DateRangeFilterProps {
  value?: {
    start: string;
    end: string;
  } | null;
  onChange: (value: { start: string; end: string } | null) => void;
}

export function DateRangeFilter({ value, onChange }: DateRangeFilterProps) {
  return (
    <div className="space-y-4">
      <h4 className="text-xs font-medium text-gray-500 uppercase tracking-wider">Due Date Range</h4>
      <div className="grid grid-cols-2 gap-4">
        <DatePicker
          value={value?.start ? new Date(value.start) : null}
          onChange={(date) => {
            if (!date) {
              onChange(null);
              return;
            }
            onChange({
              start: date.toISOString(),
              end: value?.end || date.toISOString()
            });
          }}
          placeholder="Start date"
        />
        <DatePicker
          value={value?.end ? new Date(value.end) : null}
          onChange={(date) => {
            if (!date || !value?.start) {
              onChange(null);
              return;
            }
            onChange({
              start: value.start,
              end: date.toISOString()
            });
          }}
          placeholder="End date"
          minDate={value?.start ? new Date(value.start) : undefined}
        />
      </div>
    </div>
  );
}