import { useMemo } from 'react';
import { isAfter } from 'date-fns';
import type { Task } from '../../../../../types/task';

export function usePriorityTasks(tasks: Task[]) {
  const highPriorityTasks = useMemo(() => {
    return tasks.filter(task => 
      task.priority === 'high' && 
      task.status !== 'completed'
    );
  }, [tasks]);

  const blockedTasks = useMemo(() => {
    return tasks.filter(task => 
      task.status === 'blocked'
    );
  }, [tasks]);

  const overdueTasks = useMemo(() => {
    const now = new Date();
    return tasks.filter(task => 
      task.status !== 'completed' &&
      task.dueDate &&
      isAfter(now, new Date(task.dueDate))
    );
  }, [tasks]);

  return {
    highPriorityTasks,
    blockedTasks,
    overdueTasks
  };
}