import { usePermissions } from '../../../../hooks/usePermissions';
import { StatusFilter } from './filters/StatusFilter';
import { PriorityFilter } from './filters/PriorityFilter';
import { AssigneeFilter } from './filters/AssigneeFilter';
import { SubtaskFilter } from './filters/SubtaskFilter';
import { DateRangeFilter } from './filters/DateRangeFilter';
import { X } from 'lucide-react';

interface FilterPanelProps {
  filters: any;
  onFilterChange: (filters: any) => void;
  onClose: () => void;
}

export function FilterPanel({
  filters,
  onFilterChange,
  onClose
}: FilterPanelProps) {
  const clearFilters = () => {
    onFilterChange({});
  };

  return (
    <div 
      className="w-96 bg-white rounded-lg shadow-lg ring-1 ring-black ring-opacity-5"
      onClick={e => e.stopPropagation()}
    >
      <div className="p-4">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-sm font-medium text-gray-900">Filters</h3>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-500"
          >
            <X className="w-5 h-5" />
          </button>
        </div>

        <div className="space-y-4">
          <StatusFilter
            value={filters.status}
            onChange={(status) => onFilterChange({ ...filters, status })}
          />

          <PriorityFilter
            value={filters.priority}
            onChange={(priority) => onFilterChange({ ...filters, priority })}
          />

          <AssigneeFilter
            value={filters.assignedTo}
            onChange={(assignedTo) => onFilterChange({ ...filters, assignedTo })}
          />

          <SubtaskFilter
            value={filters.showSubtasksOnly}
            onChange={(showSubtasksOnly) => onFilterChange({ ...filters, showSubtasksOnly })}
          />

          <DateRangeFilter
            value={filters.dueDate}
            onChange={(dueDate) => onFilterChange({ ...filters, dueDate })}
          />
        </div>

        {Object.keys(filters).length > 0 && (
          <div className="mt-4 pt-4 border-t border-gray-200">
            <button
              onClick={clearFilters}
              className="text-sm text-red-600 hover:text-red-700"
            >
              Clear all filters
            </button>
          </div>
        )}
      </div>
    </div>
  );
}