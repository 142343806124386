export type TaskPriority = 'low' | 'medium' | 'high';

export const TASK_PRIORITIES: Record<TaskPriority, {
  label: string;
  color: string;
  description: string;
  icon: string;
}> = {
  low: {
    label: 'Low',
    color: 'gray',
    description: 'Task can be completed when convenient',
    icon: 'arrow-down'
  },
  medium: {
    label: 'Medium',
    color: 'yellow',
    description: 'Task should be completed soon',
    icon: 'minus'
  },
  high: {
    label: 'High',
    color: 'red',
    description: 'Task requires immediate attention',
    icon: 'arrow-up'
  }
};