import React from 'react';
import { Bell, Mail, MessageSquare, Globe } from 'lucide-react';

export default function NotificationSettings() {
  const notificationCategories = [
    {
      id: 'email',
      name: 'Email Notifications',
      icon: Mail,
      description: 'Configure email notification preferences',
      settings: [
        { id: 'task_updates', name: 'Task Updates', enabled: true },
        { id: 'mentions', name: 'Mentions', enabled: true },
        { id: 'team_updates', name: 'Team Updates', enabled: false }
      ]
    },
    {
      id: 'push',
      name: 'Push Notifications',
      icon: Bell,
      description: 'Configure browser push notifications',
      settings: [
        { id: 'push_task_updates', name: 'Task Updates', enabled: false },
        { id: 'push_mentions', name: 'Mentions', enabled: true },
        { id: 'push_team_updates', name: 'Team Updates', enabled: true }
      ]
    },
    {
      id: 'in_app',
      name: 'In-App Notifications',
      icon: MessageSquare,
      description: 'Configure notifications within the application',
      settings: [
        { id: 'in_app_task_updates', name: 'Task Updates', enabled: true },
        { id: 'in_app_mentions', name: 'Mentions', enabled: true },
        { id: 'in_app_team_updates', name: 'Team Updates', enabled: true }
      ]
    }
  ];

  return (
    <div className="space-y-6">
      <div>
        <h2 className="text-lg font-medium text-gray-900">Notification Settings</h2>
        <p className="mt-1 text-sm text-gray-500">
          Manage how and when you receive notifications
        </p>
      </div>

      <div className="space-y-4">
        {notificationCategories.map((category) => (
          <div
            key={category.id}
            className="bg-white shadow-sm rounded-lg border border-gray-200 overflow-hidden"
          >
            <div className="px-6 py-4 border-b border-gray-200">
              <div className="flex items-center space-x-3">
                <category.icon className="h-5 w-5 text-brand-600" />
                <div>
                  <h3 className="text-sm font-medium text-gray-900">{category.name}</h3>
                  <p className="text-sm text-gray-500">{category.description}</p>
                </div>
              </div>
            </div>
            <div className="px-6 py-4">
              <div className="space-y-4">
                {category.settings.map((setting) => (
                  <div key={setting.id} className="flex items-center justify-between">
                    <div className="flex items-center">
                      <label
                        htmlFor={setting.id}
                        className="text-sm font-medium text-gray-700"
                      >
                        {setting.name}
                      </label>
                    </div>
                    <button
                      type="button"
                      className={`
                        ${setting.enabled ? 'bg-brand-600' : 'bg-gray-200'}
                        relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500
                      `}
                      role="switch"
                      aria-checked={setting.enabled}
                    >
                      <span
                        aria-hidden="true"
                        className={`
                          ${setting.enabled ? 'translate-x-5' : 'translate-x-0'}
                          pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200
                        `}
                      />
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}