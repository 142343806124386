import { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { User } from 'lucide-react';
import { CustomSelect } from '../../common/CustomSelect';

interface TaskAssigneeSelectProps {
  value: number | null;
  onChange: (value: number | null) => void;
  error?: string;
  isDisabled?: boolean;
  required?: boolean;
  isClearable?: boolean;
  className?: string;
  placeholder?: string; // Added placeholder prop
}

interface UserOption {
  id: number;
  email: string;
  firstName: string | null;
  lastName: string | null;
}

export function TaskAssigneeSelect({
  value,
  onChange,
  error,
  isDisabled,
  required,
  isClearable = false,
  className,
  placeholder = 'Select assignee' // Added default value
}: TaskAssigneeSelectProps) {
  const { getAccessTokenSilently } = useAuth0();
  const [users, setUsers] = useState<UserOption[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const token = await getAccessTokenSilently();
        const response = await axios.get(
          `${import.meta.env.VITE_API_URL}/api/users`,
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
        setUsers(response.data);
      } catch (error) {
        console.error('Error fetching users:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUsers();
  }, [getAccessTokenSilently]);

  const options = users.map(user => ({
    value: user.id,
    label: user.firstName && user.lastName 
      ? `${user.firstName} ${user.lastName}`
      : user.email,
    description: user.firstName && user.lastName ? user.email : undefined,
    icon: User
  }));

  return (
    <CustomSelect
      options={options}
      value={value || ''}
      onChange={(newValue) => onChange(newValue ? Number(newValue) : null)}
      placeholder={placeholder}
      label="Assign To"
      error={error}
      isDisabled={isDisabled}
      isLoading={isLoading}
      isSearchable
      isClearable={isClearable}
      required={required}
      className={className}
    />
  );
}