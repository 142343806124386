import React from 'react';
import { X } from 'lucide-react';

interface RolePermissionBadgeProps {
  permission: string;
  onRemove?: () => void;
  isEditable?: boolean;
}

export default function RolePermissionBadge({ 
  permission, 
  onRemove, 
  isEditable = false 
}: RolePermissionBadgeProps) {
  const getColorScheme = (permission: string) => {
    if (permission.startsWith('manage:')) return 'bg-purple-100 text-purple-800';
    if (permission.startsWith('create:')) return 'bg-green-100 text-green-800';
    if (permission.startsWith('read:')) return 'bg-blue-100 text-blue-800';
    if (permission.startsWith('update:')) return 'bg-yellow-100 text-yellow-800';
    return 'bg-red-100 text-red-800';
  };

  return (
    <span
      className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${getColorScheme(permission)}`}
    >
      {permission}
      {isEditable && onRemove && (
        <button
          onClick={onRemove}
          className="ml-1 hover:text-gray-500 focus:outline-none"
        >
          <X className="h-3 w-3" />
        </button>
      )}
    </span>
  );
}