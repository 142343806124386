import { Check, X } from 'lucide-react';
import { PlanType, SUBSCRIPTION_PLANS } from '../../types/subscription';

interface FeatureComparisonProps {
  currentPlan: PlanType;
}

export function FeatureComparison({ currentPlan }: FeatureComparisonProps) {
  const plans = Object.values(SUBSCRIPTION_PLANS);
  const allFeatures = Array.from(
    new Set(plans.flatMap(plan => Object.keys(plan.features)))
  );

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full divide-y divide-gray-200">
        <thead>
          <tr>
            <th className="py-3 pl-4 pr-3 text-left text-sm font-medium text-gray-900 sm:pl-6">
              Feature
            </th>
            {plans.map((plan) => (
              <th
                key={plan.id}
                className="px-3 py-3 text-center text-sm font-medium text-gray-900"
              >
                {plan.name}
                {plan.id === currentPlan && (
                  <span className="ml-2 text-brand-600">(Current)</span>
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          {allFeatures.map((feature) => (
            <tr key={feature}>
              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                {feature}
              </td>
              {plans.map((plan) => {
                const featureConfig = plan.features[feature];
                return (
                  <td
                    key={plan.id}
                    className="whitespace-nowrap px-3 py-4 text-sm text-center"
                  >
                    {featureConfig?.enabled ? (
                      featureConfig.limit ? (
                        <span className="text-gray-900">
                          {featureConfig.limit} {featureConfig.unit}
                        </span>
                      ) : (
                        <Check className="w-5 h-5 text-green-500 mx-auto" />
                      )
                    ) : (
                      <X className="w-5 h-5 text-gray-300 mx-auto" />
                    )}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}