import { useState } from 'react';
import { Loader2, AlertCircle } from 'lucide-react';
import Modal from '../../common/Modal';
import { Input } from '../../common/Input';
import type { Feature, FeatureSettings } from '../../../types/feature';

interface FeatureOverrideModalProps {
  isOpen: boolean;
  onClose: () => void;
  feature: Feature;
  settings: FeatureSettings;
  onSave: (enabled: boolean, limits?: Record<string, number>) => Promise<void>;
}

export function FeatureOverrideModal({
  isOpen,
  onClose,
  feature,
  settings,
  onSave
}: FeatureOverrideModalProps) {
  const [enabled, setEnabled] = useState(settings?.enabled ?? feature.defaultEnabled);
  const [limits, setLimits] = useState<Record<string, number>>(
    settings?.limits || {}
  );
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      await onSave(enabled, Object.keys(limits).length > 0 ? limits : undefined);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={`Override ${feature.name}`}
    >
      <form onSubmit={handleSubmit} className="space-y-6">
        {/* Enable/Disable Toggle */}
        <div>
          <label className="flex items-center">
            <input
              type="checkbox"
              checked={enabled}
              onChange={(e) => setEnabled(e.target.checked)}
              className="h-4 w-4 text-brand-600 focus:ring-brand-500 border-gray-300 rounded"
            />
            <span className="ml-2 text-sm text-gray-700">Enable {feature.name}</span>
          </label>
          {feature.required && !enabled && (
            <p className="mt-2 text-sm text-yellow-600 flex items-center">
              <AlertCircle className="w-4 h-4 mr-1" />
              This is a required feature. Disabling it may affect system functionality.
            </p>
          )}
        </div>

        {/* Feature Limits */}
        {feature.hasLimits && enabled && (
          <div>
            <h3 className="text-sm font-medium text-gray-700 mb-2">Custom Limits</h3>
            <div className="space-y-4">
              {Object.entries(feature.limits || {}).map(([key, config]) => (
                <Input
                  key={key}
                  type="number"
                  label={config.label}
                  value={limits[key] || config.default}
                  onChange={(e) => setLimits(prev => ({
                    ...prev,
                    [key]: parseInt(e.target.value)
                  }))}
                  min={config.min}
                  max={config.max}
                  required
                />
              ))}
            </div>
          </div>
        )}

        {/* Actions */}
        <div className="flex justify-end space-x-3">
          <button
            type="button"
            onClick={onClose}
            className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={isSubmitting}
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-brand-600 hover:bg-brand-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500 disabled:opacity-50"
          >
            {isSubmitting ? (
              <>
                <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                Saving...
              </>
            ) : (
              'Save Override'
            )}
          </button>
        </div>
      </form>
    </Modal>
  );
}