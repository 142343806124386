import React, { useState, useRef } from 'react';
import { Building, ChevronDown, Plus, Loader2 } from 'lucide-react';
import { useOrganizationContext } from '../context/OrganizationContext';
import { usePermissions } from '../hooks/usePermissions';
import CreateOrganizationModal from './CreateOrganizationModal';
import { useClickOutside } from '../hooks/useClickOutside';
import type { Organization } from '../types/organization';

export default function OrganizationSwitcher() {
  const { 
    organizations,
    currentOrganization,
    switchOrganization,
    isLoading
  } = useOrganizationContext();

  const { isOrgAdmin } = usePermissions();

  const [isParentDropdownOpen, setIsParentDropdownOpen] = useState(false);
  const [isSubDropdownOpen, setIsSubDropdownOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCreatingSubOrg, setIsCreatingSubOrg] = useState(false);
  const [isSwitching, setIsSwitching] = useState(false);

  // Create refs for both dropdowns
  const parentDropdownRef = useClickOutside(() => setIsParentDropdownOpen(false));
  const subDropdownRef = useClickOutside(() => setIsSubDropdownOpen(false));

  // Get parent organization and its sub-organizations
  const parentOrg = currentOrganization?.parentId 
    ? organizations.find(org => org.id === currentOrganization.parentId)
    : currentOrganization;
  
  const subOrganizations = organizations.filter(org => org.parentId === parentOrg?.id);

  const handleOrganizationSwitch = async (orgId: number) => {
    if (isSwitching || orgId === currentOrganization?.id) return;

    setIsSwitching(true);
    try {
      await switchOrganization(orgId);
      setIsParentDropdownOpen(false);
      setIsSubDropdownOpen(false);
    } catch (error) {
      console.error('Failed to switch organization:', error);
    } finally {
      setIsSwitching(false);
    }
  };

  // Render breadcrumb path
  const renderBreadcrumb = () => {
    if (!currentOrganization) return null;

    return (
      <div className="flex items-center space-x-2 text-sm text-gray-600 mr-4">
        <Building className="w-4 h-4" />
        <span>{parentOrg?.name}</span>
        {currentOrganization.parentId && (
          <>
            <span className="text-brand-600">›</span>
            <span className="text-brand-600">{currentOrganization.name}</span>
          </>
        )}
      </div>
    );
  };

  const renderOrganizationOption = (org: Organization) => (
    <button
      key={org.id}
      onClick={() => handleOrganizationSwitch(org.id)}
      disabled={isSwitching}
      className={`w-full text-left px-4 py-2 text-sm flex items-center space-x-2 ${
        org.id === currentOrganization?.id ? 'bg-brand-50 text-brand-600' : 'hover:bg-gray-50'
      } ${isSwitching ? 'opacity-50 cursor-not-allowed' : ''}`}
    >
      <Building className="w-4 h-4" />
      <span>{org.name}</span>
      {org.userOrganizations[0]?.role === 'owner' && (
        <span className="ml-auto text-xs text-gray-500">(Owner)</span>
      )}
    </button>
  );

  if (isLoading) {
    return (
      <div className="flex items-center space-x-2">
        <Loader2 className="w-5 h-5 text-brand-600 animate-spin" />
        <span className="text-sm text-gray-500">Loading...</span>
      </div>
    );
  }

  return (
    <div className="flex items-center">
      {/* Breadcrumb path */}
      {renderBreadcrumb()}

      <div className="flex items-center space-x-2">
        {/* Parent Organization Dropdown */}
        <div className="relative" ref={parentDropdownRef}>
          <button
            onClick={() => setIsParentDropdownOpen(!isParentDropdownOpen)}
            disabled={isSwitching}
            className="flex items-center space-x-2 px-4 py-2 text-sm bg-white border border-gray-200 rounded-lg hover:bg-gray-50 shadow-sm disabled:opacity-50 disabled:cursor-not-allowed"
          >
            <Building className="w-4 h-4 text-brand-600" />
            <span>{parentOrg?.name || 'Select Organization'}</span>
            <ChevronDown className="w-4 h-4 text-gray-400" />
          </button>

          {isParentDropdownOpen && (
            <div className="absolute z-10 mt-2 w-56 rounded-lg shadow-lg bg-white ring-1 ring-black ring-opacity-5">
              <div className="py-1">
                {organizations
                  .filter(org => !org.parentId)
                  .map(renderOrganizationOption)}
                {isOrgAdmin && (
                  <div className="border-t border-gray-100 mt-1">
                    <button
                      onClick={() => {
                        setIsModalOpen(true);
                        setIsCreatingSubOrg(false);
                        setIsParentDropdownOpen(false);
                      }}
                      className="w-full text-left px-4 py-2 text-sm text-brand-600 hover:bg-gray-50 flex items-center space-x-2"
                    >
                      <Plus className="w-4 h-4" />
                      <span>Create New Organization</span>
                    </button>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>

        {/* Sub-Organization Dropdown */}
        {parentOrg && (
          <div className="relative" ref={subDropdownRef}>
            <button
              onClick={() => setIsSubDropdownOpen(!isSubDropdownOpen)}
              disabled={isSwitching}
              className="flex items-center space-x-2 px-4 py-2 text-sm bg-white border border-gray-200 rounded-lg hover:bg-gray-50 shadow-sm disabled:opacity-50 disabled:cursor-not-allowed"
            >
              <Building className="w-4 h-4 text-brand-600" />
              <span className="text-gray-600">Sub-Org</span>
              <span className={currentOrganization?.parentId ? 'text-brand-600 font-medium' : ''}>
                {currentOrganization?.parentId ? currentOrganization.name : 'Select Sub-Organization'}
              </span>
              <ChevronDown className="w-4 h-4 text-gray-400" />
            </button>

            {isSubDropdownOpen && (
              <div className="absolute z-10 mt-2 w-56 rounded-lg shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                <div className="py-1">
                  {subOrganizations.map(renderOrganizationOption)}
                  {isOrgAdmin && (
                    <div className="border-t border-gray-100 mt-1">
                      <button
                        onClick={() => {
                          setIsModalOpen(true);
                          setIsCreatingSubOrg(true);
                          setIsSubDropdownOpen(false);
                        }}
                        className="w-full text-left px-4 py-2 text-sm text-brand-600 hover:bg-gray-50 flex items-center space-x-2"
                      >
                        <Plus className="w-4 h-4" />
                        <span>Add Sub-Organization</span>
                      </button>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      {/* Create Organization Modal */}
      <CreateOrganizationModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onOrganizationCreated={(newOrg) => {
          handleOrganizationSwitch(newOrg.id);
          setIsModalOpen(false);
        }}
        parentOrganization={isCreatingSubOrg ? parentOrg : undefined}
      />
    </div>
  );
}