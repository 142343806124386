import { AlertTriangle } from 'lucide-react';
import type { BottleneckData } from './types';

interface BottleneckAnalysisProps {
  data: BottleneckData[];
}

export function BottleneckAnalysis({ data }: BottleneckAnalysisProps) {
  return (
    <div>
      <h4 className="text-sm font-medium text-gray-700 mb-4">
        Bottleneck Analysis
      </h4>
      <div className="space-y-4">
        {data.map((bottleneck, index) => (
          <div 
            key={index}
            className="flex items-start space-x-3 p-3 bg-yellow-50 rounded-md"
          >
            <AlertTriangle className="w-5 h-5 text-yellow-500 flex-shrink-0 mt-0.5" />
            <div>
              <p className="text-sm font-medium text-yellow-800">
                {bottleneck.title}
              </p>
              <p className="text-sm text-yellow-700 mt-1">
                {bottleneck.description}
              </p>
              {bottleneck.recommendation && (
                <p className="text-sm text-yellow-700 mt-2">
                  Recommendation: {bottleneck.recommendation}
                </p>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}