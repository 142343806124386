export interface FeatureFlag {
  id: string;
  name: string;
  description: string;
  requiredRole: 'owner' | 'admin' | 'manager' | 'member';
  enabled: boolean;
}

export const FEATURES: Record<string, FeatureFlag> = {
  INVITE_USERS: {
    id: 'INVITE_USERS',
    name: 'User Invitations',
    description: 'Ability to invite new users to the organization',
    requiredRole: 'admin',
    enabled: true
  },
  MANAGE_ROLES: {
    id: 'MANAGE_ROLES',
    name: 'Role Management',
    description: 'Ability to manage user roles',
    requiredRole: 'owner',
    enabled: true
  },
  VIEW_ANALYTICS: {
    id: 'VIEW_ANALYTICS',
    name: 'Analytics Access',
    description: 'Access to organization analytics',
    requiredRole: 'manager',
    enabled: true
  },
  MANAGE_BILLING: {
    id: 'MANAGE_BILLING',
    name: 'Billing Management',
    description: 'Access to billing and subscription settings',
    requiredRole: 'owner',
    enabled: true
  }
};