import { User, Shield, Bell, Users } from 'lucide-react';
import { PermissionGate } from '../components/permissions';
import RolesAndPermissions from '../components/settings/RolesAndPermissions';
import UserRoleAssignments from '../components/settings/UserRoleAssignments';
import NotificationSettings from '../components/settings/NotificationSettings';
import UserProfile from '../components/settings/UserProfile';
import { useTabState } from '../hooks/useTabState';
import { USER_PERMISSIONS } from '../types/permission';
import { SettingsNavigation } from '../components/settings/SettingsNavigation';

export default function Settings() {
  const [activeTab, setActiveTab] = useTabState('settingsTab', 'profile');

  const renderContent = () => {
    switch (activeTab) {
      case 'profile':
        return <UserProfile />;
      case 'roles':
        return (
          <PermissionGate 
            permission={USER_PERMISSIONS.MANAGE_ROLES}
            fallback={
              <div className="text-center py-8 text-gray-500">
                You don't have permission to access this section.
              </div>
            }
          >
            <RolesAndPermissions />
          </PermissionGate>
        );
      case 'assignments':
        return (
          <PermissionGate 
            permission={USER_PERMISSIONS.MANAGE_USERS}
            fallback={
              <div className="text-center py-8 text-gray-500">
                You don't have permission to access this section.
              </div>
            }
          >
            <UserRoleAssignments />
          </PermissionGate>
        );
      case 'notifications':
        return <NotificationSettings />;
      default:
        return null;
    }
  };

  return (
    <div className="flex h-[calc(100vh-4rem)]">
      <SettingsNavigation
        activeTab={activeTab}
        onTabChange={setActiveTab}
      />
      <div className="flex-1 overflow-auto">
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
          {renderContent()}
        </div>
      </div>
    </div>
  );
}