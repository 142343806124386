import { useState, useMemo } from 'react';
import { isWithinInterval, startOfDay, endOfDay } from 'date-fns';
import { useTaskList } from '../../../../../hooks/task';
import { useUsers } from '../../../../../hooks/useUsers';
import type { TeamWorkloadData } from '../types';

export function useTeamWorkload() {
  const { tasks, isLoading: isTasksLoading } = useTaskList();
  const { users, isLoading: isUsersLoading } = useUsers();
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const workloadData = useMemo(() => {
    const filteredTasks = tasks.filter(task => {
      if (!startDate || !endDate) return true;
      if (!task.dueDate) return false;

      const taskDate = new Date(task.dueDate);
      return isWithinInterval(taskDate, {
        start: startOfDay(startDate),
        end: endOfDay(endDate)
      });
    });

    return users.map(user => {
      const userTasks = filteredTasks.filter(task => task.assignedTo === user.id);
      const totalTasks = userTasks.length;
      const completedTasks = userTasks.filter(task => task.status === 'completed').length;
      const percentage = totalTasks ? Math.round((completedTasks / totalTasks) * 100) : 0;

      return {
        user,
        totalTasks,
        completedTasks,
        percentage,
        overdueTasks: userTasks.filter(task => {
          if (!task.dueDate) return false;
          return new Date(task.dueDate) < new Date() && task.status !== 'completed';
        }).length
      };
    });
  }, [tasks, users, startDate, endDate]);

  return {
    workloadData,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    isLoading: isTasksLoading || isUsersLoading
  };
}