import { TaskAssigneeSelect } from '../../../selects/TaskAssigneeSelect';

interface AssigneeFilterProps {
  value?: number[];
  onChange: (value: number[]) => void;
}

export function AssigneeFilter({ value, onChange }: AssigneeFilterProps) {
  const handleChange = (assigneeId: number | null) => {
    onChange(assigneeId ? [assigneeId] : []);
  };

  return (
    <TaskAssigneeSelect
      value={value?.[0] || null}
      onChange={handleChange}
      placeholder="Filter by assignee"
      isClearable
    />
  );
}