interface SubtaskFilterProps {
    value?: boolean;
    onChange: (value: boolean) => void;
  }
  
  export function SubtaskFilter({ value = false, onChange }: SubtaskFilterProps) {
    return (
      <div>
        <label className="flex items-center">
          <input
            type="checkbox"
            checked={value}
            onChange={(e) => onChange(e.target.checked)}
            className="h-4 w-4 text-brand-600 focus:ring-brand-500 border-gray-300 rounded"
          />
          <span className="ml-2 text-sm text-gray-700">Show subtasks</span>
        </label>
      </div>
    );
  }