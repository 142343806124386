import { useState } from 'react';
import { AlertCircle, Loader2, Plus } from 'lucide-react';
import { cn } from '../../../../lib/utils';
import { TaskListProps } from './types';
import { TaskCard } from '../../TaskCard';
import { QuickSubtaskForm } from '../../QuickSubtaskForm';
import { PermissionGate } from '../../../permissions';
import { TASK_PERMISSIONS } from '../../../../types/permission';

export function TaskList({ 
  tasks, 
  onTaskClick, 
  onTaskEdit,
  onTaskDelete,
  isLoading, 
  error,
  className 
}: TaskListProps) {
  const [isAddingTask, setIsAddingTask] = useState(false);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center py-8">
        <Loader2 className="w-8 h-8 text-brand-600 animate-spin" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="rounded-md bg-red-50 p-4">
        <div className="flex">
          <AlertCircle className="h-5 w-5 text-red-400" />
          <div className="ml-3">
            <h3 className="text-sm font-medium text-red-800">{error}</h3>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={cn('space-y-4', className)}>
      {tasks.length > 0 ? (
        tasks.map((task) => (
          <TaskCard
            key={task.id}
            task={task}
            onClick={() => onTaskClick?.(task)}
            onDelete={() => onTaskDelete?.(task.id)}
          />
        ))
      ) : (
        <div className="text-center py-8">
          <p className="text-sm text-gray-500">No tasks found</p>
        </div>
      )}
    </div>
  );
}