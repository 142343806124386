import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { Save, Eye, AlertCircle, X, Mail, Code } from 'lucide-react';
import Editor from '@monaco-editor/react';

interface Template {
  id: string;
  name: string;
  content: string;
  subject: string;
  isCustom: boolean;
}

export default function EmailTemplates({ organization }: { organization: any }) {
  const { getAccessTokenSilently } = useAuth0();
  const [templates, setTemplates] = useState<Template[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<Template | null>(null);
  const [editedContent, setEditedContent] = useState('');
  const [editedSubject, setEditedSubject] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [isPreviewing, setIsPreviewing] = useState(false);
  const [error, setError] = useState('');
  const [previewHtml, setPreviewHtml] = useState('');
  const [testRecipient, setTestRecipient] = useState('');

  useEffect(() => {
    fetchTemplates();
  }, [organization.id]);

  const fetchTemplates = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await axios.get(
        `${import.meta.env.VITE_API_URL}/api/organizations/${organization.id}/email-templates`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setTemplates(response.data);
      if (response.data.length > 0 && !selectedTemplate) {
        setSelectedTemplate(response.data[0]);
        setEditedContent(response.data[0].content);
        setEditedSubject(response.data[0].subject);
      }
      setError('');
    } catch (error: any) {
      setError(error.response?.data?.message || 'Failed to fetch templates');
    } finally {
      setIsLoading(false);
    }
  };

  const handleTemplateSelect = async (templateId: string) => {
    const template = templates.find(t => t.id === templateId);
    if (!template) return;

    try {
      const token = await getAccessTokenSilently();
      const response = await axios.get(
        `${import.meta.env.VITE_API_URL}/api/organizations/${organization.id}/email-templates/${templateId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setSelectedTemplate(response.data);
      setEditedContent(response.data.content);
      setEditedSubject(response.data.subject);
      setError('');
    } catch (error: any) {
      setError(error.response?.data?.message || 'Failed to load template');
    }
  };

  const handleSave = async () => {
    if (!selectedTemplate) return;

    setIsSaving(true);
    setError('');

    try {
      const token = await getAccessTokenSilently();
      await axios.put(
        `${import.meta.env.VITE_API_URL}/api/organizations/${organization.id}/email-templates/${selectedTemplate.id}`,
        {
          content: editedContent,
          subject: editedSubject
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );
      await fetchTemplates();
    } catch (error: any) {
      setError(error.response?.data?.message || 'Failed to save template');
    } finally {
      setIsSaving(false);
    }
  };

  const handlePreview = async () => {
    if (!selectedTemplate) return;

    if (!testRecipient || !testRecipient.includes('@')) {
      setError('Please enter a valid email address for testing');
      return;
    }

    setIsPreviewing(true);
    setError('');

    try {
      const token = await getAccessTokenSilently();
      const response = await axios.post(
        `${import.meta.env.VITE_API_URL}/api/organizations/${organization.id}/email-templates/${selectedTemplate.id}/preview`,
        {
          testData: {
            organizationName: organization.name,
            userName: 'John Doe',
            email: testRecipient,
            inviterName: 'Jane Smith',
            inviteUrl: 'https://example.com/invite',
            resetUrl: 'https://example.com/reset',
            actionUrl: 'https://example.com/action'
          }
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );
      setPreviewHtml(response.data.preview);
    } catch (error: any) {
      setError(error.response?.data?.message || 'Failed to preview template');
    } finally {
      setIsPreviewing(false);
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-brand-600"></div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="bg-white shadow-sm rounded-lg border border-gray-200">
        <div className="px-4 py-5 sm:p-6">
          <div className="flex justify-between items-center mb-6">
            <h3 className="text-lg font-medium text-gray-900">Email Templates</h3>
            <div className="flex space-x-3">
              <div className="relative">
                <input
                  type="email"
                  value={testRecipient}
                  onChange={(e) => setTestRecipient(e.target.value)}
                  placeholder="Test recipient email"
                  className="block w-64 border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-brand-500 focus:border-brand-500 sm:text-sm"
                />
              </div>
              <button
                onClick={handlePreview}
                disabled={!selectedTemplate || isPreviewing || !testRecipient}
                className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500 disabled:opacity-50"
              >
                <Eye className="w-4 h-4 mr-2" />
                Preview
              </button>
              <button
                onClick={handleSave}
                disabled={!selectedTemplate || isSaving}
                className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-brand-600 hover:bg-brand-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500 disabled:opacity-50"
              >
                <Save className="w-4 h-4 mr-2" />
                Save Changes
              </button>
            </div>
          </div>

          <div className="grid grid-cols-4 gap-6">
            {/* Template List */}
            <div className="col-span-1 bg-gray-50 rounded-lg p-4">
              <h4 className="text-sm font-medium text-gray-900 mb-4">Templates</h4>
              <div className="space-y-2">
                {templates.map((template) => (
                  <button
                    key={template.id}
                    onClick={() => handleTemplateSelect(template.id)}
                    className={`w-full text-left px-4 py-2 rounded-md text-sm ${
                      selectedTemplate?.id === template.id
                        ? 'bg-brand-50 text-brand-700'
                        : 'text-gray-700 hover:bg-gray-100'
                    }`}
                  >
                    <div className="flex items-center">
                      <Mail className="w-4 h-4 mr-2" />
                      <span>{template.name}</span>
                    </div>
                    {template.isCustom && (
                      <span className="ml-6 text-xs text-gray-500">Custom</span>
                    )}
                  </button>
                ))}
              </div>
            </div>

            {/* Editor */}
            <div className="col-span-3">
              {selectedTemplate ? (
                <div className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Subject
                    </label>
                    <input
                      type="text"
                      value={editedSubject}
                      onChange={(e) => setEditedSubject(e.target.value)}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-brand-500 focus:border-brand-500 sm:text-sm"
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Template Content
                    </label>
                    <div className="border border-gray-300 rounded-md overflow-hidden">
                      <Editor
                        height="500px"
                        defaultLanguage="handlebars"
                        value={editedContent}
                        onChange={(value) => setEditedContent(value || '')}
                        options={{
                          minimap: { enabled: false },
                          scrollBeyondLastLine: false,
                          wordWrap: 'on'
                        }}
                      />
                    </div>
                  </div>

                  {error && (
                    <div className="rounded-md bg-red-50 p-4">
                      <div className="flex">
                        <AlertCircle className="h-5 w-5 text-red-400" />
                        <div className="ml-3">
                          <h3 className="text-sm font-medium text-red-800">
                            {error}
                          </h3>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div className="flex items-center justify-center h-full text-gray-500">
                  Select a template to edit
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Preview Modal */}
      {previewHtml && (
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full sm:p-6">
              <div className="absolute top-0 right-0 pt-4 pr-4">
                <button
                  onClick={() => setPreviewHtml('')}
                  className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
                >
                  <span className="sr-only">Close</span>
                  <X className="h-6 w-6" />
                </button>
              </div>

              <div className="sm:flex sm:items-start">
                <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                  <div className="flex justify-between items-center mb-4">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                      Template Preview
                    </h3>
                    <div className="text-sm text-gray-500">
                      Preview for: {testRecipient}
                    </div>
                  </div>
                  <div className="mt-4 bg-gray-50 p-4 rounded-lg">
                    <iframe
                      srcDoc={previewHtml}
                      className="w-full h-[500px] border-0"
                      title="Email Template Preview"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}