import { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import type { DashboardLayout } from '../types/dashboard';

// Default layout configuration
const DEFAULT_LAYOUT: DashboardLayout = [
  { id: 'task-overview', type: 'taskOverview', size: 'large', position: 0, visible: true },
  { id: 'my-tasks', type: 'myTasks', size: 'normal', position: 1, visible: true },
  { id: 'team-workload', type: 'teamWorkload', size: 'normal', position: 2, visible: true },
  { id: 'priority-tasks', type: 'priorityTasks', size: 'normal', position: 3, visible: true },
  { id: 'timeline', type: 'timeline', size: 'large', position: 4, visible: true },
  { id: 'recent-activity', type: 'recentActivity', size: 'normal', position: 5, visible: true },
  { id: 'analytics', type: 'analytics', size: 'normal', position: 6, visible: true },
  { id: 'custom-reports', type: 'customReports', size: 'normal', position: 7, visible: true },
  { id: 'comment-thread', type: 'commentThread', size: 'normal', position: 8, visible: true }
];

export function useDashboardLayout() {
  const { getAccessTokenSilently, user } = useAuth0();
  const [layout, setLayout] = useState<DashboardLayout>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  // Load layout from localStorage with user-specific key
  useEffect(() => {
    if (!user?.sub) return;

    const loadLayout = () => {
      try {
        const savedLayout = localStorage.getItem(`dashboardLayout_${user.sub}`);
        if (savedLayout) {
          setLayout(JSON.parse(savedLayout));
        } else {
          setLayout(DEFAULT_LAYOUT);
        }
        setError(null);
      } catch (error) {
        console.error('Failed to load dashboard layout:', error);
        setError('Failed to load dashboard layout');
        setLayout(DEFAULT_LAYOUT);
      } finally {
        setIsLoading(false);
      }
    };

    loadLayout();
  }, [user?.sub]);

  const updateLayout = async (newLayout: DashboardLayout) => {
    if (!user?.sub) throw new Error('User not authenticated');

    try {
      // Validate layout
      if (!Array.isArray(newLayout) || newLayout.length === 0) {
        throw new Error('Invalid layout format');
      }

      // Ensure all required widgets are present
      const hasAllWidgets = DEFAULT_LAYOUT.every(defaultWidget =>
        newLayout.some(widget => widget.id === defaultWidget.id)
      );
      if (!hasAllWidgets) {
        throw new Error('Missing required widgets');
      }

      // Save to localStorage with user-specific key
      localStorage.setItem(`dashboardLayout_${user.sub}`, JSON.stringify(newLayout));
      setLayout(newLayout);
      setError(null);
    } catch (error: any) {
      console.error('Failed to update layout:', error);
      setError(error.message || 'Failed to update dashboard layout');
      throw error;
    }
  };

  const resetLayout = async () => {
    if (!user?.sub) throw new Error('User not authenticated');

    try {
      localStorage.removeItem(`dashboardLayout_${user.sub}`);
      setLayout(DEFAULT_LAYOUT);
      setError(null);
    } catch (error: any) {
      console.error('Failed to reset layout:', error);
      setError(error.message || 'Failed to reset dashboard layout');
      throw error;
    }
  };

  const refreshLayout = async () => {
    if (!user?.sub) return;
    
    setIsLoading(true);
    try {
      const savedLayout = localStorage.getItem(`dashboardLayout_${user.sub}`);
      if (savedLayout) {
        setLayout(JSON.parse(savedLayout));
      } else {
        setLayout(DEFAULT_LAYOUT);
      }
      setError(null);
    } catch (error: any) {
      console.error('Failed to refresh layout:', error);
      setError('Failed to refresh dashboard layout');
      setLayout(DEFAULT_LAYOUT);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    layout,
    isLoading,
    error,
    updateLayout,
    resetLayout,
    refreshLayout
  };
}