import { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { PlanType } from '../types/subscription';

export function useSubscription() {
  const { getAccessTokenSilently } = useAuth0();
  const [subscription, setSubscription] = useState<any>(null);
  const [usage, setUsage] = useState<Record<string, number>>({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchSubscription = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await axios.get(
        `${import.meta.env.VITE_API_URL}/api/subscriptions`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setSubscription(response.data.subscription);
      setUsage(response.data.usage);
    } catch (error: any) {
      setError(error.response?.data?.message || 'Failed to fetch subscription');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchSubscription();
  }, [getAccessTokenSilently]);

  const updatePlan = async (plan: PlanType, paymentMethodId?: string) => {
    try {
      const token = await getAccessTokenSilently();
      await axios.put(
        `${import.meta.env.VITE_API_URL}/api/subscriptions`,
        { plan, paymentMethodId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );
      await fetchSubscription();
    } catch (error: any) {
      throw new Error(error.response?.data?.message || 'Failed to update subscription');
    }
  };

  return {
    subscription,
    usage,
    isLoading,
    error,
    updatePlan,
    refreshSubscription: fetchSubscription
  };
}