import { cn } from '../../../../../lib/utils';
import type { TeamWorkloadData } from '../types';

interface WorkloadCardProps {
  data: TeamWorkloadData;
}

export function WorkloadCard({ data }: WorkloadCardProps) {
  const { user, totalTasks, completedTasks, percentage, overdueTasks } = data;

  return (
    <div className="flex items-center space-x-4 p-4 bg-white rounded-lg border border-gray-200 hover:shadow-sm transition-shadow">
      <div className="flex-shrink-0">
        <div className="h-10 w-10 rounded-full bg-brand-100 flex items-center justify-center">
          <span className="text-sm font-medium text-brand-600">
            {user.firstName?.[0] || user.email[0].toUpperCase()}
          </span>
        </div>
      </div>
      <div className="flex-1 min-w-0">
        <p className="text-sm font-medium text-gray-900 truncate">
          {user.firstName && user.lastName 
            ? `${user.firstName} ${user.lastName}`
            : user.email}
        </p>
        <div className="flex items-center mt-2">
          <div className="flex-1 bg-gray-200 rounded-full h-2">
            <div
              className={cn(
                'h-2 rounded-full transition-all duration-300',
                percentage >= 80 ? 'bg-green-500' :
                percentage >= 50 ? 'bg-yellow-500' :
                'bg-brand-500'
              )}
              style={{ width: `${percentage}%` }}
            />
          </div>
          <span className="ml-3 text-xs text-gray-500 min-w-[90px] text-right">
            {completedTasks}/{totalTasks} tasks
          </span>
        </div>
        {overdueTasks > 0 && (
          <p className="mt-1 text-xs text-red-600">
            {overdueTasks} overdue {overdueTasks === 1 ? 'task' : 'tasks'}
          </p>
        )}
      </div>
    </div>
  );
}