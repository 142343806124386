import { useState } from 'react';
import { Filter } from 'lucide-react';
import { cn } from '../../../../lib/utils';
import { TaskFiltersProps } from './types';
import { FilterPanel } from './FilterPanel';
import { FilterOverlay } from './FilterOverlay';
import { useActiveFilters } from './hooks/useActiveFilters';
import { Z_INDEX } from '../../../../constants/zIndex';

export function TaskFilters({
  filters,
  onFilterChange,
  className
}: TaskFiltersProps) {
  const [isOpen, setIsOpen] = useState(false);
  const activeFiltersCount = useActiveFilters(filters);

  return (
    <div className={cn('relative', className)}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={cn(
          "inline-flex items-center px-3 py-2 border rounded-md text-sm font-medium",
          "relative",
          isOpen
            ? "border-brand-500 text-brand-700 bg-brand-50"
            : "border-gray-300 text-gray-700 bg-white hover:bg-gray-50"
        )}
        style={{ zIndex: isOpen ? Z_INDEX.DROPDOWN_TRIGGER : 'auto' }}
      >
        <Filter className="w-4 h-4 mr-2" />
        Filters
        {activeFiltersCount > 0 && (
          <span className="ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-brand-100 text-brand-800">
            {activeFiltersCount}
          </span>
        )}
      </button>

      {isOpen && (
        <div className="fixed inset-0" style={{ zIndex: Z_INDEX.DROPDOWN_OVERLAY }}>
          <div className="relative h-full">
            {/* Overlay */}
            <FilterOverlay onClose={() => setIsOpen(false)} />

            {/* Panel */}
            <div 
              className="absolute right-0 mt-2 max-h-[calc(100vh-200px)] overflow-y-auto"
              style={{ zIndex: Z_INDEX.DROPDOWN_CONTENT }}
            >
              <FilterPanel 
                filters={filters}
                onFilterChange={onFilterChange}
                onClose={() => setIsOpen(false)}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}