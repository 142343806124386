import { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import type { Activity } from '../types';

export function useActivityFeed() {
  const { getAccessTokenSilently } = useAuth0();
  const [activities, setActivities] = useState<Activity[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchActivities = async () => {
      try {
        const token = await getAccessTokenSilently();
        const response = await axios.get(
          `${import.meta.env.VITE_API_URL}/api/activities`,
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
        setActivities(response.data);
      } catch (error: any) {
        console.error('Failed to fetch activities:', error);
        setError(error.response?.data?.message || 'Failed to load activities');
      } finally {
        setIsLoading(false);
      }
    };

    fetchActivities();
  }, [getAccessTokenSilently]);

  return { activities, isLoading, error };
}