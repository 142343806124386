import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { format } from 'date-fns';
import { DashboardWidget } from '../../DashboardWidget';
import { ActivityItem } from './ActivityItem';
import { useActivityFeed } from './hooks/useActivityFeed';
import type { Activity } from './types';

export function RecentActivityWidget() {
  const { activities, isLoading } = useActivityFeed();

  return (
    <DashboardWidget title="Recent Activity">
      <div className="space-y-4">
        {isLoading ? (
          <div className="animate-pulse space-y-4">
            {[...Array(5)].map((_, i) => (
              <div key={i} className="flex space-x-3">
                <div className="h-8 w-8 bg-gray-200 rounded-full" />
                <div className="flex-1 space-y-2">
                  <div className="h-4 bg-gray-200 rounded w-3/4" />
                  <div className="h-3 bg-gray-200 rounded w-1/2" />
                </div>
              </div>
            ))}
          </div>
        ) : (
          activities.map((activity) => (
            <ActivityItem key={activity.id} activity={activity} />
          ))
        )}
      </div>
    </DashboardWidget>
  );
}