import React from 'react';
import { ArrowDown, ArrowUp, Minus } from 'lucide-react';
import { CustomSelect } from '../../common/CustomSelect';
import { TaskPriority, TASK_PRIORITIES } from '../../../types/task';

interface TaskPrioritySelectProps {
  value: TaskPriority | null;
  onChange: (value: TaskPriority | null) => void;
  error?: string;
  isDisabled?: boolean;
  required?: boolean;
  isMulti?: boolean;
  selectedValues?: TaskPriority[];
  label?: string;
}

const priorityIcons = {
  low: ArrowDown,
  medium: Minus,
  high: ArrowUp
};

export function TaskPrioritySelect({
  value,
  onChange,
  error,
  isDisabled,
  required,
  isMulti = false,
  selectedValues = [],
  label = 'Priority'
}: TaskPrioritySelectProps) {
  const options = Object.entries(TASK_PRIORITIES).map(([priority, config]) => ({
    value: priority,
    label: config.label,
    description: config.description,
    icon: priorityIcons[priority as TaskPriority]
  }));

  return (
    <CustomSelect
      options={options}
      value={isMulti ? selectedValues : (value || '')}
      onChange={(newValue) => {
        if (isMulti) {
          onChange(newValue as TaskPriority);
        } else {
          onChange(newValue as TaskPriority);
        }
      }}
      placeholder="Select priority"
      label={label}
      error={error}
      isDisabled={isDisabled}
      required={required}
      isMulti={isMulti}
    />
  );
}