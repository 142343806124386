import { PermissionType } from './permission';

export type UserRole = 'owner' | 'admin' | 'manager' | 'member';

export interface RoleDefinition {
  name: string;
  level: number;
  description: string;
  permissions: PermissionType[];
}

export const ROLES: Record<UserRole, RoleDefinition> = {
  owner: {
    name: 'owner',
    level: 100,
    description: 'Full access and ownership of the organization',
    permissions: [
      // Task Permissions
      'create:task',
      'read:task:all',
      'read:task:assigned',
      'update:task:all',
      'update:task:assigned',
      'delete:task:all',
      'delete:task:assigned',
      'manage:task_settings',
      // Subtask Permissions
      'create:subtask',
      'read:subtask:all',
      'read:subtask:assigned',
      'update:subtask:all',
      'update:subtask:assigned',
      'delete:subtask:all',
      'delete:subtask:assigned',
      // User Management
      'manage:users',
      'manage:roles',
      'invite:users',
      'read:users',
      // Organization Management
      'manage:organization',
      'manage:settings',
      'manage:billing',
      'read:organization',
      // Custom Fields
      'manage:custom_fields',
      'read:custom_fields',
      // Automation
      'manage:automation',
      'read:automation',
      'execute:automation',
      // Reports
      'view:reports',
      'export:reports',
      'create:reports'
    ]
  },
  admin: {
    name: 'admin',
    level: 80,
    description: 'Administrative access with user management',
    permissions: [
      // Task Permissions
      'create:task',
      'read:task:all',
      'read:task:assigned',
      'update:task:all',
      'update:task:assigned',
      'delete:task:all',
      'delete:task:assigned',
      'manage:task_settings',
      // Subtask Permissions
      'create:subtask',
      'read:subtask:all',
      'read:subtask:assigned',
      'update:subtask:all',
      'update:subtask:assigned',
      'delete:subtask:all',
      'delete:subtask:assigned',
      // User Management
      'manage:users',
      'invite:users',
      'read:users',
      // Organization Management
      'manage:settings',
      'read:organization',
      // Custom Fields
      'read:custom_fields',
      // Reports
      'view:reports',
      'export:reports'
    ]
  },
  manager: {
    name: 'manager',
    level: 60,
    description: 'Team management and project oversight',
    permissions: [
      // Task Permissions
      'create:task',
      'read:task:all',
      'read:task:assigned',
      'update:task:all',
      'update:task:assigned',
      'delete:task:assigned',
      // Subtask Permissions
      'create:subtask',
      'read:subtask:all',
      'read:subtask:assigned',
      'update:subtask:all',
      'update:subtask:assigned',
      'delete:subtask:assigned',
      // User Management
      'invite:users',
      'read:users',
      // Organization
      'read:organization',
      // Custom Fields
      'read:custom_fields',
      // Reports
      'view:reports'
    ]
  },
  member: {
    name: 'member',
    level: 40,
    description: 'Basic member access',
    permissions: [
      // Task Permissions
      'create:task',
      'read:task:assigned',
      'update:task:assigned',
      // Subtask Permissions
      'create:subtask',
      'read:subtask:assigned',
      'update:subtask:assigned',
      // User Management
      'read:users',
      // Basic Access
      'read:organization',
      'read:custom_fields'
    ]
  }
};

export function hasRole(userRole: UserRole, requiredRole: UserRole): boolean {
  const userRoleLevel = ROLES[userRole]?.level || 0;
  const requiredRoleLevel = ROLES[requiredRole]?.level || 0;
  return userRoleLevel >= requiredRoleLevel;
}