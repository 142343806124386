import React from 'react';
import { cn } from '../../../../lib/utils';
import { KanbanColumnProps } from './types';
import { TaskCard } from '../../TaskCard';
import { TASK_STATUSES } from '../../../../types/task';

export function KanbanColumn({
  status,
  tasks,
  onDrop,
  onTaskClick,
  isDraggingOver
}: KanbanColumnProps) {
  const statusConfig = TASK_STATUSES[status];

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    onDrop(parseInt(e.dataTransfer.getData('taskId')));
  };

  return (
    <div
      className="flex-1 flex flex-col min-w-[300px]"
      onDragOver={handleDragOver}
    >
      {/* Column Header */}
      <div className="flex items-center justify-between mb-4 p-2">
        <div className="flex items-center space-x-2">
          <span
            className={cn(
              'w-2 h-2 rounded-full',
              `bg-${statusConfig.color}-500`
            )}
          />
          <h3 className="text-sm font-medium text-gray-900">
            {statusConfig.label}
          </h3>
          <span className="text-sm text-gray-500">
            ({tasks.length})
          </span>
        </div>
      </div>

      {/* Tasks Container */}
      <div 
        className={cn(
          'flex-1 overflow-y-auto space-y-3 p-2 rounded-lg',
          isDraggingOver && 'bg-gray-50 border-2 border-dashed border-gray-200'
        )}
      >
        {tasks.map((task) => (
          <TaskCard
            key={task.id}
            task={task}
            onClick={() => onTaskClick?.(task)}
            className="cursor-move"
          />
        ))}
        {tasks.length === 0 && (
          <div className="text-sm text-gray-500 text-center py-4">
            No tasks
          </div>
        )}
      </div>
    </div>
  );
}