import { TaskPrioritySelect } from '../../../selects/TaskPrioritySelect';
import { TaskPriority } from '../../../../../types/task';

interface PriorityFilterProps {
  value?: TaskPriority[];
  onChange: (value: TaskPriority[]) => void;
}

export function PriorityFilter({ value = [], onChange }: PriorityFilterProps) {
  return (
    <TaskPrioritySelect
      value={null}
      onChange={(priority) => {
        if (!priority) return;
        const newValue = value.includes(priority as TaskPriority)
          ? value.filter(p => p !== priority)
          : [...value, priority as TaskPriority];
        onChange(newValue);
      }}
      isMulti
      selectedValues={value}
      label="Filter by Priority"
    />
  );
}