import { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { TaskComment } from '../../types/task/comment.types';
import { useParams } from 'react-router-dom';

export function useTaskComments(taskId: number) {
  const { getAccessTokenSilently } = useAuth0();
  const { token } = useParams<{ token?: string }>();
  const [comments, setComments] = useState<TaskComment[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchComments = async () => {
      setIsLoading(true);
      setError(null);

      try {
        let response;
        if (token) {
          // Use magic link endpoint
          response = await axios.get(
            `${import.meta.env.VITE_API_URL}/api/tasks/share/${token}/comments`
          );
        } else {
          // Use authenticated endpoint
          const authToken = await getAccessTokenSilently();
          response = await axios.get(
            `${import.meta.env.VITE_API_URL}/api/tasks/${taskId}/comments`,
            {
              headers: {
                Authorization: `Bearer ${authToken}`
              }
            }
          );
        }
        setComments(response.data);
      } catch (error: any) {
        setError(error.response?.data?.message || 'Failed to fetch comments');
        console.error('Error fetching comments:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchComments();
  }, [taskId, token, getAccessTokenSilently]);

  const addComment = async (content: string, userName?: string, userEmail?: string) => {
    try {
      let response;
      if (token) {
        // Use magic link endpoint
        response = await axios.post(
          `${import.meta.env.VITE_API_URL}/api/tasks/share/${token}/comments`,
          { content, userName, userEmail }
        );
      } else {
        // Use authenticated endpoint
        const authToken = await getAccessTokenSilently();
        response = await axios.post(
          `${import.meta.env.VITE_API_URL}/api/tasks/${taskId}/comments`,
          { content },
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
              'Content-Type': 'application/json'
            }
          }
        );
      }
      setComments(prev => [response.data, ...prev]);
      return response.data;
    } catch (error: any) {
      throw new Error(error.response?.data?.message || 'Failed to add comment');
    }
  };

  return {
    comments,
    isLoading,
    error,
    addComment
  };
}