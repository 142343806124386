import { cn } from '../../../lib/utils';
import { SelectOption } from './types';

interface SelectOptionsProps {
  options: SelectOption[];
  selectedOptions: SelectOption | SelectOption[];
  onSelect: (option: SelectOption) => void;
  isMulti: boolean;
  menuPlacement: 'top' | 'bottom' | 'auto';
  noOptionsMessage: string;
}

export function SelectOptions({
  options,
  selectedOptions,
  onSelect,
  isMulti,
  menuPlacement,
  noOptionsMessage
}: SelectOptionsProps) {
  return (
    <div 
      className={cn(
        'absolute w-full rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5',
        'z-[51]', // Higher than the select button
        menuPlacement === 'top' ? 'bottom-full mb-1' : 'top-full mt-1'
      )}
      style={{ maxHeight: '300px', overflowY: 'auto' }}
    >
      <ul className="py-1 text-base sm:text-sm">
        {options.length > 0 ? (
          options.map((option) => {
            const isSelected = isMulti
              ? Array.isArray(selectedOptions) && selectedOptions.some(opt => opt.value === option.value)
              : option.value === (selectedOptions as SelectOption)?.value;

            return (
              <li
                key={option.value}
                onClick={() => onSelect(option)}
                className={cn(
                  'relative cursor-pointer select-none py-2 pl-3 pr-9',
                  isSelected ? 'bg-brand-100 text-brand-900' : 'hover:bg-gray-50'
                )}
              >
                <div className="flex items-center">
                  {option.icon && (
                    <option.icon className="h-5 w-5 text-gray-400 mr-2" />
                  )}
                  <div>
                    <span className={cn(
                      'block truncate',
                      isSelected && 'font-semibold'
                    )}>
                      {option.label}
                    </span>
                    {option.description && (
                      <span className="block truncate text-gray-500 text-xs">
                        {option.description}
                      </span>
                    )}
                  </div>
                </div>
              </li>
            );
          })
        ) : (
          <li className="relative cursor-default select-none py-2 pl-3 pr-9 text-gray-500">
            {noOptionsMessage}
          </li>
        )}
      </ul>
    </div>
  );
}