export type TaskStatus = 'todo' | 'in_progress' | 'blocked' | 'completed';

export const TASK_STATUSES: Record<TaskStatus, {
  label: string;
  color: string;
  description: string;
}> = {
  todo: {
    label: 'To Do',
    color: 'gray',
    description: 'Task has not been started'
  },
  in_progress: {
    label: 'In Progress',
    color: 'blue',
    description: 'Task is currently being worked on'
  },
  blocked: {
    label: 'Blocked',
    color: 'red',
    description: 'Task is blocked by dependencies or issues'
  },
  completed: {
    label: 'Completed',
    color: 'green',
    description: 'Task has been completed'
  }
};