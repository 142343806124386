import { Line } from 'react-chartjs-2';
import { format } from 'date-fns';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js';
import type { CompletionTrendData } from './types';

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

interface CompletionTrendProps {
  data: CompletionTrendData[];
}

export function CompletionTrend({ data }: CompletionTrendProps) {
  const chartData = {
    labels: data.map(d => format(new Date(d.date), 'MMM d')),
    datasets: [
      {
        label: 'Tasks Completed',
        data: data.map(d => d.completed),
        borderColor: 'rgb(56, 48, 245)',
        backgroundColor: 'rgba(56, 48, 245, 0.1)',
        fill: true,
        tension: 0.4
      }
    ]
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        mode: 'index' as const,
        intersect: false
      }
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 1
        }
      }
    }
  };

  return (
    <div>
      <h4 className="text-sm font-medium text-gray-700 mb-4">
        Task Completion Trend
      </h4>
      <div className="h-48">
        <Line data={chartData} options={options} />
      </div>
    </div>
  );
}