import React, { useState } from 'react';
import { Shield, Pencil, Trash2, Check, X } from 'lucide-react';
import { RoleDefinition } from '../../types/auth';
import RolePermissionEditor from './RolePermissionEditor';
import FeatureGate from '../FeatureGate';

interface RoleCardProps {
  role: RoleDefinition;
  permissions: {
    tasks: string[];
    users: string[];
    roles: string[];
  };
  selectedPermissions: string[];
  onUpdatePermissions: (permissions: string[]) => void;
  onDelete?: () => void;
}

export default function RoleCard({
  role,
  permissions,
  selectedPermissions,
  onUpdatePermissions,
  onDelete
}: RoleCardProps) {
  const [isEditing, setIsEditing] = useState(false);
  const [editedPermissions, setEditedPermissions] = useState(selectedPermissions);

  const handlePermissionToggle = (permission: string) => {
    const newPermissions = editedPermissions.includes(permission)
      ? editedPermissions.filter(p => p !== permission)
      : [...editedPermissions, permission];
    setEditedPermissions(newPermissions);
  };

  const handleSave = () => {
    onUpdatePermissions(editedPermissions);
    setIsEditing(false);
  };

  const handleCancel = () => {
    setEditedPermissions(selectedPermissions);
    setIsEditing(false);
  };

  return (
    <div className="bg-white shadow-sm rounded-lg border border-gray-200 overflow-hidden">
      <div className="px-6 py-4 border-b border-gray-200 flex justify-between items-center">
        <div className="flex items-center space-x-3">
          <Shield className="h-5 w-5 text-brand-600" />
          <div>
            <h3 className="text-sm font-medium text-gray-900">
              {role.name.charAt(0).toUpperCase() + role.name.slice(1)}
            </h3>
            <p className="text-sm text-gray-500">{role.description}</p>
          </div>
        </div>
        <FeatureGate feature="MANAGE_ROLES">
          <div className="flex items-center space-x-2">
            {isEditing ? (
              <>
                <button
                  onClick={handleSave}
                  className="p-1 text-green-600 hover:text-green-700"
                >
                  <Check className="w-4 h-4" />
                </button>
                <button
                  onClick={handleCancel}
                  className="p-1 text-gray-400 hover:text-gray-500"
                >
                  <X className="w-4 h-4" />
                </button>
              </>
            ) : (
              <>
                <button
                  onClick={() => setIsEditing(true)}
                  className="p-1 text-gray-400 hover:text-gray-500"
                >
                  <Pencil className="w-4 h-4" />
                </button>
                {role.name !== 'owner' && onDelete && (
                  <button
                    onClick={onDelete}
                    className="p-1 text-gray-400 hover:text-red-500"
                  >
                    <Trash2 className="w-4 h-4" />
                  </button>
                )}
              </>
            )}
          </div>
        </FeatureGate>
      </div>
      <div className="px-6 py-4">
        <div className="space-y-4">
          {Object.entries(permissions).map(([category, perms]) => (
            <RolePermissionEditor
              key={category}
              categoryName={category}
              permissions={perms}
              selectedPermissions={editedPermissions.filter(p => 
                perms.includes(p)
              )}
              onPermissionToggle={handlePermissionToggle}
            />
          ))}
        </div>
      </div>
    </div>
  );
}