import { Navigate, useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import LoadingScreen from './LoadingScreen';

interface ProtectedRouteProps {
  children: React.ReactNode;
}

export default function ProtectedRoute({ children }: ProtectedRouteProps) {
  const { isAuthenticated, isLoading, user } = useAuth0();
  const location = useLocation();

  console.log('[ProtectedRoute] Auth state:', {
    isAuthenticated,
    isLoading,
    path: location.pathname,
    user: {
      email: user?.email,
      role: user?.['https://auth-sync-api/role'],
      permissions: user?.['https://auth-sync-api/permissions'],
      organizationId: user?.['https://auth-sync-api/organizationId'],
      isProfileCompleted: user?.['https://auth-sync-api/isProfileCompleted'],
      needsOnboarding: user?.['https://auth-sync-api/needsOnboarding']
    }
  });

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated) {
    console.log('[ProtectedRoute] User not authenticated, redirecting to login');
    return <Navigate to="/login" state={{ from: location.pathname }} replace />;
  }

  // Check if this is an invited user with a token
  const invitationToken = user?.['https://auth-sync-api/invitationToken'];
  if (invitationToken && location.pathname !== '/accept-invite') {
    console.log('[ProtectedRoute] User has invitation token, redirecting to accept invite');
    return <Navigate to={`/accept-invite?token=${invitationToken}`} replace />;
  }

  // Check if user needs onboarding
  const needsOnboarding = user?.['https://auth-sync-api/needsOnboarding'] === true;
  if (needsOnboarding && location.pathname !== '/onboarding') {
    console.log('[ProtectedRoute] User needs onboarding, redirecting to onboarding');
    return <Navigate to="/onboarding" replace />;
  }

  console.log('[ProtectedRoute] Access granted to:', location.pathname);
  return <>{children}</>;
}