import { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { Task } from '../../types/task';
import { usePermissions } from '../usePermissions';

interface TaskFilters {
  status?: string[];
  priority?: string[];
  assignedTo?: number[];
  showSubtasksOnly?: boolean;
  assignedToMe?: boolean;
  search?: string;
  dueDate?: { start: string; end: string };
}

// Cache key generator
const generateCacheKey = (filters?: TaskFilters, userRole?: string) => {
  return JSON.stringify({ filters, userRole });
};

// Task cache
const taskCache = new Map<string, { data: Task[]; timestamp: number }>();
const CACHE_TTL = 30000; // 30 seconds

export function useTaskList(filters?: TaskFilters) {
  const { getAccessTokenSilently } = useAuth0();
  const { userRole, userId } = usePermissions();
  const [tasks, setTasks] = useState<Task[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchTasks = async (skipCache = false) => {
    console.log('[useTaskList] Fetching tasks with filters:', { filters, userRole, userId });
    setIsLoading(true);
    setError(null);

    // Check cache first
    const cacheKey = generateCacheKey(filters, userRole);
    if (!skipCache) {
      const cached = taskCache.get(cacheKey);
      if (cached && Date.now() - cached.timestamp < CACHE_TTL) {
        console.log('[useTaskList] Using cached tasks');
        setTasks(cached.data);
        setIsLoading(false);
        return;
      }
    }

    try {
      const token = await getAccessTokenSilently();
      const params = new URLSearchParams();

      // Process standard filters
      if (filters) {
        Object.entries(filters).forEach(([key, value]) => {
          if (!value) return;
          
          if (Array.isArray(value)) {
            value.forEach(v => params.append(key, v.toString()));
          } else if (typeof value === 'object' && value !== null) {
            params.append(key, JSON.stringify(value));
          } else {
            params.append(key, value.toString());
          }
        });
      }

      // Handle subtasks and assigned tasks filters
      if (filters?.showSubtasksOnly) {
        params.append('type', 'subtasks');
      } else if (!filters?.assignedToMe) {
        // Only show parent tasks by default
        params.append('parentId', 'null');
      }

      // For member role, add filters for created or assigned tasks
      if (userRole === 'member' && userId) {
        params.append('memberFilter', 'true');
        params.append('userId', userId.toString());
      }

      // Add assigned to me filter
      if (filters?.assignedToMe) {
        params.append('assignedToMe', 'true');
      }

      const response = await axios.get(
        `${import.meta.env.VITE_API_URL}/api/tasks?${params.toString()}`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      // Update cache
      taskCache.set(cacheKey, {
        data: response.data,
        timestamp: Date.now()
      });

      setTasks(response.data);
    } catch (error: any) {
      console.error('[useTaskList] Error fetching tasks:', error);
      setError(error.response?.data?.message || 'Failed to fetch tasks');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchTasks();
  }, [
    getAccessTokenSilently,
    JSON.stringify(filters),
    userRole,
    userId
  ]);

  const refreshTasks = async () => {
    console.log('[useTaskList] Refreshing tasks');
    return fetchTasks(true); // Skip cache when refreshing
  };

  const invalidateCache = () => {
    console.log('[useTaskList] Invalidating task cache');
    taskCache.clear();
  };

  return { 
    tasks, 
    isLoading, 
    error,
    refreshTasks,
    invalidateCache
  };
}