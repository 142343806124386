import { useForm, Controller } from 'react-hook-form';
import { useAuth0 } from '@auth0/auth0-react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Loader2 } from 'lucide-react';
import { TaskFormSchema } from './schema';
import { TaskFormProps, TaskFormData } from './types';
import { TaskStatusSelect, TaskPrioritySelect, TaskAssigneeSelect, TaskVisibilitySelect } from '../selects';
import { DatePicker } from '../../common/DatePicker';
import { CustomFieldsSection } from '../CustomFieldsSection';
import { useCustomFields } from '../../../hooks/task';
import { cn } from '../../../lib/utils';

export function TaskForm({
  onSubmit,
  onCancel,
  initialData,
  isSubmitting = false
}: TaskFormProps) {
  const { user } = useAuth0();
  const organizationId = user?.['https://auth-sync-api/organizationId'] as number;
  const { customFields, isLoading: isLoadingCustomFields } = useCustomFields(organizationId, true);
  
  const {
    register,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<TaskFormData>({
    resolver: zodResolver(TaskFormSchema),
    defaultValues: {
      title: initialData?.title || '',
      description: initialData?.description || '',
      status: initialData?.status || 'todo',
      priority: initialData?.priority || 'medium',
      visibility: initialData?.visibility || 'public',
      dueDate: initialData?.dueDate || null,
      assignedTo: initialData?.assignedTo || null,
      customFields: initialData?.customFieldValues || {}
    }
  });

  const onFormSubmit = handleSubmit(async (data) => {
    await onSubmit({
      ...data,
      customFieldValues: data.customFields
    });
  });

  const inputClasses = cn(
    "block w-full rounded-md shadow-sm sm:text-sm",
    "border border-gray-300 focus:border-brand-500 focus:ring-brand-500",
    "placeholder:text-gray-400",
    "h-10 py-2 px-3"
  );

  const isEditing = !!initialData;

  return (
    <form onSubmit={onFormSubmit} className="space-y-6">
      {/* Title */}
      <div>
        <label htmlFor="title" className="block text-sm font-medium text-gray-700 mb-1">
          Title
          <span className="text-red-500 ml-1">*</span>
        </label>
        <input
          {...register('title')}
          id="title"
          type="text"
          placeholder="Enter a clear and concise task title"
          className={cn(inputClasses, isEditing && 'bg-gray-100 text-gray-500 cursor-not-allowed')}
          disabled={isEditing}
        />
        {errors.title?.message && (
          <p className="mt-1 text-sm text-red-600">{errors.title.message}</p>
        )}
      </div>

      {/* Description */}
      <div>
        <label htmlFor="description" className="block text-sm font-medium text-gray-700 mb-1">
          Description
          <span className="text-red-500 ml-1">*</span>
        </label>
        <textarea
          {...register('description')}
          id="description"
          rows={4}
          placeholder="Provide detailed information about the task"
          className={cn(
            inputClasses,
            "h-auto min-h-[100px]",
            isEditing && 'bg-gray-100 text-gray-500 cursor-not-allowed'
          )}
          disabled={isEditing}
        />
        {errors.description?.message && (
          <p className="mt-1 text-sm text-red-600">{errors.description.message}</p>
        )}
      </div>

      {/* Status and Priority */}
      <div className="grid grid-cols-2 gap-4">
        <Controller
          name="status"
          control={control}
          render={({ field }) => (
            <TaskStatusSelect
              value={field.value}
              onChange={field.onChange}
              error={errors.status?.message}
              required
            />
          )}
        />
        <Controller
          name="priority"
          control={control}
          render={({ field }) => (
            <TaskPrioritySelect
              value={field.value}
              onChange={field.onChange}
              error={errors.priority?.message}
              required
            />
          )}
        />
      </div>

      {/* Visibility and Due Date */}
      <div className="grid grid-cols-2 gap-4">
        <Controller
          name="visibility"
          control={control}
          render={({ field }) => (
            <TaskVisibilitySelect
              value={field.value}
              onChange={field.onChange}
              error={errors.visibility?.message}
              required
            />
          )}
        />
        <Controller
          name="dueDate"
          control={control}
          render={({ field }) => (
            <DatePicker
              value={field.value ? new Date(field.value) : null}
              onChange={(date) => field.onChange(date?.toISOString() || null)}
              error={errors.dueDate?.message}
              label="Due Date"
              showTime
            />
          )}
        />
      </div>

      {/* Assignee */}
      <Controller
        name="assignedTo"
        control={control}
        render={({ field }) => (
          <TaskAssigneeSelect
            value={field.value ?? null}
            onChange={field.onChange}
            error={errors.assignedTo?.message?.toString()}
          />
        )}
      />

      {/* Custom Fields */}
      {!isLoadingCustomFields && customFields.length > 0 && (
        <Controller
          name="customFields"
          control={control}
          render={({ field }) => (
            <CustomFieldsSection
              fields={customFields}
              values={field.value}
              onChange={(fieldId, value) => {
                field.onChange({
                  ...field.value,
                  [fieldId]: value
                });
              }}
              errors={errors.customFields as unknown as Record<string, string>}
              taskId={initialData?.id}
            />
          )}
        />
      )}

      {/* Actions */}
      <div className="flex justify-end space-x-3 pt-4 mt-6 border-t border-gray-200">
        <button
          type="button"
          onClick={onCancel}
          disabled={isSubmitting}
          className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
        >
          Cancel
        </button>
        <button
          type="submit"
          disabled={isSubmitting}
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-brand-600 hover:bg-brand-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500 disabled:opacity-50"
        >
          {isSubmitting ? (
            <>
              <Loader2 className="w-4 h-4 mr-2 animate-spin" />
              Saving...
            </>
          ) : (
            'Save Task'
          )}
        </button>
      </div>
    </form>
  );
}