import { useAuth0 } from '@auth0/auth0-react';
import { useOrganizationContext } from '../context/OrganizationContext';
import { FEATURES } from '../types/features';
import { hasRole } from '../types/auth';
import type { UserRole } from '../types/auth';

export function useFeatureFlag(featureId: string): boolean {
  const { user } = useAuth0();
  const { currentOrganization } = useOrganizationContext();
  const userRole = user?.['https://auth-sync-api/role'] as UserRole || 'member';
  
  // Get feature definition
  const feature = FEATURES[featureId];
  if (!feature || !feature.enabled) return false;

  // Check organization-specific override
  const orgSettings = currentOrganization?.settings?.features?.[featureId];
  if (orgSettings?.enabled === false) return false;
  if (orgSettings?.enabled === true) {
    // Check custom limits if applicable
    if ('hasLimits' in feature && orgSettings.limits) {
      // Implementation would depend on how limits are tracked
      // This is just a placeholder
      return true;
    }
    return true;
  }
  
  // Check role-based access
  return hasRole(userRole, feature.requiredRole || 'member');
}