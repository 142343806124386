import { useState } from 'react';
import { Plus, Building, AlertCircle } from 'lucide-react';
import CreateOrganizationModal from '../CreateOrganizationModal';
import type { Organization } from '../../types/organization';

interface SubOrganizationsProps {
  organization: Organization;
  onUpdate: () => void;
}

export default function SubOrganizations({ organization, onUpdate }: SubOrganizationsProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleCreateSuccess = () => {
    setError(null);
    onUpdate();
    setIsModalOpen(false);
  };

  const renderOrganization = (org: Organization, level = 0) => {
    return (
      <div key={org.id} style={{ marginLeft: `${level * 1.5}rem` }}>
        <div className="flex items-center space-x-3 p-4 hover:bg-gray-50">
          <Building className="h-5 w-5 text-gray-400" />
          <div>
            <h4 className="text-sm font-medium text-gray-900">{org.name}</h4>
            <p className="text-sm text-gray-500">{org.slug}</p>
          </div>
        </div>
        {org.children?.map((child) => renderOrganization(child, level + 1))}
      </div>
    );
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h3 className="text-lg font-medium text-gray-900">Sub-Organizations</h3>
        <button
          onClick={() => setIsModalOpen(true)}
          className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-brand-600 hover:bg-brand-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
        >
          <Plus className="-ml-1 mr-2 h-4 w-4" />
          Create Sub-Organization
        </button>
      </div>

      {error && (
        <div className="rounded-md bg-red-50 p-4">
          <div className="flex">
            <AlertCircle className="h-5 w-5 text-red-400" />
            <div className="ml-3">
              <h3 className="text-sm font-medium text-red-800">{error}</h3>
            </div>
          </div>
        </div>
      )}

      <div className="bg-white shadow-sm rounded-lg border border-gray-200 overflow-hidden">
        {organization.children && organization.children.length > 0 ? (
          <div className="divide-y divide-gray-200">
            {organization.children.map((child) => renderOrganization(child))}
          </div>
        ) : (
          <div className="p-4 text-center text-sm text-gray-500">
            No sub-organizations yet
          </div>
        )}
      </div>

      <CreateOrganizationModal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setError(null);
        }}
        onOrganizationCreated={handleCreateSuccess}
        parentOrganization={organization}
      />
    </div>
  );
}