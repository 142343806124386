import { useMemo } from 'react';
import { TaskFilters } from '../types';

export function useActiveFilters(filters: TaskFilters): number {
  return useMemo(() => {
    let count = 0;

    if (filters.status?.length) count++;
    if (filters.priority?.length) count++;
    if (filters.assignedTo?.length) count++;
    if (filters.userFilter?.length) count++;
    if (filters.showSubtasksOnly) count++;
    if (filters.dueDate?.start || filters.dueDate?.end) count++;

    return count;
  }, [filters]);
}