import React from 'react';
import { AlertCircle, CheckCircle2, Clock, Loader2 } from 'lucide-react';
import { CustomSelect } from '../../common/CustomSelect';
import { TaskStatus, TASK_STATUSES } from '../../../types/task';

interface TaskStatusSelectProps {
  value: TaskStatus | null;
  onChange: (value: TaskStatus | null) => void;
  error?: string;
  isDisabled?: boolean;
  required?: boolean;
  isMulti?: boolean;
  selectedValues?: TaskStatus[];
  label?: string;
}

const statusIcons = {
  todo: Clock,
  in_progress: Loader2,
  blocked: AlertCircle,
  completed: CheckCircle2
};

export function TaskStatusSelect({
  value,
  onChange,
  error,
  isDisabled,
  required,
  isMulti = false,
  selectedValues = [],
  label = 'Status'
}: TaskStatusSelectProps) {
  const options = Object.entries(TASK_STATUSES).map(([status, config]) => ({
    value: status,
    label: config.label,
    description: config.description,
    icon: statusIcons[status as TaskStatus]
  }));

  return (
    <CustomSelect
      options={options}
      value={isMulti ? selectedValues : (value || '')}
      onChange={(newValue) => {
        if (isMulti) {
          onChange(newValue as TaskStatus);
        } else {
          onChange(newValue as TaskStatus);
        }
      }}
      placeholder="Select status"
      label={label}
      error={error}
      isDisabled={isDisabled}
      required={required}
      isMulti={isMulti}
    />
  );
}