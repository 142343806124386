import { useMemo } from 'react';
import { useTaskList } from '../../../../hooks/task';
import { DashboardWidget } from '../../DashboardWidget';
import { TimelineHeader } from './TimelineHeader';
import { TimelineGrid } from './TimelineGrid';
import { useTimelineControls } from './hooks/useTimelineControls';
import { getTimelineData } from './utils/timelineUtils';

export function TimelineWidget() {
  const { tasks } = useTaskList();
  const { 
    currentDate,
    viewMode,
    onNextPeriod,
    onPrevPeriod,
    onViewModeChange,
    onDateChange
  } = useTimelineControls();

  const timelineData = useMemo(() => {
    return getTimelineData(tasks, currentDate, viewMode);
  }, [tasks, currentDate, viewMode]);

  return (
    <DashboardWidget 
      title="Timeline" 
      className="min-h-[400px]"
    >
      <div className="flex flex-col h-full">
        <TimelineHeader
          currentDate={currentDate}
          viewMode={viewMode}
          onNextPeriod={onNextPeriod}
          onPrevPeriod={onPrevPeriod}
          onViewModeChange={onViewModeChange}
          onDateChange={onDateChange}
        />
        <TimelineGrid 
          data={timelineData}
          viewMode={viewMode}
        />
      </div>
    </DashboardWidget>
  );
}