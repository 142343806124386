import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Plus, Calendar } from 'lucide-react';
import { TaskAssigneeSelect } from '../selects';
import { DatePicker } from '../../common/DatePicker';
import { cn } from '../../../lib/utils';
import { useToast } from '../../../context/ToastContext';

interface QuickSubtaskFormProps {
  taskId: number;
  onSubtaskCreated?: () => void;
  onCancel?: () => void;
  className?: string;
}

export function QuickSubtaskForm({ taskId, onSubtaskCreated, onCancel, className }: QuickSubtaskFormProps) {
  const { getAccessTokenSilently } = useAuth0();
  const { showToast } = useToast();
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [assignedTo, setAssignedTo] = useState<number | null>(null);
  const [dueDate, setDueDate] = useState<Date | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async () => {
    if (!title.trim()) return;
    
    setIsSubmitting(true);
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(`${import.meta.env.VITE_API_URL}/api/tasks/${taskId}/subtasks`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          title: title.trim(),
          description: description.trim(),
          status: 'todo',
          priority: 'medium',
          visibility: 'public',
          assignedTo,
          dueDate: dueDate?.toISOString()
        })
      });

      if (!response.ok) {
        throw new Error('Failed to create subtask');
      }

      showToast('Subtask created successfully', 'success');
      setTitle('');
      setDescription('');
      setAssignedTo(null);
      setDueDate(null);
      onSubtaskCreated?.();
    } catch (error) {
      console.error('Failed to create subtask:', error);
      showToast('Failed to create subtask', 'error');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className={cn("space-y-4", className)}>
      {/* Title Input */}
      <input
        type="text"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        placeholder="Task title"
        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-brand-500 focus:border-brand-500"
      />

      {/* Description Input */}
      <textarea
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        placeholder="Add a description..."
        rows={3}
        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-brand-500 focus:border-brand-500"
      />

      <div className="flex flex-col space-y-4">
        {/* Assignee Select */}
        <TaskAssigneeSelect
          value={assignedTo}
          onChange={setAssignedTo}
          className="w-full"
        />

        {/* Due Date Picker */}
        <DatePicker
          value={dueDate}
          onChange={setDueDate}
          placeholder="Set due date"
          className="w-full"
        />
      </div>

      {/* Action Buttons */}
      <div className="flex justify-end space-x-3">
        <button
          type="button"
          onClick={onCancel}
          className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
        >
          Cancel
        </button>
        <button
          type="button"
          onClick={handleSubmit}
          disabled={!title.trim() || isSubmitting}
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-brand-600 hover:bg-brand-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500 disabled:opacity-50"
        >
          <Plus className="w-4 h-4 mr-2" />
          Add Subtask
        </button>
      </div>
    </div>
  );
}