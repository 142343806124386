import { useState, useRef } from 'react';
import { ChevronDown, X, Loader2 } from 'lucide-react';
import { useClickOutside } from '../../../hooks/useClickOutside';
import { cn } from '../../../lib/utils';
import { SelectOption, CustomSelectProps } from './types';
import { SelectInput } from './SelectInput';
import { SelectOptions } from './SelectOptions';
import { SelectedValue } from './SelectedValue';
import { Z_INDEX } from '../../../constants/zIndex';

export function CustomSelect({
  options,
  value,
  onChange,
  placeholder = 'Select...',
  isSearchable = false,
  isClearable = false,
  isDisabled = false,
  isLoading = false,
  isMulti = false,
  error,
  label,
  name,
  className,
  menuPlacement = 'bottom',
  noOptionsMessage = 'No options available',
  required = false,
  onSearchChange
}: CustomSelectProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const containerRef = useClickOutside(() => setIsOpen(false));
  const inputRef = useRef<HTMLInputElement>(null);

  // Convert value to array of selected options
  const selectedOptions = isMulti
    ? options.filter(opt => Array.isArray(value) && value.includes(opt.value))
    : options.find(opt => opt.value === value);

  const filteredOptions = isSearchable && searchQuery
    ? options.filter(opt => 
        opt.label.toLowerCase().includes(searchQuery.toLowerCase()) ||
        opt.description?.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : options;

  const handleSelect = (option: SelectOption) => {
    if (isMulti) {
      const currentValue = Array.isArray(value) ? value : [];
      const newValue = currentValue.includes(option.value)
        ? currentValue.filter(v => v !== option.value)
        : [...currentValue, option.value];
      onChange(newValue);
    } else {
      onChange(option.value);
      setIsOpen(false);
    }
    setSearchQuery('');
  };

  const handleClear = (e: React.MouseEvent) => {
    e.stopPropagation();
    onChange(isMulti ? [] : null);
    setSearchQuery('');
  };

  const handleInputClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (!isDisabled) {
      setIsOpen(true);
      if (isSearchable) {
        inputRef.current?.focus();
      }
    }
  };

  const handleSearchChange = (query: string) => {
    setSearchQuery(query);
    onSearchChange?.(query);
  };

  return (
    <div className={cn('relative', className)} ref={containerRef}>
      {label && (
        <label className="block text-sm font-medium text-gray-700 mb-1">
          {label}
          {required && <span className="text-red-500 ml-1">*</span>}
        </label>
      )}

      <div
        onClick={handleInputClick}
        className={cn(
          'relative w-full cursor-default rounded-md bg-white py-2 pl-3 pr-10 text-left shadow-sm',
          'border border-gray-300',
          'focus:outline-none focus:ring-1 focus:ring-brand-500 focus:border-brand-500',
          'sm:text-sm',
          isDisabled && 'bg-gray-50 cursor-not-allowed',
          error && 'border-red-300 focus:ring-red-500 focus:border-red-500'
        )}
        style={{ zIndex: isOpen ? Z_INDEX.DROPDOWN_TRIGGER : 'auto' }}
      >
        <input type="hidden" name={name} value={value?.toString() || ''} />

        {isSearchable && isOpen ? (
          <SelectInput
            ref={inputRef}
            value={searchQuery}
            onChange={handleSearchChange}
            placeholder={placeholder}
          />
        ) : (
          <SelectedValue
            selectedOptions={selectedOptions}
            placeholder={placeholder}
            isMulti={isMulti}
          />
        )}

        <span className="absolute inset-y-0 right-0 flex items-center pr-2">
          {isClearable && value && !isDisabled && (
            <button
              onClick={handleClear}
              className="p-1 text-gray-400 hover:text-gray-500"
            >
              <X className="h-4 w-4" />
            </button>
          )}
          {isLoading ? (
            <Loader2 className="h-4 w-4 text-gray-400 animate-spin" />
          ) : (
            <ChevronDown className={cn(
              'h-4 w-4 text-gray-400',
              isOpen && 'transform rotate-180'
            )} />
          )}
        </span>
      </div>

      {error && (
        <p className="mt-1 text-sm text-red-600">{error}</p>
      )}

      {isOpen && !isDisabled && (
        <SelectOptions
          options={filteredOptions}
          selectedOptions={selectedOptions || []}
          onSelect={handleSelect}
          isMulti={isMulti}
          menuPlacement={menuPlacement}
          noOptionsMessage={noOptionsMessage}
        />
      )}
    </div>
  );
}