import React, { useState, useRef, useEffect } from 'react';
import { Calendar as CalendarIcon } from 'lucide-react';
import { format } from 'date-fns';
import { DayPicker } from 'react-day-picker';
import { cn } from '../../../lib/utils';
import type { DatePickerProps } from './types';

export function DatePicker({
  value,
  onChange,
  placeholder = 'Select date',
  isDisabled = false,
  error,
  label,
  required,
  minDate,
  maxDate,
  showTime = false,
  className
}: DatePickerProps) {
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSelect = (date: Date | undefined) => {
    onChange(date || null);
    setIsOpen(false);
  };

  return (
    <div className={className} ref={containerRef}>
      {label && (
        <label className="block text-sm font-medium text-gray-700 mb-1">
          {label}
          {required && <span className="text-red-500 ml-1">*</span>}
        </label>
      )}

      <div className="relative">
        <button
          type="button"
          onClick={() => !isDisabled && setIsOpen(!isOpen)}
          disabled={isDisabled}
          className={cn(
            'w-full flex items-center px-3 py-2 border rounded-md shadow-sm text-left',
            'focus:outline-none focus:ring-1 sm:text-sm',
            isDisabled ? 'bg-gray-50 cursor-not-allowed' : 'bg-white',
            error 
              ? 'border-red-300 focus:ring-red-500 focus:border-red-500' 
              : 'border-gray-300 focus:ring-brand-500 focus:border-brand-500'
          )}
        >
          <CalendarIcon className="w-5 h-5 text-gray-400 mr-2" />
          <span className={cn(
            'flex-1',
            !value && 'text-gray-500'
          )}>
            {value ? format(value, showTime ? 'PPp' : 'PP') : placeholder}
          </span>
        </button>

        {error && (
          <p className="mt-1 text-sm text-red-600">{error}</p>
        )}

        {isOpen && !isDisabled && (
          <div 
            className={cn(
              'absolute w-auto min-w-[280px] rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5',
              'z-[53]', // Highest z-index for date picker
              'p-4'
            )}
            style={{
              top: label ? 'calc(100% + 4px)' : 'calc(100% + 4px)',
              left: 0
            }}
          >
            <DayPicker
              mode="single"
              selected={value || undefined}
              onSelect={handleSelect}
              disabled={[
                ...(minDate ? [{ before: minDate }] : []),
                ...(maxDate ? [{ after: maxDate }] : [])
              ]}
              modifiers={{
                today: new Date()
              }}
              modifiersStyles={{
                today: {
                  fontWeight: 'bold',
                  textDecoration: 'underline'
                }
              }}
              className="p-3"
              classNames={{
                months: "flex flex-col gap-4",
                month: "space-y-4",
                caption: "flex justify-between items-center",
                caption_label: "text-sm font-semibold text-gray-900",
                nav: "flex items-center gap-1",
                nav_button: cn(
                  "inline-flex items-center justify-center rounded-md p-1",
                  "text-gray-500 hover:bg-gray-50 hover:text-gray-700",
                  "focus:outline-none focus:ring-2 focus:ring-brand-500"
                ),
                table: "w-full border-collapse space-y-1",
                head_row: "flex",
                head_cell: cn(
                  "text-gray-500 rounded-md w-9 font-normal text-[0.8rem]",
                  "flex items-center justify-center"
                ),
                row: "flex w-full mt-2",
                cell: cn(
                  "relative p-0 text-center text-sm focus-within:relative",
                  "flex items-center justify-center"
                ),
                day: cn(
                  "h-9 w-9 p-0 font-normal",
                  "flex items-center justify-center rounded-md",
                  "hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-brand-500"
                ),
                day_selected: cn(
                  "bg-brand-600 text-white hover:bg-brand-700",
                  "focus:bg-brand-700 focus:text-white"
                ),
                day_today: "bg-gray-100",
                day_outside: "text-gray-400",
                day_disabled: "text-gray-400 opacity-50",
                day_hidden: "invisible"
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}