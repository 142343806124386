import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';

export function useMagicLink() {
  const { getAccessTokenSilently } = useAuth0();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const generateMagicLink = async (taskId: number): Promise<string> => {
    setIsLoading(true);
    setError(null);

    try {
      const token = await getAccessTokenSilently();
      const response = await axios.post(
        `${import.meta.env.VITE_API_URL}/api/tasks/${taskId}/magic-link`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );

      const { token: magicToken } = response.data;
      return `${window.location.origin}/tasks/share/${magicToken}`;
    } catch (error: any) {
      const errorMessage = error.response?.data?.message || 'Failed to generate magic link';
      setError(errorMessage);
      throw new Error(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    generateMagicLink,
    isLoading,
    error
  };
}