import React from 'react';
import { useOrganizationContext } from '../context/OrganizationContext';
import { SettingsNavigation } from '../components/organization/SettingsNavigation/SettingsNavigation';
import OrganizationDetails from '../components/organization/OrganizationDetails';
import OrganizationMembers from '../components/organization/OrganizationMembers';
import SubOrganizations from '../components/organization/SubOrganizations';
import BillingSettings from '../components/organization/BillingSettings';
import EmailSettings from '../components/organization/EmailSettings';
import EmailTemplates from '../components/organization/EmailTemplates';
import { CustomFields } from '../components/organization/CustomFields';
import { FeatureManagement } from '../components/organization/FeatureManagement/FeatureManagement';
import PendingInvitations from '../components/organization/PendingInvitations';
import { useTabState } from '../hooks/useTabState';
import { useToast } from '../context/ToastContext';
import { PermissionGate } from '../components/permissions';
import { ORGANIZATION_PERMISSIONS } from '../types/permission';

export default function OrganizationSettings() {
  const { currentOrganization, fetchOrganizations } = useOrganizationContext();
  const [activeTab, setActiveTab] = useTabState('orgSettingsTab', 'details');
  const { showToast } = useToast();

  const handleUpdate = async () => {
    try {
      await fetchOrganizations();
      showToast('Settings updated successfully', 'success');
    } catch (error) {
      console.error('Failed to update settings:', error);
      showToast('Failed to update settings', 'error');
    }
  };

  if (!currentOrganization) {
    return (
      <div className="flex justify-center items-center min-h-[400px]">
        <div className="text-gray-500">No organization selected</div>
      </div>
    );
  }

  const renderContent = () => {
    switch (activeTab) {
      case 'details':
        return (
          <OrganizationDetails 
            organization={currentOrganization}
            onUpdate={handleUpdate}
          />
        );
      case 'members':
        return (
          <OrganizationMembers
            organization={currentOrganization}
            onUpdate={handleUpdate}
          />
        );
      case 'invitations':
        return (
          <PendingInvitations
            organization={currentOrganization}
            onUpdate={handleUpdate}
          />
        );
      case 'sub-organizations':
        return (
          <SubOrganizations
            organization={currentOrganization}
            onUpdate={handleUpdate}
          />
        );
      case 'billing':
        return (
          <BillingSettings
            organization={currentOrganization}
            onUpdate={handleUpdate}
          />
        );
      case 'email':
        return (
          <EmailSettings
            organization={currentOrganization}
            onUpdate={handleUpdate}
          />
        );
      case 'templates':
        return (
          <EmailTemplates
            organization={currentOrganization}
          />
        );
      case 'custom-fields':
        return (
          <CustomFields
            organization={currentOrganization}
            onUpdate={handleUpdate}
          />
        );
      case 'features':
        return (
          <PermissionGate permission={ORGANIZATION_PERMISSIONS.MANAGE_ORGANIZATION}>
            <FeatureManagement
              organizationId={currentOrganization.id}
            />
          </PermissionGate>
        );
      default:
        return null;
    }
  };

  return (
    <div className="flex h-[calc(100vh-4rem)]">
      <SettingsNavigation
        activeTab={activeTab}
        onTabChange={setActiveTab}
      />
      <div className="flex-1 overflow-auto">
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
          {renderContent()}
        </div>
      </div>
    </div>
  );
}