import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';

interface FileUploadHookOptions {
  taskId: number;
  fieldId: number;
  onSuccess?: (files: any[]) => void;
  onError?: (error: string) => void;
}

export function useFileUpload({ taskId, fieldId, onSuccess, onError }: FileUploadHookOptions) {
  const { getAccessTokenSilently } = useAuth0();
  const [isUploading, setIsUploading] = useState(false);
  const [progress, setProgress] = useState(0);

  const uploadFiles = async (files: File[]) => {
    setIsUploading(true);
    setProgress(0);

    try {
      const token = await getAccessTokenSilently();
      const uploadedFiles = [];

      for (let i = 0; i < files.length; i++) {
        const formData = new FormData();
        formData.append('file', files[i]);

        const response = await axios.post(
          `${import.meta.env.VITE_API_URL}/api/tasks/${taskId}/fields/${fieldId}/files`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: (progressEvent) => {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / (progressEvent.total || 0)
              );
              setProgress(percentCompleted);
            }
          }
        );

        uploadedFiles.push(response.data);
      }

      onSuccess?.(uploadedFiles);
      return uploadedFiles;
    } catch (error: any) {
      const errorMessage = error.response?.data?.message || 'Failed to upload file';
      onError?.(errorMessage);
      throw new Error(errorMessage);
    } finally {
      setIsUploading(false);
      setProgress(0);
    }
  };

  const deleteFile = async (fileId: number) => {
    try {
      const token = await getAccessTokenSilently();
      await axios.delete(
        `${import.meta.env.VITE_API_URL}/api/tasks/${taskId}/fields/${fieldId}/files/${fileId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
    } catch (error: any) {
      const errorMessage = error.response?.data?.message || 'Failed to delete file';
      onError?.(errorMessage);
      throw new Error(errorMessage);
    }
  };

  const getFileUrl = async (fileId: number) => {
    try {
      const token = await getAccessTokenSilently();
      const response = await axios.get(
        `${import.meta.env.VITE_API_URL}/api/tasks/${taskId}/fields/${fieldId}/files/${fileId}/url`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      return response.data.url;
    } catch (error: any) {
      const errorMessage = error.response?.data?.message || 'Failed to get file URL';
      onError?.(errorMessage);
      throw new Error(errorMessage);
    }
  };

  return {
    uploadFiles,
    deleteFile,
    getFileUrl,
    isUploading,
    progress
  };
}