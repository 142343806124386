import { cn } from '../../../lib/utils';
import { SelectOption } from './types';

interface SelectedValueProps {
  selectedOptions: SelectOption | SelectOption[] | undefined;
  placeholder: string;
  isMulti: boolean;
}

export function SelectedValue({
  selectedOptions,
  placeholder,
  isMulti
}: SelectedValueProps) {
  if (!selectedOptions) {
    return (
      <span className="block truncate text-gray-500">
        {placeholder}
      </span>
    );
  }

  if (isMulti && Array.isArray(selectedOptions)) {
    return selectedOptions.length > 0 ? (
      <span className="block truncate">
        {selectedOptions.map(opt => opt.label).join(', ')}
      </span>
    ) : (
      <span className="block truncate text-gray-500">
        {placeholder}
      </span>
    );
  }

  const option = selectedOptions as SelectOption;
  return (
    <span className="block truncate">
      {option.label}
    </span>
  );
}