import { createContext, useContext, ReactNode, useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import type { Organization } from '../types/organization';

interface OrganizationContextType {
  organizations: Organization[];
  currentOrganization: Organization | null;
  isLoading: boolean;
  error: string | null;
  switchOrganization: (organizationId: number) => Promise<void>;
  fetchOrganizations: () => Promise<void>;
  refreshOrganizations: () => Promise<void>;
}

const OrganizationContext = createContext<OrganizationContextType | undefined>(undefined);

export function OrganizationProvider({ children }: { children: ReactNode }) {
  const { getAccessTokenSilently, isAuthenticated, isLoading: isAuthLoading } = useAuth0();
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const [currentOrganization, setCurrentOrganization] = useState<Organization | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchOrganizations = async () => {
    if (!isAuthenticated || isAuthLoading) {
      console.log('[OrganizationContext] Skipping fetch - not authenticated or loading');
      return;
    }

    console.log('[OrganizationContext] Fetching organizations');
    try {
      // Get fresh token with offline_access scope
      const token = await getAccessTokenSilently({
        authorizationParams: {
          audience: import.meta.env.VITE_AUTH0_AUDIENCE,
          scope: 'openid profile email offline_access'
        },
        detailedResponse: true
      });
      
      console.log('[OrganizationContext] Got access token, fetching organizations');
      
      // Fetch all organizations
      const response = await axios.get<Organization[]>(
        `${import.meta.env.VITE_API_URL}/api/organizations`,
        {
          headers: {
            Authorization: `Bearer ${token.access_token}`
          }
        }
      );
      
      console.log('[OrganizationContext] Fetched organizations:', {
        count: response.data.length
      });
      
      setOrganizations(response.data);
      
      // Get current organization
      console.log('[OrganizationContext] Fetching current organization');
      const currentResponse = await axios.get<{ organization: Organization | null }>(
        `${import.meta.env.VITE_API_URL}/api/organization-switch/current`,
        {
          headers: {
            Authorization: `Bearer ${token.access_token}`
          }
        }
      );
      
      if (currentResponse.data.organization) {
        console.log('[OrganizationContext] Got current organization:', {
          id: currentResponse.data.organization.id,
          name: currentResponse.data.organization.name
        });
        setCurrentOrganization(currentResponse.data.organization);
      } else if (response.data.length > 0) {
        console.log('[OrganizationContext] No current organization, using first available');
        setCurrentOrganization(response.data[0]);
      }
      
      setError(null);
    } catch (error: any) {
      console.error('[OrganizationContext] Error fetching organizations:', error);
      setError(error.response?.data?.message || 'Failed to fetch organizations');
    } finally {
      setIsLoading(false);
    }
  };

  const refreshOrganizations = async () => {
    setIsLoading(true);
    await fetchOrganizations();
  };

  const switchOrganization = async (organizationId: number) => {
    console.log('[OrganizationContext] Switching organization:', { organizationId });
    try {
      // Get fresh token
      const token = await getAccessTokenSilently({
        authorizationParams: {
          audience: import.meta.env.VITE_AUTH0_AUDIENCE,
          scope: 'openid profile email offline_access'
        },
        detailedResponse: true
      });

      console.log('[OrganizationContext] Got access token, switching organization');
      const response = await axios.post(
        `${import.meta.env.VITE_API_URL}/api/organization-switch/switch`,
        { organizationId },
        {
          headers: {
            Authorization: `Bearer ${token.access_token}`,
            'Content-Type': 'application/json'
          }
        }
      );

      if (response.data.organization) {
        console.log('[OrganizationContext] Switch successful, updating state');
        // Update local state
        setCurrentOrganization(response.data.organization);
        
        console.log('[OrganizationContext] Getting fresh token');
        // Get fresh token with new organization context
        await getAccessTokenSilently({ 
          cacheMode: 'off',
          authorizationParams: {
            audience: import.meta.env.VITE_AUTH0_AUDIENCE,
            scope: 'openid profile email offline_access'
          }
        });

        // Immediately reload the page to apply new organization context
        window.location.reload();
      }
    } catch (error: any) {
      console.error('[OrganizationContext] Error switching organization:', error);
      throw new Error(error.response?.data?.message || 'Failed to switch organization');
    }
  };

  useEffect(() => {
    if (isAuthenticated && !isAuthLoading) {
      console.log('[OrganizationContext] Auth ready, fetching organizations');
      fetchOrganizations();
    }
  }, [isAuthenticated, isAuthLoading]);

  return (
    <OrganizationContext.Provider value={{
      organizations,
      currentOrganization,
      isLoading,
      error,
      switchOrganization,
      fetchOrganizations,
      refreshOrganizations
    }}>
      {children}
    </OrganizationContext.Provider>
  );
}

export function useOrganizationContext(): OrganizationContextType {
  const context = useContext(OrganizationContext);
  if (context === undefined) {
    throw new Error('useOrganizationContext must be used within an OrganizationProvider');
  }
  return context;
}
