export enum PlanType {
    FREE = 'free',
    PRO = 'pro',
    ENTERPRISE = 'enterprise'
  }
  
  export interface SubscriptionPlan {
    id: PlanType;
    name: string;
    description: string;
    price: number;
    features: Record<string, FeatureLimit>;
    stripeProductId?: string;
    stripePriceId?: string;
  }
  
  export interface FeatureLimit {
    enabled: boolean;
    limit?: number;
    unit?: string;
  }
  
  export const SUBSCRIPTION_PLANS: Record<PlanType, SubscriptionPlan> = {
    [PlanType.FREE]: {
      id: PlanType.FREE,
      name: 'Free',
      description: 'For small teams getting started',
      price: 0,
      features: {
        'tasks.total': { enabled: true, limit: 100, unit: 'tasks' },
        'tasks.subtasks': { enabled: true, limit: 5, unit: 'per task' },
        'users.total': { enabled: true, limit: 5, unit: 'users' },
        'storage.quota': { enabled: true, limit: 100, unit: 'MB' },
        'custom_fields': { enabled: false },
        'magic_links': { enabled: true, limit: 10, unit: 'per month' },
        'email_notifications': { enabled: true },
        'comments': { enabled: true },
        'history': { enabled: true }
      }
    },
    [PlanType.PRO]: {
      id: PlanType.PRO,
      name: 'Professional',
      description: 'For growing teams',
      price: 29,
      stripeProductId: 'prod_xyz',
      stripePriceId: 'price_xyz',
      features: {
        'tasks.total': { enabled: true, limit: 1000, unit: 'tasks' },
        'tasks.subtasks': { enabled: true, limit: 20, unit: 'per task' },
        'users.total': { enabled: true, limit: 25, unit: 'users' },
        'storage.quota': { enabled: true, limit: 1000, unit: 'MB' },
        'custom_fields': { enabled: true },
        'magic_links': { enabled: true, limit: 100, unit: 'per month' },
        'email_notifications': { enabled: true },
        'comments': { enabled: true },
        'history': { enabled: true }
      }
    },
    [PlanType.ENTERPRISE]: {
      id: PlanType.ENTERPRISE,
      name: 'Enterprise',
      description: 'For large organizations',
      price: 99,
      stripeProductId: 'prod_abc',
      stripePriceId: 'price_abc',
      features: {
        'tasks.total': { enabled: true }, // Unlimited
        'tasks.subtasks': { enabled: true }, // Unlimited
        'users.total': { enabled: true }, // Unlimited
        'storage.quota': { enabled: true, limit: 10000, unit: 'MB' },
        'custom_fields': { enabled: true },
        'magic_links': { enabled: true }, // Unlimited
        'email_notifications': { enabled: true },
        'comments': { enabled: true },
        'history': { enabled: true }
      }
    }
  };