import { useState } from 'react';
import { Settings2, AlertCircle } from 'lucide-react';
import { useFeatureSettings } from '../../../hooks/useFeatureSettings';
import { FeatureList } from './FeatureList';
import { FeatureSettingsModal } from './FeatureSettingsModal';
import { FeatureOverrideModal } from './FeatureOverrideModal';
import { AlertDialog } from '../../common/AlertDialog';
import type { Feature, FeatureSettings } from '../../../types/feature';

interface FeatureManagementProps {
  organizationId: number;
}

export function FeatureManagement({ organizationId }: FeatureManagementProps) {
  const { 
    features, 
    settings,
    isLoading, 
    error,
    updateFeatureSettings,
    overrideFeature
  } = useFeatureSettings(organizationId);

  const [selectedFeature, setSelectedFeature] = useState<Feature | null>(null);
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
  const [isOverrideModalOpen, setIsOverrideModalOpen] = useState(false);
  const [showResetConfirm, setShowResetConfirm] = useState(false);

  const handleSettingsUpdate = async (featureId: string, newSettings: FeatureSettings) => {
    try {
      await updateFeatureSettings(featureId, newSettings);
      setIsSettingsModalOpen(false);
    } catch (error) {
      console.error('Failed to update feature settings:', error);
    }
  };

  const handleFeatureOverride = async (featureId: string, enabled: boolean, limits?: Record<string, number>) => {
    try {
      await overrideFeature(featureId, enabled, limits);
      setIsOverrideModalOpen(false);
    } catch (error) {
      console.error('Failed to override feature:', error);
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center py-8">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-brand-600"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="rounded-md bg-red-50 p-4">
        <div className="flex">
          <AlertCircle className="h-5 w-5 text-red-400" />
          <div className="ml-3">
            <h3 className="text-sm font-medium text-red-800">{error}</h3>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <div className="flex items-center space-x-3">
          <Settings2 className="w-6 h-6 text-brand-600" />
          <h2 className="text-lg font-medium text-gray-900">Feature Management</h2>
        </div>
      </div>

      <FeatureList
        features={features}
        settings={settings}
        onConfigureFeature={(feature) => {
          setSelectedFeature(feature);
          setIsSettingsModalOpen(true);
        }}
        onOverrideFeature={(feature) => {
          setSelectedFeature(feature);
          setIsOverrideModalOpen(true);
        }}
      />

      {selectedFeature && (
        <>
          <FeatureSettingsModal
            isOpen={isSettingsModalOpen}
            onClose={() => setIsSettingsModalOpen(false)}
            feature={selectedFeature}
            settings={settings[selectedFeature.id]}
            onSave={(newSettings) => handleSettingsUpdate(selectedFeature.id, newSettings)}
          />

          <FeatureOverrideModal
            isOpen={isOverrideModalOpen}
            onClose={() => setIsOverrideModalOpen(false)}
            feature={selectedFeature}
            settings={settings[selectedFeature.id]}
            onSave={(enabled, limits) => handleFeatureOverride(selectedFeature.id, enabled, limits)}
          />
        </>
      )}

      <AlertDialog
        isOpen={showResetConfirm}
        onClose={() => setShowResetConfirm(false)}
        onConfirm={async () => {
          if (selectedFeature) {
            await handleFeatureOverride(selectedFeature.id, false);
            setShowResetConfirm(false);
          }
        }}
        title="Reset Feature Settings"
        description="Are you sure you want to reset this feature's settings to the default plan values? This will remove any custom overrides."
        confirmLabel="Reset"
        cancelLabel="Cancel"
        variant="warning"
      />
    </div>
  );
}