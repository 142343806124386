import { useState } from 'react';
import { format } from 'date-fns';
import { Calendar, MessageSquare, Paperclip, Edit2, Trash2, User, Plus, Copy, Link2 } from 'lucide-react';
import { cn } from '../../../lib/utils';
import { getTaskDisplayId } from '../../../utils/formatters';
import { TaskCardProps } from './types';
import { TASK_STATUSES, TASK_PRIORITIES } from '../../../types/task';
import { PermissionGate } from '../../permissions';
import { TASK_PERMISSIONS } from '../../../types/permission';
import { QuickSubtaskForm } from '../QuickSubtaskForm';
import { AlertDialog } from '../../common/AlertDialog';
import { DuplicateTaskModal } from '../DuplicateTaskModal';
import { useTaskMutations } from '../../../hooks/task';
import { useToast } from '../../../context/ToastContext';
import { MagicLink } from '../MagicLink';
import Modal from '../../common/Modal';

export function TaskCard({ 
  task, 
  onClick, 
  onDelete, 
  onDuplicate,
  className, 
  isCompact = false 
}: TaskCardProps) {
  const [isAddingSubtask, setIsAddingSubtask] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const [showMagicLinkModal, setShowMagicLinkModal] = useState(false);
  const { duplicateTask } = useTaskMutations();
  const { showToast } = useToast();
  const status = TASK_STATUSES[task.status];
  const priority = TASK_PRIORITIES[task.priority];

  const handleEditClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (onClick) onClick();
  };

  const handleDeleteClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setShowDeleteConfirm(true);
  };

  const handleDuplicateClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setShowDuplicateModal(true);
  };

  const handleMagicLinkClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setShowMagicLinkModal(true);
  };

  const handleDuplicate = async (data: { title: string; description: string }) => {
    try {
      await duplicateTask(task.id, data);
      showToast('Task duplicated successfully', 'success');
      if (onDuplicate) {
        onDuplicate();
      }
      setShowDuplicateModal(false);
    } catch (error) {
      console.error('Failed to duplicate task:', error);
      showToast('Failed to duplicate task', 'error');
    }
  };

  const handleAddSubtaskClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsAddingSubtask(true);
  };

  const handleConfirmDelete = async () => {
    try {
      if (onDelete) {
        await onDelete();
        showToast('Task deleted successfully', 'success');
      }
    } catch (error) {
      console.error('Failed to delete task:', error);
      showToast('Failed to delete task', 'error');
    } finally {
      setShowDeleteConfirm(false);
    }
  };

  const handleSubtaskCreated = () => {
    setIsAddingSubtask(false);
    showToast('Subtask created successfully', 'success');
  };

  return (
    <>
      <div
        onClick={onClick}
        className={cn(
          'bg-white rounded-lg border border-gray-200 shadow-sm hover:shadow-md transition-shadow relative group',
          onClick && 'cursor-pointer',
          className
        )}
      >
        <div className="p-4">
          {/* Task ID Badge */}
          <div className="absolute left-2 top-2 px-2 py-1 bg-gray-100 rounded text-xs font-mono text-gray-600">
            {getTaskDisplayId(task)}
          </div>

          {/* Action Buttons */}
          <div className="absolute right-2 top-2 flex items-center space-x-2 opacity-0 group-hover:opacity-100 transition-opacity">
            <PermissionGate
              permission={TASK_PERMISSIONS.READ_ASSIGNED}
            >
              <button
                onClick={handleMagicLinkClick}
                className="flex items-center justify-center w-8 h-8 rounded-full bg-brand-50 text-brand-600 hover:bg-brand-100 hover:text-brand-700 transition-colors"
                title="Share link"
              >
                <Link2 className="w-4 h-4" />
              </button>
            </PermissionGate>

            <PermissionGate
              permission={TASK_PERMISSIONS.CREATE}
            >
              <button
                onClick={handleDuplicateClick}
                className="flex items-center justify-center w-8 h-8 rounded-full bg-brand-50 text-brand-600 hover:bg-brand-100 hover:text-brand-700 transition-colors"
                title="Duplicate task"
              >
                <Copy className="w-4 h-4" />
              </button>
            </PermissionGate>

            <PermissionGate
              permission={TASK_PERMISSIONS.CREATE}
            >
              <button
                onClick={handleAddSubtaskClick}
                className="flex items-center justify-center w-8 h-8 rounded-full bg-brand-50 text-brand-600 hover:bg-brand-100 hover:text-brand-700 transition-colors"
                title="Add subtask"
              >
                <Plus className="w-4 h-4" />
              </button>
            </PermissionGate>

            <PermissionGate
              permission={TASK_PERMISSIONS.UPDATE_ASSIGNED}
              options={{ ownerId: task.createdBy }}
            >
              <button
                onClick={handleEditClick}
                className="flex items-center justify-center w-8 h-8 rounded-full bg-blue-50 text-blue-600 hover:bg-blue-100 hover:text-blue-700 transition-colors"
                title="Edit task"
              >
                <Edit2 className="w-4 h-4" />
              </button>
            </PermissionGate>

            <PermissionGate
              permission={TASK_PERMISSIONS.DELETE_ASSIGNED}
              options={{ ownerId: task.createdBy }}
            >
              <button
                onClick={handleDeleteClick}
                className="flex items-center justify-center w-8 h-8 rounded-full bg-red-50 text-red-600 hover:bg-red-100 hover:text-red-700 transition-colors"
                title="Delete task"
              >
                <Trash2 className="w-4 h-4" />
              </button>
            </PermissionGate>
          </div>

          {/* Main Content */}
          <div className="pt-8">
            {/* Status and Priority */}
            <div className="flex items-center space-x-2 mb-3">
              <span
                className={cn(
                  'inline-flex items-center px-2.5 py-1 rounded-full text-xs font-medium',
                  `bg-${status.color}-100 text-${status.color}-800`
                )}
              >
                {status.label}
              </span>
              <span
                className={cn(
                  'inline-flex items-center px-2.5 py-1 rounded-full text-xs font-medium',
                  `bg-${priority.color}-100 text-${priority.color}-800`
                )}
              >
                {priority.label}
              </span>
            </div>

            {/* Title */}
            <h3 className="text-sm font-medium text-gray-900 mb-2">
              {task.title}
            </h3>

            {/* Description - Only show in non-compact mode */}
            {!isCompact && task.description && (
              <p className="text-sm text-gray-500 line-clamp-2 mb-4">
                {task.description}
              </p>
            )}

            {/* Quick Subtask Form */}
            {isAddingSubtask && (
              <div className="mt-4 border-t border-gray-100 pt-4" onClick={e => e.stopPropagation()}>
                <QuickSubtaskForm
                  taskId={task.id}
                  onSubtaskCreated={handleSubtaskCreated}
                  onCancel={() => setIsAddingSubtask(false)}
                />
              </div>
            )}

            {/* Metadata */}
            <div className="flex items-center justify-between mt-4 pt-3 border-t border-gray-100">
              {/* Left side - Comment count and assignee */}
              <div className="flex items-center space-x-4">
                {task.comments && task.comments.length > 0 && (
                  <div className="flex items-center text-gray-500">
                    <MessageSquare className="w-4 h-4 mr-1" />
                    <span className="text-xs">{task.comments.length}</span>
                  </div>
                )}
                {task.assignee ? (
                  <div className="flex items-center text-gray-600">
                    <User className="w-4 h-4 mr-1" />
                    <span className="text-xs">
                      {task.assignee.firstName && task.assignee.lastName
                        ? `${task.assignee.firstName} ${task.assignee.lastName}`
                        : task.assignee.email}
                    </span>
                  </div>
                ) : (
                  <div className="flex items-center text-gray-400">
                    <User className="w-4 h-4 mr-1" />
                    <span className="text-xs">Unassigned</span>
                  </div>
                )}
              </div>

              {/* Right side - Icons and Counts */}
              <div className="flex items-center space-x-4">
                {task.subtasks && task.subtasks.length > 0 && (
                  <div className="flex items-center text-gray-500">
                    <span className="text-xs font-medium mr-1">{task.subtasks.length}</span>
                    <span className="text-xs">subtasks</span>
                  </div>
                )}
                <div className="flex items-center space-x-2 text-gray-400">
                  <Paperclip className="w-4 h-4" />
                  {task.dueDate && (
                    <div className="flex items-center text-xs text-gray-500">
                      <Calendar className="w-4 h-4 mr-1" />
                      {format(new Date(task.dueDate), 'MMM d')}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Delete Confirmation Dialog */}
      <AlertDialog
        isOpen={showDeleteConfirm}
        onClose={() => setShowDeleteConfirm(false)}
        onConfirm={handleConfirmDelete}
        title="Delete Task"
        description="Are you sure you want to delete this task? This action cannot be undone and will also delete all subtasks, comments, and attachments."
        confirmLabel="Delete"
        cancelLabel="Cancel"
        variant="danger"
      />

      {/* Duplicate Task Modal */}
      <div onClick={e => e.stopPropagation()}>
        <DuplicateTaskModal
          isOpen={showDuplicateModal}
          onClose={() => setShowDuplicateModal(false)}
          onDuplicate={handleDuplicate}
          initialData={{
            title: `${task.title} (Copy)`,
            description: task.description
          }}
        />
      </div>

      {/* Magic Link Modal */}
      <div onClick={e => e.stopPropagation()}>
        <Modal
          isOpen={showMagicLinkModal}
          onClose={() => setShowMagicLinkModal(false)}
          title="Share Task"
        >
          <MagicLink taskId={task.id} />
        </Modal>
      </div>
    </>
  );
}