import { useState, useEffect } from 'react';
import { Loader2 } from 'lucide-react';
import Modal from '../common/Modal';
import { StripeWrapper } from './StripeWrapper';
import { StripePaymentForm } from './StripePaymentForm';
import { useStripePayment } from '../../hooks/useStripePayment';
import type { SubscriptionPlan } from '../../types/subscription';

interface PaymentModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (paymentMethodId: string) => Promise<void>;
  plan: SubscriptionPlan | null;
}

export function PaymentModal({ isOpen, onClose, onSubmit, plan }: PaymentModalProps) {
  const { createPaymentIntent, isLoading: isLoadingIntent } = useStripePayment();
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const initializePayment = async () => {
      if (!plan || !isOpen) return;

      try {
        const secret = await createPaymentIntent(plan.price * 100); // Convert to cents
        setClientSecret(secret);
      } catch (error: any) {
        setError(error.message);
      }
    };

    initializePayment();
  }, [plan, isOpen]);

  if (!plan) return null;

  if (isLoadingIntent || !clientSecret) {
    return (
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        title={`Upgrade to ${plan.name}`}
      >
        <div className="flex justify-center items-center py-12">
          <Loader2 className="w-8 h-8 animate-spin text-brand-600" />
        </div>
      </Modal>
    );
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={`Upgrade to ${plan.name}`}
    >
      <div className="space-y-6">
        <div className="bg-gray-50 p-4 rounded-md">
          <div className="flex justify-between items-center">
            <span className="text-sm font-medium text-gray-900">
              {plan.name} Plan
            </span>
            <span className="text-sm font-medium text-gray-900">
              ${plan.price}/month
            </span>
          </div>
        </div>

        {error ? (
          <div className="text-sm text-red-600">
            {error}
          </div>
        ) : (
          <StripeWrapper clientSecret={clientSecret}>
            <StripePaymentForm
              onSubmit={onSubmit}
              onCancel={onClose}
              amount={plan.price * 100}
            />
          </StripeWrapper>
        )}
      </div>
    </Modal>
  );
}