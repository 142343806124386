import { ChevronLeft, ChevronRight, Calendar } from 'lucide-react';
import { format } from 'date-fns';
import { DatePicker } from '../../../common/DatePicker';
import { TimelineViewMode } from './types';
import { cn } from '../../../../lib/utils';

interface TimelineHeaderProps {
  currentDate: Date;
  viewMode: TimelineViewMode;
  onNextPeriod: () => void;
  onPrevPeriod: () => void;
  onViewModeChange: (mode: TimelineViewMode) => void;
  onDateChange: (date: Date | null) => void;
}

export function TimelineHeader({
  currentDate,
  viewMode,
  onNextPeriod,
  onPrevPeriod,
  onViewModeChange,
  onDateChange
}: TimelineHeaderProps) {
  return (
    <div className="flex flex-col space-y-4 mb-6">
      <div className="flex items-center justify-between">
        {/* Period Navigation */}
        <div className="flex items-center space-x-4">
          <button
            onClick={onPrevPeriod}
            className="p-2 rounded-full hover:bg-gray-100 text-gray-600"
          >
            <ChevronLeft className="w-5 h-5" />
          </button>
          
          <div className="flex items-center space-x-2">
            <DatePicker
              value={currentDate}
              onChange={onDateChange}
              className="w-[180px]"
            />
            <span className="text-sm font-medium text-gray-900">
              {format(currentDate, 'MMMM yyyy')}
            </span>
          </div>

          <button
            onClick={onNextPeriod}
            className="p-2 rounded-full hover:bg-gray-100 text-gray-600"
          >
            <ChevronRight className="w-5 h-5" />
          </button>
        </div>

        {/* View Mode Selector */}
        <div className="flex items-center space-x-1 bg-gray-100 rounded-lg p-1">
          {(['week', 'month', 'quarter'] as TimelineViewMode[]).map((mode) => (
            <button
              key={mode}
              onClick={() => onViewModeChange(mode)}
              className={cn(
                'px-3 py-1.5 text-sm font-medium rounded-md transition-colors',
                viewMode === mode
                  ? 'bg-white text-brand-600 shadow-sm'
                  : 'text-gray-600 hover:text-gray-900'
              )}
            >
              {mode.charAt(0).toUpperCase() + mode.slice(1)}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}