import { cn } from '../../../lib/utils';
import { PermissionGate } from '../../permissions';
import type { NavigationItem as NavigationItemType } from './types';

interface NavigationItemProps {
  item: NavigationItemType;
  isActive: boolean;
  onClick: () => void;
}

export function NavigationItem({ item, isActive, onClick }: NavigationItemProps) {
  const ItemButton = (
    <button
      onClick={onClick}
      className={cn(
        'group flex items-center w-full px-3 py-2 text-sm font-medium rounded-md',
        isActive
          ? 'bg-brand-50 text-brand-600'
          : 'text-gray-600 hover:text-gray-900 hover:bg-gray-50'
      )}
    >
      {item.icon && (
        <item.icon 
          className={cn(
            'flex-shrink-0 -ml-1 mr-3 h-4 w-4',
            isActive
              ? 'text-brand-500'
              : 'text-gray-400 group-hover:text-gray-500'
          )}
        />
      )}
      <span className="truncate">{item.name}</span>
    </button>
  );

  if (item.requiresPermission && item.permission) {
    return (
      <PermissionGate permission={item.permission}>
        {ItemButton}
      </PermissionGate>
    );
  }

  return ItemButton;
}