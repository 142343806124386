import { useState } from 'react';
import { 
  addWeeks, 
  addMonths, 
  subWeeks, 
  subMonths, 
  startOfWeek, 
  startOfMonth,
  startOfQuarter
} from 'date-fns';
import type { TimelineViewMode } from '../types';

export function useTimelineControls() {
  const [currentDate, setCurrentDate] = useState(() => {
    const now = new Date();
    return startOfWeek(now);
  });
  const [viewMode, setViewMode] = useState<TimelineViewMode>('week');

  const onNextPeriod = () => {
    setCurrentDate(current => {
      switch (viewMode) {
        case 'week':
          return addWeeks(current, 1);
        case 'month':
          return addMonths(current, 1);
        case 'quarter':
          return addMonths(current, 3);
        default:
          return current;
      }
    });
  };

  const onPrevPeriod = () => {
    setCurrentDate(current => {
      switch (viewMode) {
        case 'week':
          return subWeeks(current, 1);
        case 'month':
          return subMonths(current, 1);
        case 'quarter':
          return subMonths(current, 3);
        default:
          return current;
      }
    });
  };

  const onViewModeChange = (mode: TimelineViewMode) => {
    setViewMode(mode);
    setCurrentDate(current => {
      switch (mode) {
        case 'week':
          return startOfWeek(current);
        case 'month':
          return startOfMonth(current);
        case 'quarter':
          return startOfQuarter(current);
        default:
          return current;
      }
    });
  };

  const onDateChange = (date: Date | null) => {
    if (!date) return;
    
    switch (viewMode) {
      case 'week':
        setCurrentDate(startOfWeek(date));
        break;
      case 'month':
        setCurrentDate(startOfMonth(date));
        break;
      case 'quarter':
        setCurrentDate(startOfQuarter(date));
        break;
      default:
        setCurrentDate(date);
    }
  };

  return {
    currentDate,
    viewMode,
    onNextPeriod,
    onPrevPeriod,
    onViewModeChange,
    onDateChange
  };
}