import { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import type { ReportFilters, ReportData } from '../types';

export function useCustomReports(filters: ReportFilters) {
  const { getAccessTokenSilently } = useAuth0();
  const [data, setData] = useState<ReportData[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchReport = async () => {
      try {
        const token = await getAccessTokenSilently();
        const response = await axios.get(
          `${import.meta.env.VITE_API_URL}/api/reports`,
          {
            headers: {
              Authorization: `Bearer ${token}`
            },
            params: filters
          }
        );
        setData(response.data);
      } catch (error: any) {
        console.error('Failed to fetch report:', error);
        setError(error.response?.data?.message || 'Failed to load report');
      } finally {
        setIsLoading(false);
      }
    };

    fetchReport();
  }, [getAccessTokenSilently, filters]);

  const exportReport = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await axios.get(
        `${import.meta.env.VITE_API_URL}/api/reports/export`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          },
          params: filters,
          responseType: 'blob'
        }
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'report.csv');
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('Failed to export report:', error);
    }
  };

  return { data, isLoading, error, exportReport };
}