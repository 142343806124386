import { Check } from 'lucide-react';
import { cn } from '../../lib/utils';
import type { SubscriptionPlan } from '../../types/subscription';

interface PlanCardProps {
  plan: SubscriptionPlan;
  isCurrentPlan: boolean;
  onSelect: () => void;
}

export function PlanCard({ plan, isCurrentPlan, onSelect }: PlanCardProps) {
  return (
    <div className={cn(
      'relative rounded-lg border p-6 shadow-sm',
      isCurrentPlan ? 'border-brand-500 bg-brand-50' : 'border-gray-200'
    )}>
      {isCurrentPlan && (
        <div className="absolute -top-4 left-1/2 -translate-x-1/2">
          <span className="inline-flex items-center px-3 py-1 text-xs font-medium bg-brand-600 text-white rounded-full">
            Current Plan
          </span>
        </div>
      )}

      <div className="text-center">
        <h3 className="text-lg font-medium text-gray-900">{plan.name}</h3>
        <p className="mt-2 text-sm text-gray-500">{plan.description}</p>
        <p className="mt-4">
          <span className="text-4xl font-bold text-gray-900">${plan.price}</span>
          <span className="text-sm text-gray-500">/month</span>
        </p>
      </div>

      <ul className="mt-6 space-y-4">
        {Object.entries(plan.features).map(([feature, config]) => (
          <li key={feature} className="flex items-start">
            <div className="flex-shrink-0">
              <Check className={cn(
                'h-5 w-5',
                config.enabled ? 'text-brand-500' : 'text-gray-300'
              )} />
            </div>
            <p className="ml-3 text-sm text-gray-500">
              {config.enabled ? (
                <>
                  {config.limit ? (
                    <>{config.limit} {config.unit}</>
                  ) : (
                    'Unlimited'
                  )}
                </>
              ) : (
                'Not available'
              )}
            </p>
          </li>
        ))}
      </ul>

      <button
        onClick={onSelect}
        disabled={isCurrentPlan}
        className={cn(
          'mt-8 w-full rounded-md px-4 py-2 text-sm font-medium',
          isCurrentPlan
            ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
            : 'bg-brand-600 text-white hover:bg-brand-700 focus:outline-none focus:ring-2 focus:ring-brand-500 focus:ring-offset-2'
        )}
      >
        {isCurrentPlan ? 'Current Plan' : 'Upgrade'}
      </button>
    </div>
  );
}