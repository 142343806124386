import { useState } from 'react';
import { Link2, Copy, CheckCircle2, Loader2 } from 'lucide-react';
import { useMagicLink } from '../../../hooks/task/useMagicLink';
import { cn } from '../../../lib/utils';

interface MagicLinkProps {
  taskId: number;
  className?: string;
}

export function MagicLink({ taskId, className }: MagicLinkProps) {
  const { generateMagicLink, isLoading, error } = useMagicLink();
  const [magicLink, setMagicLink] = useState<string | null>(null);
  const [copied, setCopied] = useState(false);

  const handleGenerateLink = async () => {
    try {
      const link = await generateMagicLink(taskId);
      setMagicLink(link);
    } catch (error) {
      console.error('Failed to generate magic link:', error);
    }
  };

  const handleCopyLink = async () => {
    if (!magicLink) return;
    
    try {
      await navigator.clipboard.writeText(magicLink);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (error) {
      console.error('Failed to copy link:', error);
    }
  };

  return (
    <div className={cn("space-y-4", className)}>
      <button
        onClick={handleGenerateLink}
        disabled={isLoading}
        className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-brand-600 hover:bg-brand-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500 disabled:opacity-50"
      >
        {isLoading ? (
          <>
            <Loader2 className="w-4 h-4 mr-2 animate-spin" />
            Generating...
          </>
        ) : (
          <>
            <Link2 className="w-4 h-4 mr-2" />
            Generate Magic Link
          </>
        )}
      </button>

      {error && (
        <p className="text-sm text-red-600">
          {error}
        </p>
      )}

      {magicLink && (
        <div className="flex items-center space-x-2 p-4 bg-gray-50 rounded-md">
          <input
            type="text"
            value={magicLink}
            readOnly
            className="flex-1 block w-full px-3 py-2 text-sm border border-gray-300 rounded-md bg-white shadow-sm focus:outline-none focus:ring-brand-500 focus:border-brand-500"
          />
          <button
            onClick={handleCopyLink}
            className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
          >
            {copied ? (
              <CheckCircle2 className="w-4 h-4 text-green-500" />
            ) : (
              <Copy className="w-4 h-4" />
            )}
          </button>
        </div>
      )}
    </div>
  );
}