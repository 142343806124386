import React from 'react';

export default function LoadingScreen() {
  return (
    <div className="min-h-screen bg-gray-50 flex flex-col items-center justify-center">
      <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-indigo-600 mb-4"></div>
      <h2 className="text-xl text-gray-600">Loading...</h2>
      <p className="text-gray-500 mt-2">Please wait while we set things up.</p>
    </div>
  );
}