import { useMemo } from 'react';
import { format } from 'date-fns';
import { MessageSquare } from 'lucide-react';
import { DashboardWidget } from '../../DashboardWidget';
import { useTaskList } from '../../../../hooks/task';
import { useTaskComments } from '../../../../hooks/task/useTaskComments';
import { cn } from '../../../../lib/utils';

export function CommentThreadWidget() {
  const { tasks } = useTaskList();
  const recentTasks = useMemo(() => tasks.slice(0, 5), [tasks]); // Get 5 most recent tasks

  return (
    <DashboardWidget 
      title="Recent Discussions" 
      maxHeight={500}
      showExpandButton={true}
    >
      <div className="space-y-6">
        {recentTasks.length > 0 ? (
          recentTasks.map(task => (
            <TaskDiscussionThread key={task.id} taskId={task.id} taskTitle={task.title} />
          ))
        ) : (
          <div className="text-center py-8 text-gray-500">
            <MessageSquare className="w-8 h-8 mx-auto mb-2 text-gray-400" />
            <p>No recent discussions</p>
          </div>
        )}
      </div>
    </DashboardWidget>
  );
}

function TaskDiscussionThread({ taskId, taskTitle }: { taskId: number; taskTitle: string }) {
  const { comments, isLoading } = useTaskComments(taskId);

  if (isLoading || comments.length === 0) return null;

  return (
    <div className="border-b border-gray-200 last:border-0 pb-4 last:pb-0">
      <div className="flex items-center justify-between mb-3">
        <h4 className="text-sm font-medium text-gray-900">
          {taskTitle}
        </h4>
        <span className="text-xs text-gray-500">
          {comments.length} comment{comments.length !== 1 ? 's' : ''}
        </span>
      </div>

      <div className="space-y-3">
        {comments.slice(0, 3).map((comment) => (
          <div 
            key={comment.id} 
            className={cn(
              "flex space-x-3 p-3 rounded-lg",
              "bg-gray-50 hover:bg-gray-100 transition-colors"
            )}
          >
            <div className="flex-shrink-0">
              <div className="h-8 w-8 rounded-full bg-brand-100 flex items-center justify-center">
                <span className="text-sm font-medium text-brand-600">
                  {comment.user.firstName?.[0] || comment.user.email[0]}
                </span>
              </div>
            </div>
            <div className="flex-1 min-w-0">
              <div className="flex items-center justify-between">
                <p className="text-sm font-medium text-gray-900">
                  {comment.user.firstName && comment.user.lastName
                    ? `${comment.user.firstName} ${comment.user.lastName}`
                    : comment.user.email}
                </p>
                <span className="text-xs text-gray-500">
                  {format(new Date(comment.createdAt), 'MMM d, h:mm a')}
                </span>
              </div>
              <p className="mt-1 text-sm text-gray-700 line-clamp-2">
                {comment.content}
              </p>
            </div>
          </div>
        ))}

        {comments.length > 3 && (
          <div className="text-center">
            <span className="text-xs text-gray-500">
              +{comments.length - 3} more comments
            </span>
          </div>
        )}
      </div>
    </div>
  );
}