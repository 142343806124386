import { CustomSelect } from '../../../common/CustomSelect';
import type { ReportFilters as ReportFiltersType } from './types';

interface ReportFiltersProps {
  filters: ReportFiltersType;
  onChange: (filters: ReportFiltersType) => void;
}

export function ReportFilters({ filters, onChange }: ReportFiltersProps) {
  return (
    <div className="flex space-x-4">
      <CustomSelect
        value={filters.dateRange}
        onChange={(value) => onChange({ ...filters, dateRange: value as string })}
        options={[
          { value: 'last7days', label: 'Last 7 Days' },
          { value: 'last30days', label: 'Last 30 Days' },
          { value: 'last90days', label: 'Last 90 Days' },
          { value: 'thisYear', label: 'This Year' }
        ]}
        label="Date Range"
      />

      <CustomSelect
        value={filters.groupBy}
        onChange={(value) => onChange({ ...filters, groupBy: value as string })}
        options={[
          { value: 'status', label: 'Status' },
          { value: 'priority', label: 'Priority' },
          { value: 'assignee', label: 'Assignee' }
        ]}
        label="Group By"
      />

      <CustomSelect
        value={filters.metrics}
        onChange={(value) => onChange({ ...filters, metrics: value as string[] })}
        options={[
          { value: 'count', label: 'Task Count' },
          { value: 'completion_rate', label: 'Completion Rate' },
          { value: 'avg_completion_time', label: 'Avg. Completion Time' }
        ]}
        isMulti
        label="Metrics"
      />
    </div>
  );
}