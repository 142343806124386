import { useMemo } from 'react';
import { 
  eachDayOfInterval, 
  subDays, 
  startOfDay,
  differenceInDays 
} from 'date-fns';
import type { Task } from '../../../../../types/task';
import type { 
  CompletionTrendData, 
  VelocityData, 
  BottleneckData 
} from '../types';

export function useAnalyticsData(tasks: Task[]) {
  const completionTrend = useMemo(() => {
    const last14Days = eachDayOfInterval({
      start: subDays(new Date(), 13),
      end: new Date()
    });

    return last14Days.map(date => {
      const dayStart = startOfDay(date);
      const completed = tasks.filter(task => {
        const completedDate = task.status === 'completed' 
          ? new Date(task.updatedAt)
          : null;
        return completedDate && 
          startOfDay(completedDate).getTime() === dayStart.getTime();
      }).length;

      return {
        date: date.toISOString(),
        completed
      };
    });
  }, [tasks]);

  const velocityData = useMemo(() => {
    const periodLength = 7; // 7 days
    const now = new Date();
    const periodEnd = startOfDay(now);
    const periodStart = startOfDay(subDays(now, periodLength));
    const previousPeriodEnd = startOfDay(subDays(now, periodLength + 1));
    const previousPeriodStart = startOfDay(subDays(now, periodLength * 2 + 1));

    const currentPeriodTasks = tasks.filter(task => {
      const completedDate = task.status === 'completed' 
        ? new Date(task.updatedAt)
        : null;
      return completedDate && 
        completedDate >= periodStart &&
        completedDate <= periodEnd;
    }).length;

    const previousPeriodTasks = tasks.filter(task => {
      const completedDate = task.status === 'completed'
        ? new Date(task.updatedAt)
        : null;
      return completedDate &&
        completedDate >= previousPeriodStart &&
        completedDate <= previousPeriodEnd;
    }).length;

    return {
      current: currentPeriodTasks / periodLength,
      previous: previousPeriodTasks / periodLength,
      periodLength
    };
  }, [tasks]);

  const bottlenecks = useMemo(() => {
    const bottlenecks: BottleneckData[] = [];

    // Check for blocked tasks
    const blockedTasks = tasks.filter(task => task.status === 'blocked');
    if (blockedTasks.length > 0) {
      bottlenecks.push({
        title: 'Blocked Tasks',
        description: `${blockedTasks.length} tasks are currently blocked`,
        recommendation: 'Review blocked tasks and identify common blockers'
      });
    }

    // Check for overdue tasks
    const overdueTasks = tasks.filter(task => {
      if (!task.dueDate || task.status === 'completed') return false;
      return new Date(task.dueDate) < new Date();
    });
    if (overdueTasks.length > 0) {
      bottlenecks.push({
        title: 'Overdue Tasks',
        description: `${overdueTasks.length} tasks are past their due date`,
        recommendation: 'Prioritize overdue tasks and reassess deadlines'
      });
    }

    // Check for long-running tasks
    const longRunningTasks = tasks.filter(task => {
      if (task.status === 'completed') return false;
      const createdDate = new Date(task.createdAt);
      return differenceInDays(new Date(), createdDate) > 14;
    });
    if (longRunningTasks.length > 0) {
      bottlenecks.push({
        title: 'Long-Running Tasks',
        description: `${longRunningTasks.length} tasks are older than 14 days`,
        recommendation: 'Break down long-running tasks into smaller subtasks'
      });
    }

    return bottlenecks;
  }, [tasks]);

  return {
    completionTrend,
    velocityData,
    bottlenecks
  };
}