import { Task } from '../types/task';

export const formatTaskId = (id: number, isSubtask: boolean = false) => {
  return `${isSubtask ? 'SUBTSK' : 'TSK'}${id.toString().padStart(4, '0')}`;
};

export const getTaskDisplayId = (task: Task) => {
  // Check if task is a subtask by checking if it has a parentId
  const isSubtask = task.parentId !== null;
  return formatTaskId(task.id, isSubtask);
};