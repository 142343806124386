import React, { useState } from 'react';
import { Plus, AlertCircle } from 'lucide-react';
import { ROLES } from '../../types/auth';
import { useAuth0 } from '@auth0/auth0-react';
import NewRoleModal from './NewRoleModal';
import RoleCard from './RoleCard';
import FeatureGate from '../FeatureGate';
import { useRoles } from '../../hooks/useRoles';

export const DEFAULT_PERMISSIONS = {
  tasks: [
    'create:task',
    'read:task:all',
    'read:task:team',
    'read:task:assigned',
    'update:task:all',
    'update:task:team',
    'update:task:assigned',
    'delete:task:all',
    'delete:task:team',
    'create:subtask',
    'read:subtask:all',
    'read:subtask:team',
    'read:subtask:assigned',
    'update:subtask:all',
    'update:subtask:team',
    'update:subtask:assigned',
    'delete:subtask:all',
    'delete:subtask:team'
  ],
  users: [
    'manage:users',
    'manage:roles'
  ],
  roles: [
    'create:role',
    'read:role',
    'update:role',
    'delete:role',
    'assign:role'
  ]
};

export default function RolesAndPermissions() {
  const [isNewRoleModalOpen, setIsNewRoleModalOpen] = useState(false);
  const { roles, isLoading, error, updateRolePermissions, deleteRole, refreshRoles } = useRoles();

  const handleUpdatePermissions = async (roleName: string, permissions: string[]) => {
    try {
      await updateRolePermissions(roleName, permissions);
    } catch (error: any) {
      console.error('Failed to update role permissions:', error);
    }
  };

  const handleDeleteRole = async (roleName: string) => {
    if (!window.confirm(`Are you sure you want to delete the ${roleName} role?`)) {
      return;
    }

    try {
      await deleteRole(roleName);
    } catch (error: any) {
      console.error('Failed to delete role:', error);
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-brand-600"></div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-lg font-medium text-gray-900">Roles</h2>
        <FeatureGate feature="MANAGE_ROLES">
          <button
            onClick={() => setIsNewRoleModalOpen(true)}
            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-brand-600 hover:bg-brand-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
          >
            <Plus className="w-4 h-4 mr-2" />
            New Role
          </button>
        </FeatureGate>
      </div>

      {error && (
        <div className="rounded-md bg-red-50 p-4">
          <div className="flex">
            <AlertCircle className="h-5 w-5 text-red-400" />
            <div className="ml-3">
              <h3 className="text-sm font-medium text-red-800">{error}</h3>
            </div>
          </div>
        </div>
      )}

      <div className="space-y-4">
        {/* Built-in roles */}
        {Object.entries(ROLES).map(([roleKey, role]) => (
          <RoleCard
            key={roleKey}
            role={role}
            permissions={DEFAULT_PERMISSIONS}
            selectedPermissions={role.permissions || []}
            onUpdatePermissions={(permissions) => 
              handleUpdatePermissions(roleKey, permissions)
            }
          />
        ))}

        {/* Custom roles */}
        {Object.entries(roles).map(([roleName, role]) => (
          <RoleCard
            key={roleName}
            role={role}
            permissions={DEFAULT_PERMISSIONS}
            selectedPermissions={role.permissions || []}
            onUpdatePermissions={(permissions) => 
              handleUpdatePermissions(roleName, permissions)
            }
            onDelete={() => handleDeleteRole(roleName)}
          />
        ))}
      </div>

      <NewRoleModal
        isOpen={isNewRoleModalOpen}
        onClose={() => setIsNewRoleModalOpen(false)}
        onRoleCreated={refreshRoles}
      />
    </div>
  );
}