import { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { RoleDefinition } from '../types/auth';

export function useRoles() {
  const { getAccessTokenSilently } = useAuth0();
  const [roles, setRoles] = useState<Record<string, RoleDefinition>>({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchRoles = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await axios.get(
        `${import.meta.env.VITE_API_URL}/api/roles`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setRoles(response.data);
      setError(null);
    } catch (error: any) {
      setError(error.response?.data?.message || 'Failed to fetch roles');
    } finally {
      setIsLoading(false);
    }
  };

  const createRole = async (roleData: {
    name: string;
    description: string;
    permissions: string[];
  }) => {
    try {
      const token = await getAccessTokenSilently();
      const response = await axios.post(
        `${import.meta.env.VITE_API_URL}/api/roles`,
        roleData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );
      await fetchRoles();
      return response.data;
    } catch (error: any) {
      throw new Error(error.response?.data?.message || 'Failed to create role');
    }
  };

  const updateRolePermissions = async (roleName: string, permissions: string[]) => {
    try {
      const token = await getAccessTokenSilently();
      const response = await axios.put(
        `${import.meta.env.VITE_API_URL}/api/roles/${roleName}/permissions`,
        { permissions },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );
      await fetchRoles();
      return response.data;
    } catch (error: any) {
      throw new Error(error.response?.data?.message || 'Failed to update role permissions');
    }
  };

  const deleteRole = async (roleName: string) => {
    try {
      const token = await getAccessTokenSilently();
      await axios.delete(
        `${import.meta.env.VITE_API_URL}/api/roles/${roleName}`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      await fetchRoles();
    } catch (error: any) {
      throw new Error(error.response?.data?.message || 'Failed to delete role');
    }
  };

  useEffect(() => {
    fetchRoles();
  }, []);

  return {
    roles,
    isLoading,
    error,
    createRole,
    updateRolePermissions,
    deleteRole,
    refreshRoles: fetchRoles
  };
}