export enum NotificationType {
  TASK_CREATED = 'task:created',
  TASK_UPDATED = 'task:updated',
  TASK_ASSIGNED = 'task:assigned',
  TASK_COMMENTED = 'task:commented',
  FILE_UPLOADED = 'file:uploaded',
  USER_INVITED = 'user:invited',
  USER_JOINED = 'user:joined',
  CUSTOM_FIELD_UPDATED = 'customField:updated',
  SUBTASK_CREATED = 'subtask:created',
  SUBTASK_UPDATED = 'subtask:updated',
  SUBTASK_ASSIGNED = 'subtask:assigned',
  SUBTASK_COMPLETED = 'subtask:completed'
}

export interface NotificationPayload {
  id: string;
  type: NotificationType;
  title: string;
  message: string;
  recipientId: number;
  organizationId: number;
  metadata?: Record<string, any>;
  read: boolean;
  createdAt: Date;
}