import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { Settings2 } from 'lucide-react';
import { DashboardGrid } from '../components/dashboard/DashboardGrid';
import { DashboardCustomizeModal } from '../components/dashboard/DashboardCustomizeModal';
import { DashboardEmptyState } from '../components/dashboard/DashboardEmptyState';
import { DashboardLoading } from '../components/dashboard/DashboardLoading';
import { DashboardError } from '../components/dashboard/DashboardError';
import { useDashboardLayout } from '../hooks/useDashboardLayout';
import { useToast } from '../context/ToastContext';

export default function Dashboard() {
  const { user } = useAuth0();
  const { showToast } = useToast();
  const [isCustomizing, setIsCustomizing] = useState(false);
  const { 
    layout, 
    isLoading, 
    error, 
    updateLayout, 
    resetLayout,
    refreshLayout 
  } = useDashboardLayout();

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const newLayout = Array.from(layout);
    const [removed] = newLayout.splice(result.source.index, 1);
    newLayout.splice(result.destination.index, 0, removed);

    // Update positions
    const updatedLayout = newLayout.map((widget, index) => ({
      ...widget,
      position: index
    }));

    updateLayout(updatedLayout).catch(() => {
      showToast('Failed to update widget order', 'error');
    });
  };

  if (isLoading) {
    return <DashboardLoading />;
  }

  if (error) {
    return <DashboardError error={error} onRetry={refreshLayout} />;
  }

  const visibleWidgets = layout.filter(widget => widget.visible);
  if (visibleWidgets.length === 0) {
    return <DashboardEmptyState onCustomize={() => setIsCustomizing(true)} />;
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      {/* Header */}
      <div className="flex justify-between items-center mb-8">
        <div>
          <h1 className="text-2xl font-bold text-gray-900">
            Welcome back, {user?.email}
          </h1>
          <p className="mt-1 text-sm text-gray-500">
            Here's what's happening in your workspace
          </p>
        </div>

        <button
          onClick={() => setIsCustomizing(true)}
          className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
        >
          <Settings2 className="w-4 h-4 mr-2" />
          Customize Dashboard
        </button>
      </div>

      {/* Dashboard Grid */}
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="dashboard">
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6"
            >
              <DashboardGrid layout={layout} />
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      {/* Customize Modal */}
      <DashboardCustomizeModal
        isOpen={isCustomizing}
        onClose={() => setIsCustomizing(false)}
        layout={layout}
        onUpdateLayout={updateLayout}
        onResetLayout={resetLayout}
      />
    </div>
  );
}