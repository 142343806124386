import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Plus } from 'lucide-react';
import { useTaskList, useTaskMutations } from '../../hooks/task';
import { TaskList, TaskGrid, TaskKanban, TaskTimeline } from '../../components/task/views';
import { TaskFilters } from '../../components/task/views/TaskFilters';
import { TaskSearch } from '../../components/task/views/TaskSearch';
import { ViewSelector } from '../../components/task/views/ViewSelector';
import { ViewType } from '../../components/task/views/ViewSelector/types';
import { PermissionGate } from '../../components/permissions';
import { TASK_PERMISSIONS } from '../../types/permission';
import { Task, CreateTaskInput } from '../../types/task';
import { TaskForm } from '../../components/task/TaskForm';
import Modal from '../../components/common/Modal/Modal';
import { useTabState } from '../../hooks/useTabState';
import { useDebounce } from '../../hooks/useDebounce';
import { useToast } from '../../context/ToastContext';
import { cn } from '../../lib/utils';

type TaskTab = 'all' | 'my-tasks';

export function TasksPage() {
  const navigate = useNavigate();
  const { showToast } = useToast();
  const [activeTab, setActiveTab] = useTabState('taskTab', 'all' as TaskTab);
  const [view, setView] = useTabState('taskView', 'list' as ViewType);
  const [searchQuery, setSearchQuery] = useState('');
  const [filters, setFilters] = useState({});
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [editingTask, setEditingTask] = useState<Task | null>(null);
  const debouncedSearch = useDebounce(searchQuery, 300);
  
  const { tasks, isLoading, error, refreshTasks } = useTaskList({
    ...filters,
    search: debouncedSearch || undefined,
    showSubtasksOnly: activeTab === 'my-tasks',
    assignedToMe: activeTab === 'my-tasks'
  });
  
  const { createTask, updateTask, deleteTask } = useTaskMutations();

  const handleTaskClick = (task: Task) => {
    navigate(`/tasks/${task.id}`);
  };

  const handleCreateTask = async (data: CreateTaskInput) => {
    try {
      await createTask(data);
      setIsCreateModalOpen(false);
      refreshTasks();
      showToast('Task created successfully', 'success');
    } catch (error) {
      console.error('Failed to create task:', error);
      showToast('Failed to create task', 'error');
    }
  };

  const handleEditTask = async (data: CreateTaskInput) => {
    if (!editingTask) return;
    
    try {
      await updateTask(editingTask.id, { ...data, id: editingTask.id });
      setEditingTask(null);
      refreshTasks();
      showToast('Task updated successfully', 'success');
    } catch (error) {
      console.error('Failed to update task:', error);
      showToast('Failed to update task', 'error');
    }
  };

  const handleDeleteTask = async (taskId: number) => {
    try {
      await deleteTask(taskId);
      refreshTasks();
      showToast('Task deleted successfully', 'success');
    } catch (error) {
      console.error('Failed to delete task:', error);
      showToast('Failed to delete task', 'error');
    }
  };

  const renderTaskView = () => {
    const props = {
      tasks,
      isLoading,
      error: error || undefined,
      onTaskClick: handleTaskClick,
      onTaskEdit: setEditingTask,
      onTaskDelete: handleDeleteTask
    };

    switch (view) {
      case 'grid':
        return <TaskGrid {...props} />;
      case 'kanban':
        return <TaskKanban {...props} />;
      case 'timeline':
        return <TaskTimeline {...props} />;
      default:
        return <TaskList {...props} />;
    }
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="mb-8">
        <div className="flex justify-between items-center">
          <h1 className="text-2xl font-bold text-gray-900">Tasks</h1>
          <PermissionGate permission={TASK_PERMISSIONS.CREATE}>
            <button
              type="button"
              onClick={() => setIsCreateModalOpen(true)}
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-brand-600 hover:bg-brand-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
            >
              <Plus className="-ml-1 mr-2 h-5 w-5" />
              New Task
            </button>
          </PermissionGate>
        </div>

        {/* Task Tabs */}
        <div className="mt-4 border-b border-gray-200">
          <nav className="-mb-px flex space-x-8">
            <button
              onClick={() => setActiveTab('all')}
              className={cn(
                'whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm',
                activeTab === 'all'
                  ? 'border-brand-500 text-brand-600'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              )}
            >
              Tasks
            </button>
            <button
              onClick={() => setActiveTab('my-tasks')}
              className={cn(
                'whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm',
                activeTab === 'my-tasks'
                  ? 'border-brand-500 text-brand-600'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              )}
            >
              Tasks & Subtasks
            </button>
          </nav>
        </div>
      </div>

      {/* Search and View Controls */}
      <div className="mb-6">
        <div className="flex items-center justify-between space-x-4">
          <TaskSearch value={searchQuery} onChange={setSearchQuery} />
          <div className="flex items-center space-x-4">
            <ViewSelector view={view as ViewType} onViewChange={setView} />
            <TaskFilters filters={filters} onFilterChange={setFilters} />
          </div>
        </div>
      </div>

      {renderTaskView()}

      {/* Create Task Modal */}
      <Modal
        isOpen={isCreateModalOpen}
        onClose={() => setIsCreateModalOpen(false)}
        title="Create New Task"
      >
        <TaskForm
          onSubmit={handleCreateTask}
          onCancel={() => setIsCreateModalOpen(false)}
        />
      </Modal>

      {/* Edit Task Modal */}
      <Modal
        isOpen={!!editingTask}
        onClose={() => setEditingTask(null)}
        title="Edit Task"
      >
        <TaskForm
          initialData={editingTask || undefined}
          onSubmit={handleEditTask}
          onCancel={() => setEditingTask(null)}
        />
      </Modal>
    </div>
  );
}