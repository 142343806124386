import React, { useRef, useState } from 'react';
import { Upload, X, FileText, AlertCircle, Loader2 } from 'lucide-react';
import { cn } from '../../../lib/utils';
import type { FileUploadProps } from './types';

const formatFileSize = (bytes: number) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
};

export function FileUpload({
  value,
  onChange,
  multiple = false,
  accept,
  maxSize,
  error,
  isDisabled,
  required,
  placeholder = 'Click or drag files to upload',
  className,
  isUploading,
  progress
}: FileUploadProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isDragOver, setIsDragOver] = useState(false);

  const handleClick = () => {
    if (!isDisabled && !isUploading) {
      inputRef.current?.click();
    }
  };

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (!isDisabled && !isUploading) {
      setIsDragOver(true);
    }
  };

  const handleDragLeave = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragOver(false);
  };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragOver(false);

    if (isDisabled || isUploading) return;

    const droppedFiles = Array.from(e.dataTransfer.files);
    handleFiles(droppedFiles);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = Array.from(e.target.files || []);
    handleFiles(selectedFiles);
    // Reset input value to allow selecting the same file again
    e.target.value = '';
  };

  const handleFiles = (files: File[]) => {
    // Validate file types
    if (accept) {
      const acceptedTypes = accept.split(',').map(type => type.trim());
      files = files.filter(file => {
        return acceptedTypes.some(type => {
          if (type.startsWith('.')) {
            return file.name.toLowerCase().endsWith(type.toLowerCase());
          }
          if (type.endsWith('/*')) {
            return file.type.startsWith(type.replace('/*', '/'));
          }
          return file.type === type;
        });
      });
    }

    // Validate file size
    if (maxSize) {
      files = files.filter(file => file.size <= maxSize);
    }

    if (files.length > 0) {
      onChange(multiple ? files : [files[0]]);
    }
  };

  const removeFile = (index: number) => {
    if (!value) return;
    const newFiles = value.filter((_, i) => i !== index);
    onChange(newFiles.length > 0 ? newFiles : null);
  };

  return (
    <div className={className}>
      <div
        onClick={handleClick}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        className={cn(
          'relative border-2 border-dashed rounded-lg p-6',
          'transition-colors duration-200 ease-in-out',
          isDragOver && 'border-brand-500 bg-brand-50',
          isDisabled || isUploading
            ? 'bg-gray-50 border-gray-200 cursor-not-allowed'
            : 'border-gray-300 hover:border-brand-500 cursor-pointer',
          error && 'border-red-300 hover:border-red-500'
        )}
      >
        <input
          ref={inputRef}
          type="file"
          className="hidden"
          onChange={handleFileChange}
          accept={accept}
          multiple={multiple}
          disabled={isDisabled || isUploading}
          required={required && (!value || value.length === 0)}
        />

        <div className="text-center">
          {isUploading ? (
            <>
              <Loader2 className="mx-auto h-12 w-12 text-brand-600 animate-spin" />
              <p className="mt-2 text-sm text-gray-500">
                Uploading... {progress}%
              </p>
            </>
          ) : (
            <>
              <Upload className="mx-auto h-12 w-12 text-gray-400" />
              <p className="mt-2 text-sm text-gray-500">{placeholder}</p>
              {maxSize && (
                <p className="mt-1 text-xs text-gray-400">
                  Max file size: {formatFileSize(maxSize)}
                </p>
              )}
              {accept && (
                <p className="mt-1 text-xs text-gray-400">
                  Accepted types: {accept}
                </p>
              )}
            </>
          )}
        </div>
      </div>

      {/* File List */}
      {value && value.length > 0 && (
        <ul className="mt-4 space-y-2">
          {value.map((file, index) => (
            <li
              key={`${file.name}-${index}`}
              className="flex items-center justify-between p-2 bg-gray-50 rounded-md"
            >
              <div className="flex items-center space-x-2">
                <FileText className="h-4 w-4 text-gray-400" />
                <span className="text-sm text-gray-700">{file.name}</span>
                <span className="text-xs text-gray-500">
                  ({formatFileSize(file.size)})
                </span>
              </div>
              {!isDisabled && !isUploading && (
                <button
                  type="button"
                  onClick={(e) => {
                    e.stopPropagation();
                    removeFile(index);
                  }}
                  className="p-1 hover:bg-gray-200 rounded-full"
                >
                  <X className="h-4 w-4 text-gray-500" />
                </button>
              )}
            </li>
          ))}
        </ul>
      )}

      {error && (
        <div className="mt-2 flex items-center text-sm text-red-600">
          <AlertCircle className="h-4 w-4 mr-1" />
          {error}
        </div>
      )}
    </div>
  );
}