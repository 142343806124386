import { useEffect } from 'react';
import { CustomFieldInput } from '../../common/CustomFieldInput';
import { useFileUpload } from '../../../hooks/useFileUpload';
import type { CustomFieldsSectionProps } from './types';

export function CustomFieldsSection({
  fields,
  values,
  onChange,
  errors,
  isDisabled,
  taskId
}: CustomFieldsSectionProps) {
  // Initialize file upload hooks for file/image fields
  const fileUploadHooks = fields
    .filter(field => field.type === 'file' || field.type === 'image')
    .reduce((acc, field) => {
      acc[field.id] = useFileUpload({
        taskId: taskId!,
        fieldId: field.id,
        onSuccess: (uploadedFiles) => {
          // Update the field value with the uploaded file information
          onChange(field.id.toString(), uploadedFiles);
        },
        onError: (error) => {
          console.error('File upload error:', error);
        }
      });
      return acc;
    }, {} as Record<number, ReturnType<typeof useFileUpload>>);

  // Handle file uploads when files are selected
  useEffect(() => {
    const uploadPendingFiles = async () => {
      for (const field of fields) {
        if ((field.type === 'file' || field.type === 'image') && values[field.id]) {
          const files = values[field.id] as File[];
          if (files.length > 0 && files[0] instanceof File) {
            try {
              await fileUploadHooks[field.id].uploadFiles(files);
            } catch (error) {
              console.error(`Failed to upload files for field ${field.id}:`, error);
            }
          }
        }
      }
    };

    uploadPendingFiles();
  }, [values, fields]);

  if (fields.length === 0) {
    return null;
  }

  return (
    <div className="space-y-4">
      <h3 className="text-sm font-medium text-gray-700">Additional Fields</h3>
      <div className="grid grid-cols-2 gap-4">
        {fields.map((field) => (
          <CustomFieldInput
            key={field.id}
            field={field}
            value={values[field.id] || null}
            onChange={(value) => onChange(field.id.toString(), value)}
            error={errors?.[field.id]}
            isDisabled={isDisabled}
            required={field.required}
            isUploading={fileUploadHooks[field.id]?.isUploading}
            uploadProgress={fileUploadHooks[field.id]?.progress}
          />
        ))}
      </div>
    </div>
  );
}