import { AlertCircle } from 'lucide-react';

interface DashboardErrorProps {
  error: string;
  onRetry: () => void;
}

export function DashboardError({ error, onRetry }: DashboardErrorProps) {
  return (
    <div className="text-center py-12">
      <div className="flex justify-center">
        <AlertCircle className="w-12 h-12 text-red-500" />
      </div>
      <h3 className="mt-2 text-sm font-semibold text-gray-900">Error loading dashboard</h3>
      <p className="mt-1 text-sm text-gray-500">{error}</p>
      <div className="mt-6">
        <button
          onClick={onRetry}
          className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-brand-600 hover:bg-brand-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
        >
          Try Again
        </button>
      </div>
    </div>
  );
}