import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { Plus, Settings2, AlertCircle, Loader2 } from 'lucide-react';
import { useCustomFields } from '../../../hooks/task';
import { CustomFieldsProps } from './types';
import { CustomFieldForm } from './CustomFieldForm';
import Modal from '../../common/Modal/Modal';
import { PermissionGate } from '../../permissions';
import { CUSTOM_FIELD_PERMISSIONS } from '../../../types/permission';
import { AlertDialog } from '../../common/AlertDialog';
import { cn } from '../../../lib/utils';
import type { CustomField, CustomFieldStatus } from '../../../types/task';

export function CustomFields({ organization }: CustomFieldsProps) {
  const { getAccessTokenSilently } = useAuth0();
  const { customFields, isLoading, error, refreshCustomFields } = useCustomFields(organization.id);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [editingField, setEditingField] = useState<CustomField | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [deleteAlert, setDeleteAlert] = useState<{ isOpen: boolean; fieldId: number | null }>({
    isOpen: false,
    fieldId: null
  });

  const handleCreateField = async (data: any) => {
    setIsSubmitting(true);
    try {
      const token = await getAccessTokenSilently();
      await axios.post(
        `${import.meta.env.VITE_API_URL}/api/custom-fields`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );
      setIsCreateModalOpen(false);
      refreshCustomFields();
    } catch (error: any) {
      console.error('Failed to create custom field:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleUpdateField = async (data: any) => {
    if (!editingField) return;
    
    setIsSubmitting(true);
    try {
      const token = await getAccessTokenSilently();
      await axios.put(
        `${import.meta.env.VITE_API_URL}/api/custom-fields/${editingField.id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );
      setEditingField(null);
      refreshCustomFields();
    } catch (error: any) {
      console.error('Failed to update custom field:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDeleteField = (fieldId: number) => {
    setDeleteAlert({ isOpen: true, fieldId });
  };

  const confirmDelete = async () => {
    if (!deleteAlert.fieldId) return;

    try {
      const token = await getAccessTokenSilently();
      await axios.delete(
        `${import.meta.env.VITE_API_URL}/api/custom-fields/${deleteAlert.fieldId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      refreshCustomFields();
    } catch (error: any) {
      console.error('Failed to delete custom field:', error);
    } finally {
      setDeleteAlert({ isOpen: false, fieldId: null });
    }
  };

  const handleToggleStatus = async (fieldId: number, currentStatus: CustomFieldStatus) => {
    try {
      const token = await getAccessTokenSilently();
      const newStatus: CustomFieldStatus = currentStatus === 'active' ? 'inactive' : 'active';
      
      await axios.put(
        `${import.meta.env.VITE_API_URL}/api/custom-fields/${fieldId}`,
        {
          status: newStatus
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );
      refreshCustomFields();
    } catch (error: any) {
      console.error('Failed to update custom field status:', error);
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <Loader2 className="w-8 h-8 animate-spin text-brand-600" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="rounded-md bg-red-50 p-4">
        <div className="flex">
          <AlertCircle className="h-5 w-5 text-red-400" />
          <div className="ml-3">
            <h3 className="text-sm font-medium text-red-800">{error}</h3>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-lg font-medium text-gray-900">Custom Fields</h2>
        <PermissionGate permission={CUSTOM_FIELD_PERMISSIONS.MANAGE}>
          <button
            onClick={() => setIsCreateModalOpen(true)}
            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-brand-600 hover:bg-brand-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
          >
            <Plus className="w-4 h-4 mr-2" />
            Add Custom Field
          </button>
        </PermissionGate>
      </div>

      {/* Custom Fields List */}
      <div className="bg-white shadow-sm rounded-lg border border-gray-200 overflow-hidden">
        {customFields.length > 0 ? (
          <ul className="divide-y divide-gray-200">
            {customFields.map((field) => (
              <li key={field.id} className="p-4 hover:bg-gray-50">
                <div className="flex items-center justify-between">
                  <div>
                    <h3 className="text-sm font-medium text-gray-900">
                      {field.name}
                    </h3>
                    <p className="text-sm text-gray-500">
                      {field.description}
                    </p>
                    <div className="mt-1 flex items-center space-x-2">
                      <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                        {field.type}
                      </span>
                      {field.required && (
                        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                          Required
                        </span>
                      )}
                      <span className={cn(
                        "inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium",
                        field.status === 'active' 
                          ? "bg-green-100 text-green-800"
                          : "bg-gray-100 text-gray-800"
                      )}>
                        {field.status === 'active' ? 'Active' : 'Inactive'}
                      </span>
                    </div>
                  </div>
                  <PermissionGate permission={CUSTOM_FIELD_PERMISSIONS.MANAGE}>
                    <div className="flex items-center space-x-2">
                      <label className="inline-flex items-center">
                        <input
                          type="checkbox"
                          checked={field.status === 'active'}
                          onChange={() => handleToggleStatus(field.id, field.status)}
                          className="rounded border-gray-300 text-brand-600 shadow-sm focus:border-brand-500 focus:ring focus:ring-brand-500 focus:ring-opacity-50"
                        />
                        <span className="ml-2 text-sm text-gray-600">
                          Show in forms
                        </span>
                      </label>
                      <button
                        onClick={() => setEditingField(field)}
                        className="p-2 text-gray-400 hover:text-gray-500 rounded-full hover:bg-gray-100"
                      >
                        <Settings2 className="w-4 h-4" />
                      </button>
                      <button
                        onClick={() => handleDeleteField(field.id)}
                        className="p-2 text-gray-400 hover:text-red-500 rounded-full hover:bg-gray-100"
                      >
                        <AlertCircle className="w-4 h-4" />
                      </button>
                    </div>
                  </PermissionGate>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <div className="text-center py-8">
            <p className="text-sm text-gray-500">No custom fields defined</p>
          </div>
        )}
      </div>

      {/* Create/Edit Modals */}
      <Modal
        isOpen={isCreateModalOpen}
        onClose={() => setIsCreateModalOpen(false)}
        title="Add Custom Field"
      >
        <CustomFieldForm
          onSubmit={handleCreateField}
          onCancel={() => setIsCreateModalOpen(false)}
          isSubmitting={isSubmitting}
        />
      </Modal>

      <Modal
        isOpen={!!editingField}
        onClose={() => setEditingField(null)}
        title="Edit Custom Field"
      >
        <CustomFieldForm
          initialData={editingField}
          onSubmit={handleUpdateField}
          onCancel={() => setEditingField(null)}
          isSubmitting={isSubmitting}
        />
      </Modal>

      {/* Delete Confirmation Dialog */}
      <AlertDialog
        isOpen={deleteAlert.isOpen}
        onClose={() => setDeleteAlert({ isOpen: false, fieldId: null })}
        onConfirm={confirmDelete}
        title="Delete Custom Field"
        description="Are you sure you want to delete this custom field? This action cannot be undone and will remove this field from all tasks."
        confirmLabel="Delete"
        cancelLabel="Cancel"
        variant="danger"
      />
    </div>
  );
}