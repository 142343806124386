import { useEffect, useState } from 'react';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { Loader2 } from 'lucide-react';
import { cn } from '../../lib/utils';

interface StripePaymentFormProps {
  onSubmit: (paymentMethodId: string) => Promise<void>;
  onCancel: () => void;
  amount: number;
}

export function StripePaymentForm({ onSubmit, onCancel, amount }: StripePaymentFormProps) {
  const stripe = useStripe();
  const elements = useElements();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!stripe || !elements) return;

    setIsSubmitting(true);
    setError(null);

    try {
      const { error: submitError, paymentMethod } = await stripe.createPaymentMethod({
        elements,
        params: {
          billing_details: {
            // These will be collected by Stripe Elements
          }
        }
      });

      if (submitError) {
        throw new Error(submitError.message);
      }

      if (!paymentMethod) {
        throw new Error('Failed to create payment method');
      }

      await onSubmit(paymentMethod.id);
    } catch (error: any) {
      setError(error.message || 'Failed to process payment');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <PaymentElement />

      {error && (
        <div className="text-sm text-red-600">
          {error}
        </div>
      )}

      <div className="flex justify-end space-x-3">
        <button
          type="button"
          onClick={onCancel}
          disabled={isSubmitting}
          className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
        >
          Cancel
        </button>
        <button
          type="submit"
          disabled={!stripe || !elements || isSubmitting}
          className={cn(
            "inline-flex items-center px-4 py-2 border border-transparent",
            "text-sm font-medium rounded-md shadow-sm text-white",
            "bg-brand-600 hover:bg-brand-700 focus:outline-none focus:ring-2",
            "focus:ring-offset-2 focus:ring-brand-500 disabled:opacity-50"
          )}
        >
          {isSubmitting ? (
            <>
              <Loader2 className="w-4 h-4 mr-2 animate-spin" />
              Processing...
            </>
          ) : (
            `Pay $${(amount / 100).toFixed(2)}`
          )}
        </button>
      </div>
    </form>
  );
}