import { TaskStatusSelect } from '../../../selects/TaskStatusSelect';
import { TaskStatus } from '../../../../../types/task';

interface StatusFilterProps {
  value?: TaskStatus[];
  onChange: (value: TaskStatus[]) => void;
}

export function StatusFilter({ value = [], onChange }: StatusFilterProps) {
  return (
    <TaskStatusSelect
      value={null}
      onChange={(status) => {
        if (!status) return;
        const newValue = value.includes(status as TaskStatus)
          ? value.filter(s => s !== status)
          : [...value, status as TaskStatus];
        onChange(newValue);
      }}
      isMulti
      selectedValues={value}
      label="Filter by Status"
    />
  );
}