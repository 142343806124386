import { useState } from 'react';
import { format } from 'date-fns';
import { Calendar, MessageSquare, User, Edit2, Trash2, Copy } from 'lucide-react';
import { cn } from '../../../lib/utils';
import { getTaskDisplayId } from '../../../utils/formatters';
import { SubtaskCardProps } from './types';
import { TASK_STATUSES, TASK_PRIORITIES } from '../../../types/task';
import { PermissionGate } from '../../permissions';
import { TASK_PERMISSIONS } from '../../../types/permission';
import { SubtaskComments } from '../SubtaskComments';
import { AlertDialog } from '../../common/AlertDialog';

export function SubtaskCard({
  task,
  onClick,
  onDelete,
  onDuplicate,
  className
}: SubtaskCardProps) {
  const [showComments, setShowComments] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const status = TASK_STATUSES[task.status];
  const priority = TASK_PRIORITIES[task.priority];

  const handleEditClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (onClick) onClick();
  };

  const handleDeleteClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setShowDeleteConfirm(true);
  };

  const handleDuplicateClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (onDuplicate) onDuplicate();
  };

  const handleCommentsClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setShowComments(!showComments);
  };

  const handleConfirmDelete = async () => {
    try {
      if (onDelete) {
        await onDelete();
      }
    } finally {
      setShowDeleteConfirm(false);
    }
  };

  return (
    <>
      <div
        className={cn(
          'bg-white rounded-lg border border-gray-200 shadow-sm hover:shadow-md transition-shadow relative group',
          onClick && 'cursor-pointer',
          className
        )}
      >
        <div className="p-4">
          {/* Task ID Badge */}
          <div className="absolute left-2 top-2 px-2 py-1 bg-gray-100 rounded text-xs font-mono text-gray-600">
            {getTaskDisplayId(task)}
          </div>

          {/* Action Buttons */}
          <div className="absolute right-2 top-2 flex items-center space-x-2 opacity-0 group-hover:opacity-100 transition-opacity">
            <PermissionGate
              permission={TASK_PERMISSIONS.CREATE}
            >
              <button
                onClick={handleDuplicateClick}
                className="flex items-center justify-center w-8 h-8 rounded-full bg-brand-50 text-brand-600 hover:bg-brand-100 hover:text-brand-700 transition-colors"
                title="Duplicate subtask"
              >
                <Copy className="w-4 h-4" />
              </button>
            </PermissionGate>

            <PermissionGate
              permission={TASK_PERMISSIONS.UPDATE_ASSIGNED}
              options={{ ownerId: task.createdBy }}
            >
              <button
                onClick={handleEditClick}
                className="flex items-center justify-center w-8 h-8 rounded-full bg-blue-50 text-blue-600 hover:bg-blue-100 hover:text-blue-700 transition-colors"
                title="Edit subtask"
              >
                <Edit2 className="w-4 h-4" />
              </button>
            </PermissionGate>

            <PermissionGate
              permission={TASK_PERMISSIONS.DELETE_ASSIGNED}
              options={{ ownerId: task.createdBy }}
            >
              <button
                onClick={handleDeleteClick}
                className="flex items-center justify-center w-8 h-8 rounded-full bg-red-50 text-red-600 hover:bg-red-100 hover:text-red-700 transition-colors"
                title="Delete subtask"
              >
                <Trash2 className="w-4 h-4" />
              </button>
            </PermissionGate>
          </div>

          {/* Main Content */}
          <div className="pt-8">
            {/* Status and Priority */}
            <div className="flex items-center space-x-2 mb-3">
              <span
                className={cn(
                  'inline-flex items-center px-2.5 py-1 rounded-full text-xs font-medium',
                  `bg-${status.color}-100 text-${status.color}-800`
                )}
              >
                {status.label}
              </span>
              <span
                className={cn(
                  'inline-flex items-center px-2.5 py-1 rounded-full text-xs font-medium',
                  `bg-${priority.color}-100 text-${priority.color}-800`
                )}
              >
                {priority.label}
              </span>
            </div>

            {/* Title */}
            <h3 className="text-sm font-medium text-gray-900 mb-2">
              {task.title}
            </h3>

            {/* Description */}
            <p className="text-sm text-gray-500 line-clamp-2 mb-4">
              {task.description}
            </p>

            {/* Metadata */}
            <div className="flex items-center justify-between mt-4 pt-3 border-t border-gray-100">
              {/* Left side - Comment count and assignee */}
              <div className="flex items-center space-x-4">
                <button
                  onClick={handleCommentsClick}
                  className="flex items-center text-gray-500 hover:text-gray-700"
                >
                  <MessageSquare className="w-4 h-4 mr-1" />
                  <span className="text-xs">{task.comments?.length || 0}</span>
                </button>
                {task.assignee ? (
                  <div className="flex items-center text-gray-600">
                    <User className="w-4 h-4 mr-1" />
                    <span className="text-xs">
                      {task.assignee.firstName && task.assignee.lastName
                        ? `${task.assignee.firstName} ${task.assignee.lastName}`
                        : task.assignee.email}
                    </span>
                  </div>
                ) : (
                  <div className="flex items-center text-gray-400">
                    <User className="w-4 h-4 mr-1" />
                    <span className="text-xs">Unassigned</span>
                  </div>
                )}
              </div>

              {/* Right side - Due Date */}
              {task.dueDate && (
                <div className="flex items-center text-gray-500">
                  <Calendar className="w-4 h-4 mr-1" />
                  <span className="text-xs">
                    {format(new Date(task.dueDate), 'MMM d')}
                  </span>
                </div>
              )}
            </div>

            {/* Comments Section */}
            {showComments && (
              <div className="mt-4 pt-4 border-t border-gray-100" onClick={e => e.stopPropagation()}>
                <SubtaskComments taskId={task.id} />
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Delete Confirmation Dialog */}
      <AlertDialog
        isOpen={showDeleteConfirm}
        onClose={() => setShowDeleteConfirm(false)}
        onConfirm={handleConfirmDelete}
        title="Delete Subtask"
        description="Are you sure you want to delete this subtask? This action cannot be undone."
        confirmLabel="Delete"
        cancelLabel="Cancel"
        variant="danger"
      />
    </>
  );
}