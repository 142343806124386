import { forwardRef } from 'react';

interface SelectInputProps {
  value: string;
  onChange: (value: string) => void;
  placeholder: string;
}

export const SelectInput = forwardRef<HTMLInputElement, SelectInputProps>(
  ({ value, onChange, placeholder }, ref) => (
    <input
      ref={ref}
      type="text"
      className="w-full border-none p-0 focus:outline-none focus:ring-0 bg-transparent"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      placeholder={placeholder}
      onClick={(e) => e.stopPropagation()}
      autoFocus
    />
  )
);

SelectInput.displayName = 'SelectInput';