interface FilterOverlayProps {
    onClose: () => void;
  }
  
  export function FilterOverlay({ onClose }: FilterOverlayProps) {
    return (
      <div 
        className="fixed inset-0"
        onClick={onClose}
      >
        <div className="absolute inset-0 bg-gray-500 bg-opacity-25" />
      </div>
    );
  }