import { format } from 'date-fns';
import { getActivityIcon } from './utils/activityUtils';
import type { Activity } from './types';

interface ActivityItemProps {
  activity: Activity;
}

export function ActivityItem({ activity }: ActivityItemProps) {
  const Icon = getActivityIcon(activity.type);

  return (
    <div className="flex space-x-3">
      <div className="flex-shrink-0">
        <div className="h-8 w-8 rounded-full bg-brand-100 flex items-center justify-center">
          <Icon className="h-4 w-4 text-brand-600" />
        </div>
      </div>
      <div className="flex-1 min-w-0">
        <p className="text-sm text-gray-900">
          <span className="font-medium">{activity.user.name}</span>
          {' '}
          {activity.description}
        </p>
        <p className="text-xs text-gray-500">
          {format(new Date(activity.timestamp), 'PPp')}
        </p>
      </div>
    </div>
  );
}