import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { CreateTaskInput, UpdateTaskInput, Task } from '../../types/task';

export function useTaskMutations() {
  const { getAccessTokenSilently } = useAuth0();

  const createTask = async (taskData: CreateTaskInput): Promise<Task> => {
    try {
      const token = await getAccessTokenSilently();
      const response = await axios.post(
        `${import.meta.env.VITE_API_URL}/api/tasks`,
        taskData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );
      return response.data;
    } catch (error: any) {
      throw new Error(error.response?.data?.message || 'Failed to create task');
    }
  };

  const updateTask = async (taskId: number, updates: UpdateTaskInput): Promise<Task> => {
    try {
      const token = await getAccessTokenSilently();
      const response = await axios.put(
        `${import.meta.env.VITE_API_URL}/api/tasks/${taskId}`,
        updates,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );
      return response.data;
    } catch (error: any) {
      throw new Error(error.response?.data?.message || 'Failed to update task');
    }
  };

  const deleteTask = async (taskId: number): Promise<void> => {
    try {
      const token = await getAccessTokenSilently();
      await axios.delete(
        `${import.meta.env.VITE_API_URL}/api/tasks/${taskId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
    } catch (error: any) {
      throw new Error(error.response?.data?.message || 'Failed to delete task');
    }
  };

  const duplicateTask = async (taskId: number, data?: { title: string; description: string }): Promise<Task> => {
    try {
      const token = await getAccessTokenSilently();
      const response = await axios.post(
        `${import.meta.env.VITE_API_URL}/api/tasks/${taskId}/duplicate`,
        data || {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );
      return response.data;
    } catch (error: any) {
      throw new Error(error.response?.data?.message || 'Failed to duplicate task');
    }
  };

  return {
    createTask,
    updateTask,
    deleteTask,
    duplicateTask
  };
}