import React from 'react';
import { X } from 'lucide-react';
import { useClickOutside } from '../../../hooks/useClickOutside';
import { cn } from '../../../lib/utils';
import type { ModalProps } from './types';

export default function Modal({
  isOpen,
  onClose,
  title,
  children,
  className
}: ModalProps) {
  const modalRef = useClickOutside(onClose);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-[100] overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
        {/* Overlay */}
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

        {/* Modal Panel */}
        <div className="inline-block align-bottom bg-white rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full">
          <div ref={modalRef} className={cn('relative', className)}>
            <div className="px-6 pt-5 pb-4 sm:p-6">
              <div className="flex items-center justify-between pb-4 mb-4 border-b">
                <h3 className="text-lg font-semibold text-gray-900">
                  {title}
                </h3>
                <button
                  onClick={onClose}
                  className="text-gray-400 hover:text-gray-500"
                >
                  <X className="h-5 w-5" />
                </button>
              </div>
              {/* Remove overflow-hidden from the content container */}
              <div className="mt-2">{children}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}