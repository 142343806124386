import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Loader2 } from 'lucide-react';
import { CustomSelect } from '../../common/CustomSelect';
import { CustomFieldFormSchema } from './schema';
import { CustomFieldFormData } from './types';
import { CustomFieldType } from '../../../types/task';

const FIELD_TYPES = [
  // Text Fields
  { value: 'text', label: 'Text', description: 'Single line text input', group: 'Text' },
  { value: 'textarea', label: 'Text Area', description: 'Multi-line text input', group: 'Text' },
  { value: 'richtext', label: 'Rich Text', description: 'Formatted text with styling', group: 'Text' },
  
  // Number Fields
  { value: 'number', label: 'Number', description: 'Numeric input', group: 'Numbers' },
  { value: 'currency', label: 'Currency', description: 'Monetary values', group: 'Numbers' },
  { value: 'percentage', label: 'Percentage', description: 'Percentage values', group: 'Numbers' },
  { value: 'rating', label: 'Rating', description: 'Star or number rating', group: 'Numbers' },
  
  // Date/Time Fields
  { value: 'date', label: 'Date', description: 'Date picker', group: 'Date & Time' },
  { value: 'time', label: 'Time', description: 'Time picker', group: 'Date & Time' },
  { value: 'datetime', label: 'Date & Time', description: 'Combined date and time', group: 'Date & Time' },
  { value: 'duration', label: 'Duration', description: 'Time duration', group: 'Date & Time' },
  
  // Choice Fields
  { value: 'select', label: 'Select', description: 'Single choice from dropdown', group: 'Choice' },
  { value: 'multiselect', label: 'Multi-Select', description: 'Multiple choices', group: 'Choice' },
  { value: 'radio', label: 'Radio', description: 'Single choice from radio buttons', group: 'Choice' },
  { value: 'checkbox', label: 'Checkbox', description: 'Yes/No choice', group: 'Choice' },
  
  // Special Fields
  { value: 'user', label: 'User', description: 'User selector', group: 'Special' },
  { value: 'multiuser', label: 'Multi-User', description: 'Multiple user selector', group: 'Special' },
  { value: 'formula', label: 'Formula', description: 'Calculated field', group: 'Special' },
  { value: 'location', label: 'Location', description: 'Geographic location', group: 'Special' },
  
  // URL Fields
  { value: 'url', label: 'URL', description: 'Web address input', group: 'URL' },
  { value: 'email', label: 'Email', description: 'Email address input', group: 'URL' },
  { value: 'phone', label: 'Phone', description: 'Phone number input', group: 'URL' },
  
  // File Fields
  { 
    value: 'file', 
    label: 'File Upload', 
    description: 'Single or multiple file upload', 
    group: 'Files',
    config: {
      validation: {
        multiple: false,
        maxSize: 10, // Default 10MB
        accept: '*/*'
      }
    }
  },
  { 
    value: 'image', 
    label: 'Image Upload', 
    description: 'Image file upload', 
    group: 'Files',
    config: {
      validation: {
        multiple: false,
        maxSize: 5, // Default 5MB
        accept: 'image/*'
      }
    }
  }
] as const;

interface Props {
  initialData?: any;
  onSubmit: (data: CustomFieldFormData) => Promise<void>;
  onCancel: () => void;
  isSubmitting?: boolean;
}

export function CustomFieldForm({
  initialData,
  onSubmit,
  onCancel,
  isSubmitting = false
}: Props) {
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors }
  } = useForm<CustomFieldFormData>({
    resolver: zodResolver(CustomFieldFormSchema),
    defaultValues: {
      name: initialData?.name || '',
      description: initialData?.description || '',
      type: initialData?.type || 'text',
      required: initialData?.required || false,
      config: initialData?.config || {}
    }
  });

  const selectedType = watch('type');

  // Convert field types into flat options for CustomSelect
  const selectOptions = FIELD_TYPES.map(field => ({
    value: field.value,
    label: field.label,
    description: field.description
  }));

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
      {/* Name */}
      <div>
        <label htmlFor="name" className="block text-sm font-medium text-gray-700">
          Field Name
          <span className="text-red-500 ml-1">*</span>
        </label>
        <input
          {...register('name')}
          type="text"
          className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm py-2 px-3 focus:outline-none focus:ring-brand-500 focus:border-brand-500 sm:text-sm"
          placeholder="Enter field name"
        />
        {errors.name?.message && (
          <p className="mt-1 text-sm text-red-600">{errors.name.message}</p>
        )}
      </div>

      {/* Description */}
      <div>
        <label htmlFor="description" className="block text-sm font-medium text-gray-700">
          Description
          <span className="text-red-500 ml-1">*</span>
        </label>
        <textarea
          {...register('description')}
          rows={3}
          className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm py-2 px-3 focus:outline-none focus:ring-brand-500 focus:border-brand-500 sm:text-sm"
          placeholder="Describe the purpose of this field"
        />
        {errors.description?.message && (
          <p className="mt-1 text-sm text-red-600">{errors.description.message}</p>
        )}
      </div>

      {/* Field Type */}
      <Controller
        name="type"
        control={control}
        render={({ field }) => (
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Field Type
              <span className="text-red-500 ml-1">*</span>
            </label>
            <CustomSelect
              value={field.value}
              onChange={field.onChange}
              options={selectOptions}
              error={errors.type?.message}
              required
              placeholder="Select field type"
              isSearchable
            />
          </div>
        )}
      />

      {/* Required Field */}
      <div className="flex items-center">
        <input
          {...register('required')}
          type="checkbox"
          className="h-4 w-4 rounded border-gray-300 text-brand-600 focus:ring-brand-500"
        />
        <label htmlFor="required" className="ml-2 block text-sm text-gray-900">
          Required field
        </label>
      </div>

      {/* Actions */}
      <div className="flex justify-end space-x-3 pt-4 mt-6 border-t border-gray-200">
        <button
          type="button"
          onClick={onCancel}
          disabled={isSubmitting}
          className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
        >
          Cancel
        </button>
        <button
          type="submit"
          disabled={isSubmitting}
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-brand-600 hover:bg-brand-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500 disabled:opacity-50"
        >
          {isSubmitting ? (
            <>
              <Loader2 className="w-4 h-4 mr-2 animate-spin" />
              Saving...
            </>
          ) : (
            'Save Field'
          )}
        </button>
      </div>
    </form>
  );
}