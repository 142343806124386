import { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { Mail, Save, AlertCircle, CheckCircle2 } from 'lucide-react';

interface EmailSettings {
  provider: 'smtp' | 'postmark';
  smtp?: {
    host: string;
    port: number;
    secure: boolean;
    user: string;
    pass: string;
    from: string;
    fromName: string;
  };
  postmark?: {
    apiKey: string;
    from: string;
    fromName: string;
  };
}

interface EmailSettingsProps {
  organization: any;
  onUpdate: () => void;
}

export default function EmailSettings({ organization, onUpdate }: EmailSettingsProps) {
  const { getAccessTokenSilently } = useAuth0();
  const [settings, setSettings] = useState<EmailSettings>({
    provider: 'smtp'
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [isTesting, setIsTesting] = useState(false);
  const [error, setError] = useState('');
  const [testRecipient, setTestRecipient] = useState('');
  const [testResult, setTestResult] = useState<{ success: boolean; message: string } | null>(null);

  useEffect(() => {
    fetchSettings();
  }, [organization.id]);

  const fetchSettings = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await axios.get(
        `${import.meta.env.VITE_API_URL}/api/organizations/${organization.id}/email-settings`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setSettings(response.data);
      setError('');
    } catch (error: any) {
      setError(error.response?.data?.message || 'Failed to fetch email settings');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSave = async () => {
    setIsSaving(true);
    setError('');
    setTestResult(null);

    try {
      const token = await getAccessTokenSilently();
      await axios.put(
        `${import.meta.env.VITE_API_URL}/api/organizations/${organization.id}/email-settings`,
        settings,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );
      setTestResult({
        success: true,
        message: 'Settings saved successfully'
      });
      onUpdate();
    } catch (error: any) {
      setError(error.response?.data?.message || 'Failed to save settings');
    } finally {
      setIsSaving(false);
    }
  };

  const handleTest = async () => {
    if (!testRecipient || !testRecipient.includes('@')) {
      setError('Please enter a valid email address for testing');
      return;
    }

    setIsTesting(true);
    setError('');
    setTestResult(null);

    try {
      const token = await getAccessTokenSilently();
      await axios.post(
        `${import.meta.env.VITE_API_URL}/api/organizations/${organization.id}/email-settings/test`,
        {
          ...settings,
          testRecipient
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );
      setTestResult({
        success: true,
        message: 'Test email sent successfully!'
      });
    } catch (error: any) {
      setTestResult({
        success: false,
        message: error.response?.data?.message || 'Failed to send test email'
      });
    } finally {
      setIsTesting(false);
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-brand-600"></div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="bg-white shadow-sm rounded-lg border border-gray-200">
        <div className="px-4 py-5 sm:p-6">
          <div className="flex justify-between items-center mb-6">
            <h3 className="text-lg font-medium text-gray-900">Email Provider Settings</h3>
            <div className="flex items-center space-x-3">
              <div className="relative">
                <input
                  type="email"
                  value={testRecipient}
                  onChange={(e) => setTestRecipient(e.target.value)}
                  placeholder="Enter test email address"
                  className="block w-64 border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-brand-500 focus:border-brand-500 sm:text-sm"
                />
              </div>
              <button
                onClick={handleTest}
                disabled={isTesting || !testRecipient}
                className={`inline-flex items-center px-4 py-2 border rounded-md shadow-sm text-sm font-medium ${
                  isTesting || !testRecipient
                    ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                    : 'border-brand-500 text-brand-600 bg-white hover:bg-brand-50'
                }`}
              >
                {isTesting ? 'Sending...' : 'Test Settings'}
              </button>
              <button
                onClick={handleSave}
                disabled={isSaving}
                className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-brand-600 hover:bg-brand-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500 disabled:opacity-50"
              >
                <Save className="w-4 h-4 mr-2" />
                {isSaving ? 'Saving...' : 'Save Settings'}
              </button>
            </div>
          </div>

          {/* Provider Selection */}
          <div className="mb-6">
            <label className="text-sm font-medium text-gray-700">Email Provider</label>
            <div className="mt-2 space-x-4">
              <button
                onClick={() => setSettings({ ...settings, provider: 'smtp' })}
                className={`inline-flex items-center px-4 py-2 rounded-md ${
                  settings.provider === 'smtp'
                    ? 'bg-brand-50 text-brand-700 ring-2 ring-brand-500'
                    : 'bg-white text-gray-700 hover:bg-gray-50'
                }`}
              >
                <Mail className="w-4 h-4 mr-2" />
                SMTP
              </button>
              <button
                onClick={() => setSettings({ ...settings, provider: 'postmark' })}
                className={`inline-flex items-center px-4 py-2 rounded-md ${
                  settings.provider === 'postmark'
                    ? 'bg-brand-50 text-brand-700 ring-2 ring-brand-500'
                    : 'bg-white text-gray-700 hover:bg-gray-50'
                }`}
              >
                <Mail className="w-4 h-4 mr-2" />
                Postmark
              </button>
            </div>
          </div>

          {/* Provider-specific settings */}
          {settings.provider === 'smtp' ? (
            <div className="grid grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700">SMTP Host</label>
                <input
                  type="text"
                  value={settings.smtp?.host || ''}
                  onChange={(e) =>
                    setSettings({
                      ...settings,
                      smtp: { ...settings.smtp, host: e.target.value } as any
                    })
                  }
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-brand-500 focus:border-brand-500 sm:text-sm"
                  placeholder="smtp.example.com"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">SMTP Port</label>
                <input
                  type="number"
                  value={settings.smtp?.port || ''}
                  onChange={(e) =>
                    setSettings({
                      ...settings,
                      smtp: { ...settings.smtp, port: parseInt(e.target.value) } as any
                    })
                  }
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-brand-500 focus:border-brand-500 sm:text-sm"
                  placeholder="587"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">SMTP User</label>
                <input
                  type="text"
                  value={settings.smtp?.user || ''}
                  onChange={(e) =>
                    setSettings({
                      ...settings,
                      smtp: { ...settings.smtp, user: e.target.value } as any
                    })
                  }
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-brand-500 focus:border-brand-500 sm:text-sm"
                  placeholder="user@example.com"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">SMTP Password</label>
                <input
                  type="password"
                  value={settings.smtp?.pass || ''}
                  onChange={(e) =>
                    setSettings({
                      ...settings,
                      smtp: { ...settings.smtp, pass: e.target.value } as any
                    })
                  }
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-brand-500 focus:border-brand-500 sm:text-sm"
                  placeholder="••••••••"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">From Email</label>
                <input
                  type="email"
                  value={settings.smtp?.from || ''}
                  onChange={(e) =>
                    setSettings({
                      ...settings,
                      smtp: { ...settings.smtp, from: e.target.value } as any
                    })
                  }
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-brand-500 focus:border-brand-500 sm:text-sm"
                  placeholder="noreply@example.com"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">From Name</label>
                <input
                  type="text"
                  value={settings.smtp?.fromName || ''}
                  onChange={(e) =>
                    setSettings({
                      ...settings,
                      smtp: { ...settings.smtp, fromName: e.target.value } as any
                    })
                  }
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-brand-500 focus:border-brand-500 sm:text-sm"
                  placeholder="Your Organization"
                />
              </div>
              <div className="col-span-2">
                <label className="inline-flex items-center">
                  <input
                    type="checkbox"
                    checked={settings.smtp?.secure || false}
                    onChange={(e) =>
                      setSettings({
                        ...settings,
                        smtp: { ...settings.smtp, secure: e.target.checked } as any
                      })
                    }
                    className="rounded border-gray-300 text-brand-600 shadow-sm focus:border-brand-500 focus:ring focus:ring-brand-500 focus:ring-opacity-50"
                  />
                  <span className="ml-2 text-sm text-gray-600">Use SSL/TLS</span>
                </label>
              </div>
            </div>
          ) : (
            <div className="space-y-6">
              <div>
                <label className="block text-sm font-medium text-gray-700">API Key</label>
                <input
                  type="password"
                  value={settings.postmark?.apiKey || ''}
                  onChange={(e) =>
                    setSettings({
                      ...settings,
                      postmark: { ...settings.postmark, apiKey: e.target.value } as any
                    })
                  }
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-brand-500 focus:border-brand-500 sm:text-sm"
                  placeholder="Enter your Postmark API key"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">From Email</label>
                <input
                  type="email"
                  value={settings.postmark?.from || ''}
                  onChange={(e) =>
                    setSettings({
                      ...settings,
                      postmark: { ...settings.postmark, from: e.target.value } as any
                    })
                  }
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-brand-500 focus:border-brand-500 sm:text-sm"
                  placeholder="noreply@example.com"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">From Name</label>
                <input
                  type="text"
                  value={settings.postmark?.fromName || ''}
                  onChange={(e) =>
                    setSettings({
                      ...settings,
                      postmark: { ...settings.postmark, fromName: e.target.value } as any
                    })
                  }
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-brand-500 focus:border-brand-500 sm:text-sm"
                  placeholder="Your Organization"
                />
              </div>
            </div>
          )}

          {/* Error and Success Messages */}
          {error && (
            <div className="mt-4 rounded-md bg-red-50 p-4">
              <div className="flex">
                <AlertCircle className="h-5 w-5 text-red-400" />
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-red-800">{error}</h3>
                </div>
              </div>
            </div>
          )}

          {testResult && (
            <div className={`mt-4 rounded-md p-4 ${
              testResult.success ? 'bg-green-50' : 'bg-red-50'
            }`}>
              <div className="flex">
                {testResult.success ? (
                  <CheckCircle2 className="h-5 w-5 text-green-400" />
                ) : (
                  <AlertCircle className="h-5 w-5 text-red-400" />
                )}
                <div className="ml-3">
                  <h3 className={`text-sm font-medium ${
                    testResult.success ? 'text-green-800' : 'text-red-800'
                  }`}>
                    {testResult.message}
                  </h3>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}