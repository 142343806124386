export type TaskVisibility = 'private' | 'public';

export const TASK_VISIBILITY: Record<TaskVisibility, {
  label: string;
  description: string;
  icon: string;
}> = {
  private: {
    label: 'Private',
    description: 'Only visible to creator and assigned users',
    icon: 'lock'
  },
  public: {
    label: 'Public',
    description: 'Visible to all organization members',
    icon: 'globe'
  }
};