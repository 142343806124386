import { useState, useEffect } from 'react';

export function useTabState(key: string, defaultTab: string) {
  const [activeTab, setActiveTab] = useState(() => {
    const saved = localStorage.getItem(`tab_${key}`);
    return saved || defaultTab;
  });

  useEffect(() => {
    localStorage.setItem(`tab_${key}`, activeTab);
  }, [activeTab, key]);

  return [activeTab, setActiveTab] as const;
}