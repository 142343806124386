import { 
    eachDayOfInterval, 
    addDays, 
    addWeeks,
    addMonths,
    isWithinInterval,
    startOfDay,
    endOfDay
  } from 'date-fns';
  import type { Task } from '../../../../../types/task';
  import type { TimelineViewMode, TimelineData } from '../types';
  
  export function getTimelineData(
    tasks: Task[], 
    currentDate: Date,
    viewMode: TimelineViewMode
  ): TimelineData {
    // Calculate date range based on view mode
    const endDate = (() => {
      switch (viewMode) {
        case 'week':
          return addDays(currentDate, 6);
        case 'month':
          return addDays(addMonths(currentDate, 1), -1);
        case 'quarter':
          return addDays(addMonths(currentDate, 3), -1);
        default:
          return addWeeks(currentDate, 1);
      }
    })();
  
    // Generate array of dates for the range
    const dates = eachDayOfInterval({
      start: currentDate,
      end: endDate
    });
  
    // Filter and map tasks
    const tasksWithDates = tasks
      .filter(task => task.dueDate)
      .map(task => {
        const taskDate = new Date(task.dueDate!);
        const taskDates = dates.filter(date =>
          isWithinInterval(date, {
            start: startOfDay(taskDate),
            end: endOfDay(taskDate)
          })
        );
  
        return {
          id: task.id,
          title: task.title,
          dates: taskDates
        };
      })
      .filter(task => task.dates.length > 0);
  
    return {
      dates,
      tasks: tasksWithDates
    };
  }