import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import CompleteProfile from '../components/CompleteProfile';
import { Loader } from 'lucide-react';

export default function OnboardingFlow() {
  const { isLoading, user } = useAuth0();

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <Loader className="w-8 h-8 animate-spin text-brand-600" />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <CompleteProfile />
    </div>
  );
}