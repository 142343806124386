import { useMemo } from 'react';
import { useTaskList } from '../../../../hooks/task';
import { DashboardWidget } from '../../DashboardWidget';
import { CompletionTrend } from './CompletionTrend';
import { VelocityMetric } from './VelocityMetric';
import { BottleneckAnalysis } from './BottleneckAnalysis';
import { useAnalyticsData } from './hooks/useAnalyticsData';
import { PermissionGate } from '../../../permissions';
import { USER_PERMISSIONS } from '../../../../types/permission';

export function AnalyticsWidget() {
  const { tasks } = useTaskList();
  const { 
    completionTrend,
    velocityData,
    bottlenecks
  } = useAnalyticsData(tasks);

  return (
    <PermissionGate permission={USER_PERMISSIONS.MANAGE_USERS}>
      <DashboardWidget title="Analytics">
        <div className="space-y-6">
          <CompletionTrend data={completionTrend} />
          <VelocityMetric data={velocityData} />
          <BottleneckAnalysis data={bottlenecks} />
        </div>
      </DashboardWidget>
    </PermissionGate>
  );
}