import { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { Mail, X, AlertCircle, Loader2 } from 'lucide-react';
import { format } from 'date-fns';
import { AlertDialog } from '../common/AlertDialog';

interface PendingInvitation {
  id: number;
  email: string;
  role: string;
  token: string;
  status: 'pending' | 'accepted' | 'expired';
  expiresAt: string;
  inviter: {
    email: string;
    firstName: string | null;
    lastName: string | null;
  };
  createdAt: string;
}

interface PendingInvitationsProps {
  organization: any;
  onUpdate: () => void;
}

export default function PendingInvitations({ organization, onUpdate }: PendingInvitationsProps) {
  const { getAccessTokenSilently } = useAuth0();
  const [invitations, setInvitations] = useState<PendingInvitation[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [revokeAlert, setRevokeAlert] = useState<{ isOpen: boolean; token: string | null }>({
    isOpen: false,
    token: null
  });

  useEffect(() => {
    fetchInvitations();
  }, [organization.id]);

  const fetchInvitations = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await axios.get(
        `${import.meta.env.VITE_API_URL}/api/invitations/pending`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setInvitations(response.data);
      setError('');
    } catch (error: any) {
      setError(error.response?.data?.message || 'Failed to fetch invitations');
    } finally {
      setIsLoading(false);
    }
  };

  const handleRevoke = async (token: string) => {
    try {
      const authToken = await getAccessTokenSilently();
      await axios.post(
        `${import.meta.env.VITE_API_URL}/api/invitations/revoke/${token}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${authToken}`
          }
        }
      );
      await fetchInvitations();
      onUpdate();
    } catch (error: any) {
      setError(error.response?.data?.message || 'Failed to revoke invitation');
    } finally {
      setRevokeAlert({ isOpen: false, token: null });
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <Loader2 className="w-8 h-8 animate-spin text-brand-600" />
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="bg-white shadow-sm rounded-lg border border-gray-200">
        <div className="px-4 py-5 sm:p-6">
          <h3 className="text-lg font-medium text-gray-900 mb-4">
            Pending Invitations
          </h3>

          {error && (
            <div className="mb-4 rounded-md bg-red-50 p-4">
              <div className="flex">
                <AlertCircle className="h-5 w-5 text-red-400" />
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-red-800">{error}</h3>
                </div>
              </div>
            </div>
          )}

          {invitations.length > 0 ? (
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead>
                  <tr>
                    <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Email
                    </th>
                    <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Role
                    </th>
                    <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Invited By
                    </th>
                    <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Expires
                    </th>
                    <th className="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {invitations.map((invitation) => (
                    <tr key={invitation.token}>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <Mail className="h-5 w-5 text-gray-400 mr-2" />
                          <span className="text-sm text-gray-900">
                            {invitation.email}
                          </span>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span className="text-sm text-gray-900 capitalize">
                          {invitation.role}
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span className="text-sm text-gray-900">
                          {invitation.inviter.firstName && invitation.inviter.lastName
                            ? `${invitation.inviter.firstName} ${invitation.inviter.lastName}`
                            : invitation.inviter.email}
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span className="text-sm text-gray-900">
                          {format(new Date(invitation.expiresAt), 'PPp')}
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-right">
                        <button
                          onClick={() => setRevokeAlert({ 
                            isOpen: true, 
                            token: invitation.token 
                          })}
                          className="text-red-600 hover:text-red-900"
                        >
                          <X className="h-5 w-5" />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <p className="text-sm text-gray-500 text-center py-4">
              No pending invitations
            </p>
          )}
        </div>
      </div>

      <AlertDialog
        isOpen={revokeAlert.isOpen}
        onClose={() => setRevokeAlert({ isOpen: false, token: null })}
        onConfirm={() => revokeAlert.token && handleRevoke(revokeAlert.token)}
        title="Revoke Invitation"
        description="Are you sure you want to revoke this invitation? This action cannot be undone."
        confirmLabel="Revoke"
        cancelLabel="Cancel"
        variant="danger"
      />
    </div>
  );
}