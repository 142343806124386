// Z-index values for consistent layering across components
export const Z_INDEX = {
  // Base layers
  BASE: 0,
  CONTENT: 10,
  
  // Navigation & Header
  HEADER: 20,
  NAV: 25,
  
  // Search & Controls
  SEARCH: 30,
  CONTROLS: 35,
  
  // Dropdowns & Filters
  DROPDOWN_TRIGGER: 40,
  DROPDOWN_OVERLAY: 41,
  DROPDOWN_CONTENT: 42,
  
  // Modals & Dialogs
  MODAL_OVERLAY: 50,
  MODAL_CONTENT: 51,
  
  // Tooltips & Popovers
  TOOLTIP: 60,
  
  // Notifications & Alerts
  NOTIFICATION: 70
} as const;