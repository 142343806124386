import { useState } from 'react';
import { BarChart, Filter, Download } from 'lucide-react';
import { DashboardWidget } from '../../DashboardWidget';
import { ReportFilters } from './ReportFilters';
import { ReportChart } from './ReportChart';
import { useCustomReports } from './hooks/useCustomReports';
import { PermissionGate } from '../../../permissions';
import { USER_PERMISSIONS } from '../../../../types/permission';
import type { ReportFilters as ReportFiltersType } from './types';

export function CustomReportsWidget() {
  const [filters, setFilters] = useState<ReportFiltersType>({
    dateRange: 'last30days',
    groupBy: 'status',
    metrics: ['count']
  });

  const { data, isLoading, exportReport } = useCustomReports(filters);

  return (
    <PermissionGate permission={USER_PERMISSIONS.MANAGE_USERS}>
      <DashboardWidget title="Custom Reports">
        <div className="space-y-6">
          <div className="flex items-center justify-between">
            <ReportFilters
              filters={filters}
              onChange={setFilters}
            />
            <button
              onClick={exportReport}
              className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
            >
              <Download className="w-4 h-4 mr-2" />
              Export
            </button>
          </div>

          <ReportChart
            data={data}
            isLoading={isLoading}
            groupBy={filters.groupBy}
          />
        </div>
      </DashboardWidget>
    </PermissionGate>
  );
}