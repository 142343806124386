import { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import type { Feature, FeatureSettings } from '../types/feature';

export function useFeatureSettings(organizationId: number) {
  const { getAccessTokenSilently } = useAuth0();
  const [features, setFeatures] = useState<Feature[]>([]);
  const [settings, setSettings] = useState<Record<string, FeatureSettings>>({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchFeatureSettings = async () => {
      try {
        const token = await getAccessTokenSilently();
        const [featuresResponse, settingsResponse] = await Promise.all([
          axios.get(`${import.meta.env.VITE_API_URL}/api/features`, {
            headers: { Authorization: `Bearer ${token}` }
          }),
          axios.get(`${import.meta.env.VITE_API_URL}/api/organizations/${organizationId}/features`, {
            headers: { Authorization: `Bearer ${token}` }
          })
        ]);

        setFeatures(featuresResponse.data);
        setSettings(settingsResponse.data);
      } catch (error: any) {
        setError(error.response?.data?.message || 'Failed to fetch feature settings');
      } finally {
        setIsLoading(false);
      }
    };

    fetchFeatureSettings();
  }, [organizationId, getAccessTokenSilently]);

  const updateFeatureSettings = async (featureId: string, newSettings: FeatureSettings) => {
    try {
      const token = await getAccessTokenSilently();
      const response = await axios.put(
        `${import.meta.env.VITE_API_URL}/api/organizations/${organizationId}/features/${featureId}`,
        newSettings,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );

      setSettings(prev => ({
        ...prev,
        [featureId]: response.data
      }));
    } catch (error: any) {
      throw new Error(error.response?.data?.message || 'Failed to update feature settings');
    }
  };

  const overrideFeature = async (featureId: string, enabled: boolean, limits?: Record<string, number>) => {
    try {
      const token = await getAccessTokenSilently();
      const response = await axios.post(
        `${import.meta.env.VITE_API_URL}/api/organizations/${organizationId}/features/${featureId}/override`,
        { enabled, limits },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );

      setSettings(prev => ({
        ...prev,
        [featureId]: response.data
      }));
    } catch (error: any) {
      throw new Error(error.response?.data?.message || 'Failed to override feature');
    }
  };

  return {
    features,
    settings,
    isLoading,
    error,
    updateFeatureSettings,
    overrideFeature
  };
}