import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { format } from 'date-fns';
import { Send, Loader2 } from 'lucide-react';
import { useTaskComments } from '../../../hooks/task/useTaskComments';
import { useToast } from '../../../context/ToastContext';
import { SubtaskCommentsProps } from './types';
import { cn } from '../../../lib/utils';

export function SubtaskComments({ taskId }: SubtaskCommentsProps) {
  const { user } = useAuth0();
  const { showToast } = useToast();
  const { comments, isLoading, error, addComment } = useTaskComments(taskId);
  const [newComment, setNewComment] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!newComment.trim()) return;

    setIsSubmitting(true);
    try {
      await addComment(newComment);
      setNewComment('');
      showToast('Comment added successfully', 'success');
    } catch (error) {
      console.error('Failed to add comment:', error);
      showToast('Failed to add comment', 'error');
    } finally {
      setIsSubmitting(false);
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center py-8">
        <Loader2 className="w-8 h-8 text-brand-600 animate-spin" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-sm text-red-600">
        Failed to load comments: {error}
      </div>
    );
  }

  return (
    <div className="space-y-4">
      {/* Comment List */}
      <div className="space-y-4">
        {comments.map((comment) => (
          <div key={comment.id} className="flex space-x-3">
            <div className="flex-shrink-0">
              <div className="h-8 w-8 rounded-full bg-brand-100 flex items-center justify-center">
                <span className="text-sm font-medium text-brand-600">
                  {comment.user.firstName?.[0] || comment.user.email[0]}
                </span>
              </div>
            </div>
            <div className="flex-1 min-w-0">
              <div className="text-sm">
                <span className="font-medium text-gray-900">
                  {comment.user.firstName && comment.user.lastName
                    ? `${comment.user.firstName} ${comment.user.lastName}`
                    : comment.user.email}
                </span>
                <span className="text-gray-500 ml-2">
                  {format(new Date(comment.createdAt), 'PPp')}
                </span>
              </div>
              <div className="mt-1 text-sm text-gray-700 whitespace-pre-wrap">
                {comment.content}
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* New Comment Form */}
      <form onSubmit={handleSubmit} className="mt-4">
        <div className="flex space-x-3">
          <div className="flex-shrink-0">
            <div className="h-8 w-8 rounded-full bg-brand-100 flex items-center justify-center">
              <span className="text-sm font-medium text-brand-600">
                {user?.email?.[0].toUpperCase()}
              </span>
            </div>
          </div>
          <div className="flex-1 min-w-0">
            <textarea
              value={newComment}
              onChange={(e) => setNewComment(e.target.value)}
              placeholder="Add a comment..."
              rows={3}
              className={cn(
                "block w-full rounded-md shadow-sm sm:text-sm",
                "border border-gray-300 focus:border-brand-500 focus:ring-brand-500",
                "placeholder:text-gray-400",
                "resize-none"
              )}
            />
            <div className="mt-3 flex justify-end">
              <button
                type="submit"
                disabled={isSubmitting || !newComment.trim()}
                className={cn(
                  "inline-flex items-center px-4 py-2 border border-transparent",
                  "text-sm font-medium rounded-md shadow-sm",
                  "text-white bg-brand-600 hover:bg-brand-700",
                  "focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500",
                  "disabled:opacity-50 transition-colors duration-200"
                )}
              >
                {isSubmitting ? (
                  <>
                    <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                    Sending...
                  </>
                ) : (
                  <>
                    <Send className="w-4 h-4 mr-2" />
                    Send
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}