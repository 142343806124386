import { type ReactNode } from 'react';
import { usePermissions } from '../../hooks/usePermissions';
import { type PermissionType, type PermissionOptions } from '../../types/permission';

export interface PermissionGateProps {
  permission: PermissionType | PermissionType[];
  options?: PermissionOptions;
  requireAll?: boolean;
  children: ReactNode;
  fallback?: ReactNode;
}

export function PermissionGate({ 
  permission, 
  options = {},
  requireAll = false,
  children, 
  fallback = null 
}: PermissionGateProps) {
  const { hasPermission, hasAllPermissions, hasAnyPermission } = usePermissions();

  console.log('[PermissionGate] Checking access:', {
    permission,
    options,
    requireAll
  });

  // Handle array of permissions
  const hasAccess = Array.isArray(permission)
    ? requireAll
      ? hasAllPermissions(permission, options)
      : hasAnyPermission(permission, options)
    : hasPermission(permission, options);

  console.log('[PermissionGate] Access result:', {
    permission,
    hasAccess
  });

  if (!hasAccess) return <>{fallback}</>;
  
  return <>{children}</>;
}