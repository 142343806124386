import { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import { GripVertical, ChevronUp, ChevronDown } from 'lucide-react';
import Modal from '../common/Modal';
import { cn } from '../../lib/utils';
import { useToast } from '../../context/ToastContext';
import type { DashboardLayout } from '../../types/dashboard';

interface DashboardCustomizeModalProps {
  isOpen: boolean;
  onClose: () => void;
  layout: DashboardLayout;
  onUpdateLayout: (layout: DashboardLayout) => Promise<void>;
  onResetLayout: () => Promise<void>;
}

const WIDGET_LABELS: Record<string, string> = {
  taskOverview: 'Task Overview',
  myTasks: 'My Tasks',
  teamWorkload: 'Team Workload',
  timeline: 'Timeline',
  analytics: 'Analytics',
  recentActivity: 'Recent Activity',
  priorityTasks: 'Priority Tasks',
  customReports: 'Custom Reports'
};

export function DashboardCustomizeModal({
  isOpen,
  onClose,
  layout,
  onUpdateLayout,
  onResetLayout
}: DashboardCustomizeModalProps) {
  const { showToast } = useToast();
  const [localLayout, setLocalLayout] = useState<DashboardLayout>([]);
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    setLocalLayout(layout);
  }, [layout]);

  const handleDragStart = () => {
    setIsDragging(true);
  };

  const handleDragEnd = (result: DropResult) => {
    setIsDragging(false);
    
    if (!result.destination) return;

    const items = Array.from(localLayout);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    // Update positions
    const updatedItems = items.map((item, index) => ({
      ...item,
      position: index
    }));

    setLocalLayout(updatedItems);
  };

  const handleVisibilityToggle = (id: string) => {
    setLocalLayout(prev => 
      prev.map(widget => 
        widget.id === id 
          ? { ...widget, visible: !widget.visible }
          : widget
      )
    );
  };

  const handleMoveUp = (index: number) => {
    if (index === 0) return;
    const items = Array.from(localLayout);
    const temp = items[index];
    items[index] = items[index - 1];
    items[index - 1] = temp;

    // Update positions
    const updatedItems = items.map((item, idx) => ({
      ...item,
      position: idx
    }));

    setLocalLayout(updatedItems);
  };

  const handleMoveDown = (index: number) => {
    if (index === localLayout.length - 1) return;
    const items = Array.from(localLayout);
    const temp = items[index];
    items[index] = items[index + 1];
    items[index + 1] = temp;

    // Update positions
    const updatedItems = items.map((item, idx) => ({
      ...item,
      position: idx
    }));

    setLocalLayout(updatedItems);
  };

  const handleSave = async () => {
    try {
      await onUpdateLayout(localLayout);
      showToast('Dashboard layout saved successfully', 'success');
      onClose();
    } catch (error) {
      console.error('Failed to save layout:', error);
      showToast('Failed to save dashboard layout', 'error');
    }
  };

  const handleReset = async () => {
    try {
      await onResetLayout();
      showToast('Dashboard layout reset successfully', 'success');
      onClose();
    } catch (error) {
      console.error('Failed to reset layout:', error);
      showToast('Failed to reset dashboard layout', 'error');
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Customize Dashboard"
    >
      <div className="space-y-6">
        <DragDropContext 
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
        >
          <Droppable droppableId="widgets">
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className="space-y-2"
              >
                {localLayout.map((widget, index) => (
                  <Draggable
                    key={widget.id}
                    draggableId={widget.id}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        className={cn(
                          'flex items-center justify-between p-3 bg-white rounded-lg border transition-shadow duration-200',
                          snapshot.isDragging ? 'shadow-lg border-brand-500' : 'border-gray-200',
                          !widget.visible && 'opacity-50'
                        )}
                      >
                        <div className="flex items-center space-x-3">
                          <div 
                            {...provided.dragHandleProps}
                            className="cursor-grab active:cursor-grabbing"
                          >
                            <GripVertical className="w-5 h-5 text-gray-400" />
                          </div>
                          <span className="text-sm font-medium text-gray-900">
                            {WIDGET_LABELS[widget.type] || widget.type}
                          </span>
                        </div>
                        <div className="flex items-center space-x-2">
                          <div className="flex space-x-1">
                            <button
                              onClick={() => handleMoveUp(index)}
                              disabled={index === 0}
                              className={cn(
                                "p-1 rounded hover:bg-gray-100",
                                index === 0 && "opacity-50 cursor-not-allowed"
                              )}
                              title="Move up"
                            >
                              <ChevronUp className="w-4 h-4 text-gray-500" />
                            </button>
                            <button
                              onClick={() => handleMoveDown(index)}
                              disabled={index === localLayout.length - 1}
                              className={cn(
                                "p-1 rounded hover:bg-gray-100",
                                index === localLayout.length - 1 && "opacity-50 cursor-not-allowed"
                              )}
                              title="Move down"
                            >
                              <ChevronDown className="w-4 h-4 text-gray-500" />
                            </button>
                          </div>
                          <button
                            onClick={() => handleVisibilityToggle(widget.id)}
                            className={cn(
                              'px-3 py-1 text-sm font-medium rounded-full transition-colors',
                              widget.visible
                                ? 'bg-brand-50 text-brand-700 hover:bg-brand-100'
                                : 'bg-gray-50 text-gray-700 hover:bg-gray-100'
                            )}
                          >
                            {widget.visible ? 'Visible' : 'Hidden'}
                          </button>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <div className="flex justify-between pt-4 border-t border-gray-200">
          <button
            onClick={handleReset}
            className="text-sm text-red-600 hover:text-red-700"
          >
            Reset to Default
          </button>
          <div className="space-x-3">
            <button
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
            >
              Cancel
            </button>
            <button
              onClick={handleSave}
              className="px-4 py-2 text-sm font-medium text-white bg-brand-600 rounded-md hover:bg-brand-700"
            >
              Save Changes
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}