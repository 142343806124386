import React from 'react';
import { Search, X } from 'lucide-react';
import { cn } from '../../../../lib/utils';
import { TaskSearchProps } from './types';

export function TaskSearch({
  value,
  onChange,
  className
}: TaskSearchProps) {
  const handleClear = () => {
    onChange('');
  };

  return (
    <div className={cn('relative flex-1 max-w-lg', className)}>
      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none z-[31]">
        <Search className="h-5 w-5 text-gray-400" />
      </div>
      <input
        type="text"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className="block w-full pl-10 pr-10 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-brand-500 focus:border-brand-500 sm:text-sm z-[30]"
        placeholder="Search tasks by title, description, or assignee..."
      />
      {value && (
        <button
          onClick={handleClear}
          className="absolute inset-y-0 right-0 pr-3 flex items-center z-[32]"
        >
          <X className="h-5 w-5 text-gray-400 hover:text-gray-500" />
        </button>
      )}
    </div>
  );
}