import { AlertTriangle, AlertCircle, ArrowUp } from 'lucide-react';
import { cn } from '../../../../lib/utils';
import { TaskCard } from '../../../task/TaskCard';
import type { Task } from '../../../../types/task';

interface PriorityTaskListProps {
  title: string;
  tasks: Task[];
  type: 'high-priority' | 'blocked' | 'overdue';
}

export function PriorityTaskList({ title, tasks, type }: PriorityTaskListProps) {
  const getIcon = () => {
    switch (type) {
      case 'high-priority':
        return <ArrowUp className="w-5 h-5 text-red-500" />;
      case 'blocked':
        return <AlertCircle className="w-5 h-5 text-yellow-500" />;
      case 'overdue':
        return <AlertTriangle className="w-5 h-5 text-orange-500" />;
    }
  };

  const getHeaderColor = () => {
    switch (type) {
      case 'high-priority':
        return 'text-red-700 bg-red-50';
      case 'blocked':
        return 'text-yellow-700 bg-yellow-50';
      case 'overdue':
        return 'text-orange-700 bg-orange-50';
    }
  };

  return (
    <div>
      <div className={cn(
        'flex items-center space-x-2 px-3 py-2 rounded-md mb-3',
        getHeaderColor()
      )}>
        {getIcon()}
        <h4 className="text-sm font-medium">
          {title} ({tasks.length})
        </h4>
      </div>

      <div className="space-y-2">
        {tasks.map((task) => (
          <TaskCard
            key={task.id}
            task={task}
            isCompact
          />
        ))}
      </div>
    </div>
  );
}