import React, { useState } from 'react';
import { Plus } from 'lucide-react';
import RolePermissionBadge from './RolePermissionBadge';

interface RolePermissionEditorProps {
  categoryName: string;
  permissions: string[];
  selectedPermissions: string[];
  onPermissionToggle: (permission: string) => void;
}

export default function RolePermissionEditor({
  categoryName,
  permissions,
  selectedPermissions,
  onPermissionToggle
}: RolePermissionEditorProps) {
  const [isAdding, setIsAdding] = useState(false);

  const availablePermissions = permissions.filter(
    p => !selectedPermissions.includes(p)
  );

  return (
    <div className="space-y-2">
      <div className="flex justify-between items-center">
        <h4 className="text-xs font-medium text-gray-500 uppercase tracking-wider">
          {categoryName} Permissions
        </h4>
        {availablePermissions.length > 0 && (
          <button
            onClick={() => setIsAdding(!isAdding)}
            className="inline-flex items-center px-2 py-1 text-xs font-medium text-brand-600 hover:text-brand-700"
          >
            <Plus className="h-3 w-3 mr-1" />
            Add Permission
          </button>
        )}
      </div>

      <div className="flex flex-wrap gap-2">
        {selectedPermissions.map(permission => (
          <RolePermissionBadge
            key={permission}
            permission={permission}
            isEditable={true}
            onRemove={() => onPermissionToggle(permission)}
          />
        ))}
      </div>

      {isAdding && availablePermissions.length > 0 && (
        <div className="mt-2 p-2 bg-gray-50 rounded-md">
          <div className="text-xs font-medium text-gray-500 mb-2">
            Available Permissions
          </div>
          <div className="flex flex-wrap gap-2">
            {availablePermissions.map(permission => (
              <button
                key={permission}
                onClick={() => {
                  onPermissionToggle(permission);
                  setIsAdding(false);
                }}
                className="inline-flex items-center px-2 py-1 rounded-md text-xs font-medium text-gray-700 bg-white border border-gray-300 hover:bg-gray-50"
              >
                {permission}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}