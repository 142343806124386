import React from 'react';
import { Globe, Lock } from 'lucide-react';
import { CustomSelect } from '../../common/CustomSelect';
import { TaskVisibility, TASK_VISIBILITY } from '../../../types/task';

interface TaskVisibilitySelectProps {
  value: TaskVisibility | null;
  onChange: (value: TaskVisibility | null) => void;
  error?: string;
  isDisabled?: boolean;
  required?: boolean;
}

const visibilityIcons = {
  private: Lock,
  public: Globe
};

export function TaskVisibilitySelect({
  value,
  onChange,
  error,
  isDisabled,
  required
}: TaskVisibilitySelectProps) {
  const options = Object.entries(TASK_VISIBILITY).map(([visibility, config]) => ({
    value: visibility,
    label: config.label,
    description: config.description,
    icon: visibilityIcons[visibility as TaskVisibility]
  }));

  return (
    <CustomSelect
      options={options}
      value={value || ''}
      onChange={(newValue) => onChange(newValue as TaskVisibility)}
      placeholder="Select visibility"
      label="Visibility"
      error={error}
      isDisabled={isDisabled}
      required={required}
    />
  );
}