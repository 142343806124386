import { useState } from 'react';
import { AlertCircle, Loader2, Plus } from 'lucide-react';
import { cn } from '../../../lib/utils';
import { TaskSubtasksProps } from './types';
import { SubtaskCard } from '../SubtaskCard';
import { SubtaskForm } from '../SubtaskForm';
import { useTaskSubtasks } from '../../../hooks/task';
import { useSubtaskMutations } from '../../../hooks/task/useSubtaskMutations';
import Modal from '../../common/Modal/Modal';
import { AlertDialog } from '../../common/AlertDialog';
import { PermissionGate } from '../../permissions';
import { TASK_PERMISSIONS } from '../../../types/permission';
import { useToast } from '../../../context/ToastContext';
import { Task } from '../../../types/task';

export function TaskSubtasks({ taskId, readOnly = false }: TaskSubtasksProps) {
  const [isAddingSubtask, setIsAddingSubtask] = useState(false);
  const [editingSubtask, setEditingSubtask] = useState<Task | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState<{ isOpen: boolean; subtaskId: number | null }>({
    isOpen: false,
    subtaskId: null
  });
  const { showToast } = useToast();

  const {
    subtasks, 
    isLoading, 
    error,
    refreshSubtasks
  } = useTaskSubtasks(taskId);
  
  const {
    createSubtask,
    updateSubtask,
    deleteSubtask,
    duplicateSubtask
  } = useSubtaskMutations(taskId);

  const handleCreateSubtask = async (data: any) => {
    setIsSubmitting(true);
    try {
      await createSubtask(data);
      setIsAddingSubtask(false);
      await refreshSubtasks();
      showToast('Subtask created successfully', 'success');
    } catch (error) {
      console.error('Failed to create subtask:', error);
      showToast('Failed to create subtask', 'error');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleEditSubtask = async (data: any) => {
    if (!editingSubtask) return;
    
    setIsSubmitting(true);
    try {
      await updateSubtask(editingSubtask.id, data);
      setEditingSubtask(null);
      await refreshSubtasks();
      showToast('Subtask updated successfully', 'success');
    } catch (error) {
      console.error('Failed to update subtask:', error);
      showToast('Failed to update subtask', 'error');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDeleteSubtask = async (subtaskId: number) => {
    setDeleteConfirm({ isOpen: true, subtaskId });
  };

  const handleDuplicateSubtask = async (subtaskId: number) => {
    try {
      await duplicateSubtask(subtaskId);
      await refreshSubtasks();
      showToast('Subtask duplicated successfully', 'success');
    } catch (error) {
      console.error('Failed to duplicate subtask:', error);
      showToast('Failed to duplicate subtask', 'error');
    }
  };

  const confirmDeleteSubtask = async () => {
    if (!deleteConfirm.subtaskId) return;

    try {
      await deleteSubtask(deleteConfirm.subtaskId);
      await refreshSubtasks();
      showToast('Subtask deleted successfully', 'success');
    } catch (error) {
      console.error('Failed to delete subtask:', error);
      showToast('Failed to delete subtask', 'error');
    } finally {
      setDeleteConfirm({ isOpen: false, subtaskId: null });
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center py-8">
        <Loader2 className="w-8 h-8 text-brand-600 animate-spin" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="rounded-md bg-red-50 p-4">
        <div className="flex">
          <AlertCircle className="h-5 w-5 text-red-400" />
          <div className="ml-3">
            <h3 className="text-sm font-medium text-red-800">{error}</h3>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-4">
      {/* Add Subtask Button */}
      {!readOnly && (
        <PermissionGate permission={TASK_PERMISSIONS.CREATE}>
          {!isAddingSubtask && (
            <button
              onClick={() => setIsAddingSubtask(true)}
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-brand-600 hover:bg-brand-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
            >
              <Plus className="w-4 h-4 mr-2" />
              Add Subtask
            </button>
          )}
        </PermissionGate>
      )}

      {/* Create Subtask Modal */}
      <Modal
        isOpen={isAddingSubtask}
        onClose={() => setIsAddingSubtask(false)}
        title="Create Subtask"
      >
        <SubtaskForm
          onSubmit={handleCreateSubtask}
          onCancel={() => setIsAddingSubtask(false)}
          isSubmitting={isSubmitting}
        />
      </Modal>

      {/* Edit Subtask Modal */}
      <Modal
        isOpen={!!editingSubtask}
        onClose={() => setEditingSubtask(null)}
        title="Edit Subtask"
      >
        <SubtaskForm
          initialData={editingSubtask || undefined}
          onSubmit={handleEditSubtask}
          onCancel={() => setEditingSubtask(null)}
          isSubmitting={isSubmitting}
        />
      </Modal>

      {/* Delete Confirmation Dialog */}
      <AlertDialog
        isOpen={deleteConfirm.isOpen}
        onClose={() => setDeleteConfirm({ isOpen: false, subtaskId: null })}
        onConfirm={confirmDeleteSubtask}
        title="Delete Subtask"
        description="Are you sure you want to delete this subtask? This action cannot be undone."
        confirmLabel="Delete"
        cancelLabel="Cancel"
        variant="danger"
      />

      {/* Subtasks List */}
      {subtasks && subtasks.length > 0 ? (
        <div className="space-y-4">
          {subtasks.map((subtask) => (
            <SubtaskCard
              key={subtask.id}
              task={subtask}
              onClick={!readOnly ? () => setEditingSubtask(subtask) : undefined}
              onDelete={!readOnly ? () => handleDeleteSubtask(subtask.id) : undefined}
              onDuplicate={!readOnly ? () => handleDuplicateSubtask(subtask.id) : undefined}
            />
          ))}
        </div>
      ) : (
        <p className="text-sm text-gray-500 text-center py-4">
          No subtasks yet
        </p>
      )}
    </div>
  );
}