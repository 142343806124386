import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { CreditCard, Check, AlertCircle, Loader2 } from 'lucide-react';
import { useSubscription } from '../hooks/useSubscription';
import { PlanType, SUBSCRIPTION_PLANS } from '../types/subscription';
import { FeatureComparison } from '../components/subscription/FeatureComparison';
import { PlanCard } from '../components/subscription/PlanCard';
import { UsageStats } from '../components/subscription/UsageStats';
import { PaymentModal } from '../components/subscription/PaymentModal';
import { useToast } from '../context/ToastContext';

export function SubscriptionPage() {
  const { subscription, usage, isLoading, error, updatePlan } = useSubscription();
  const [selectedPlan, setSelectedPlan] = useState<PlanType | null>(null);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const { showToast } = useToast();

  const handlePlanSelect = (plan: PlanType) => {
    if (plan === PlanType.FREE) {
      handleDowngrade();
    } else {
      setSelectedPlan(plan);
      setIsPaymentModalOpen(true);
    }
  };

  const handleUpgrade = async (paymentMethodId: string) => {
    if (!selectedPlan) return;

    try {
      await updatePlan(selectedPlan, paymentMethodId);
      setIsPaymentModalOpen(false);
      showToast('Subscription updated successfully', 'success');
    } catch (error) {
      console.error('Failed to update subscription:', error);
      showToast('Failed to update subscription', 'error');
    }
  };

  const handleDowngrade = async () => {
    try {
      await updatePlan(PlanType.FREE);
      showToast('Subscription updated successfully', 'success');
    } catch (error) {
      console.error('Failed to downgrade subscription:', error);
      showToast('Failed to update subscription', 'error');
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center min-h-[400px]">
        <Loader2 className="w-8 h-8 animate-spin text-brand-600" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="bg-red-50 p-4 rounded-md">
          <div className="flex">
            <AlertCircle className="h-5 w-5 text-red-400" />
            <div className="ml-3">
              <h3 className="text-sm font-medium text-red-800">
                {error}
              </h3>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="mb-8">
        <h1 className="text-2xl font-bold text-gray-900">Subscription & Usage</h1>
        <p className="mt-1 text-sm text-gray-500">
          Manage your subscription plan and monitor feature usage
        </p>
      </div>

      {/* Current Plan Info */}
      <div className="bg-white shadow-sm rounded-lg border border-gray-200 p-6 mb-8">
        <div className="flex items-center justify-between">
          <div>
            <h2 className="text-lg font-medium text-gray-900">
              Current Plan: {SUBSCRIPTION_PLANS[subscription.plan as PlanType].name}
            </h2>
            <p className="mt-1 text-sm text-gray-500">
              {subscription.status === 'trialing' ? (
                <>Trial ends on {new Date(subscription.trialEndsAt!).toLocaleDateString()}</>
              ) : (
                <>Next billing date: {new Date(subscription.currentPeriodEnd!).toLocaleDateString()}</>
              )}
            </p>
          </div>
          {subscription.status === 'active' && (
            <CreditCard className="h-8 w-8 text-brand-600" />
          )}
        </div>
      </div>

      {/* Usage Stats */}
      <UsageStats 
        usage={usage} 
        plan={SUBSCRIPTION_PLANS[subscription.plan as PlanType]} 
      />

      {/* Plan Selection */}
      <div className="mt-12">
        <h2 className="text-lg font-medium text-gray-900 mb-6">Available Plans</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {Object.values(SUBSCRIPTION_PLANS).map((plan) => (
            <PlanCard
              key={plan.id}
              plan={plan}
              isCurrentPlan={subscription.plan === plan.id}
              onSelect={() => handlePlanSelect(plan.id)}
            />
          ))}
        </div>
      </div>

      {/* Feature Comparison */}
      <div className="mt-12">
        <h2 className="text-lg font-medium text-gray-900 mb-6">Feature Comparison</h2>
        <FeatureComparison currentPlan={subscription.plan as PlanType} />
      </div>

      {/* Payment Modal */}
      <PaymentModal
        isOpen={isPaymentModalOpen}
        onClose={() => setIsPaymentModalOpen(false)}
        onSubmit={handleUpgrade}
        plan={selectedPlan ? SUBSCRIPTION_PLANS[selectedPlan] : null}
      />
    </div>
  );
}