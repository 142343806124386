import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Shield, KeyRound, Users } from 'lucide-react';
import { Link } from 'react-router-dom';

export default function Home() {
  const { loginWithRedirect, isAuthenticated } = useAuth0();

  const features = [
    {
      icon: Shield,
      title: 'Secure by Default',
      description: 'Industry-standard security practices with OAuth 2.0 and OpenID Connect protocols.'
    },
    {
      icon: KeyRound,
      title: 'Multi-Factor Auth',
      description: 'Enhanced security with optional two-factor authentication support.'
    },
    {
      icon: Users,
      title: 'Social Logins',
      description: 'Seamless integration with popular social login providers.'
    }
  ];

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Hero Section */}
      <div className="relative overflow-hidden">
        <div className="absolute inset-0 -z-10">
          <div className="absolute left-[50%] top-0 h-[1000px] w-[1000px] -translate-x-1/2 rounded-full bg-gradient-to-tr from-brand-50 to-brand-100 opacity-30 blur-3xl" />
        </div>
        
        <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8">
          <div className="text-center">
            <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
              Secure Authentication for Your{' '}
              <span className="text-brand-600">SaaS Application</span>
            </h1>
            <p className="mt-6 text-lg leading-8 text-gray-600 max-w-2xl mx-auto">
              Enterprise-grade authentication system powered by Auth0. Get started with social logins, multi-factor authentication, and more.
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              {!isAuthenticated && (
                <button
                  onClick={() => loginWithRedirect()}
                  className="rounded-md bg-brand-600 px-6 py-3 text-base font-semibold text-white shadow-sm hover:bg-brand-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-600"
                >
                  Get Started
                </button>
              )}
              <Link
                to="/about"
                className="text-base font-semibold leading-6 text-gray-900 hover:text-brand-600"
              >
                Learn more <span aria-hidden="true">→</span>
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* Features Section */}
      <div className="mx-auto max-w-7xl px-6 lg:px-8 pb-24">
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
            {features.map((feature) => (
              <div 
                key={feature.title}
                className="bg-white rounded-2xl p-8 shadow-sm ring-1 ring-gray-200/50 hover:shadow-md transition-shadow"
              >
                <dt className="flex items-center gap-x-3">
                  <div className="flex h-12 w-12 items-center justify-center rounded-lg bg-brand-50">
                    <feature.icon className="h-6 w-6 text-brand-600" aria-hidden="true" />
                  </div>
                  <span className="text-lg font-semibold leading-7 text-gray-900">
                    {feature.title}
                  </span>
                </dt>
                <dd className="mt-4 text-base leading-7 text-gray-600">
                  {feature.description}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}