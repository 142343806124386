export interface PermissionContext {
  role: string;
  permissions: string[];
  isOrgAdmin: boolean;
  organizationId: number;
  userId: number;
}

// Task-related permissions
export const TASK_PERMISSIONS = {
  CREATE: 'create:task',
  READ_ALL: 'read:task:all',
  READ_ASSIGNED: 'read:task:assigned',
  UPDATE_ALL: 'update:task:all',
  UPDATE_ASSIGNED: 'update:task:assigned',
  DELETE_ALL: 'delete:task:all',
  DELETE_ASSIGNED: 'delete:task:assigned',
  MANAGE_SETTINGS: 'manage:task_settings'
} as const;

// Subtask-related permissions
export const SUBTASK_PERMISSIONS = {
  CREATE: 'create:subtask',
  READ_ALL: 'read:subtask:all',
  READ_ASSIGNED: 'read:subtask:assigned',
  UPDATE_ALL: 'update:subtask:all',
  UPDATE_ASSIGNED: 'update:subtask:assigned',
  DELETE_ALL: 'delete:subtask:all',
  DELETE_ASSIGNED: 'delete:subtask:assigned'
} as const;

// User management permissions
export const USER_PERMISSIONS = {
  MANAGE_USERS: 'manage:users',
  MANAGE_ROLES: 'manage:roles',
  INVITE_USERS: 'invite:users',
  READ_USERS: 'read:users'
} as const;

// Organization management permissions
export const ORGANIZATION_PERMISSIONS = {
  MANAGE_ORGANIZATION: 'manage:organization',
  MANAGE_SETTINGS: 'manage:settings',
  MANAGE_BILLING: 'manage:billing',
  READ_ORGANIZATION: 'read:organization'
} as const;

// Custom fields permissions
export const CUSTOM_FIELD_PERMISSIONS = {
  MANAGE: 'manage:custom_fields',
  READ: 'read:custom_fields'
} as const;

// Automation permissions
export const AUTOMATION_PERMISSIONS = {
  MANAGE: 'manage:automation',
  READ: 'read:automation',
  EXECUTE: 'execute:automation'
} as const;

// Report permissions
export const REPORT_PERMISSIONS = {
  VIEW: 'view:reports',
  EXPORT: 'export:reports',
  CREATE: 'create:reports'
} as const;

// Helper type for permission checking
export type PermissionType = 
  | typeof TASK_PERMISSIONS[keyof typeof TASK_PERMISSIONS]
  | typeof SUBTASK_PERMISSIONS[keyof typeof SUBTASK_PERMISSIONS]
  | typeof USER_PERMISSIONS[keyof typeof USER_PERMISSIONS]
  | typeof ORGANIZATION_PERMISSIONS[keyof typeof ORGANIZATION_PERMISSIONS]
  | typeof CUSTOM_FIELD_PERMISSIONS[keyof typeof CUSTOM_FIELD_PERMISSIONS]
  | typeof AUTOMATION_PERMISSIONS[keyof typeof AUTOMATION_PERMISSIONS]
  | typeof REPORT_PERMISSIONS[keyof typeof REPORT_PERMISSIONS];

// Permission check options
export interface PermissionOptions {
  requireOwnership?: boolean;
  allowOrgAdmin?: boolean;
  checkHierarchy?: boolean;
  resourceId?: number;
  ownerId?: number;
}