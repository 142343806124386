import { User, Shield, Bell, Users } from 'lucide-react';
import { USER_PERMISSIONS } from '../../../types/permission';
import type { NavigationCategory } from './types';

export const navigationConfig: NavigationCategory[] = [
  {
    category: 'Personal',
    icon: User,
    items: [
      { id: 'profile', name: 'Profile Settings' },
      { id: 'notifications', name: 'Notifications', icon: Bell }
    ]
  },
  {
    category: 'Access Control',
    icon: Shield,
    items: [
      { 
        id: 'roles', 
        name: 'Roles & Permissions',
        icon: Shield,
        requiresPermission: true,
        permission: USER_PERMISSIONS.MANAGE_ROLES
      },
      { 
        id: 'assignments', 
        name: 'User Role Assignments',
        icon: Users,
        requiresPermission: true,
        permission: USER_PERMISSIONS.MANAGE_USERS
      }
    ]
  }
];