import { DatePicker } from '../../../../common/DatePicker';

interface WorkloadDateRangeProps {
  startDate: Date | null;
  endDate: Date | null;
  onStartDateChange: (date: Date | null) => void;
  onEndDateChange: (date: Date | null) => void;
}

export function WorkloadDateRange({
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange
}: WorkloadDateRangeProps) {
  return (
    <div className="flex items-center space-x-4 p-4 bg-gray-50 rounded-lg">
      <DatePicker
        value={startDate}
        onChange={onStartDateChange}
        label="From"
        placeholder="Start date"
        className="w-40"
      />
      <DatePicker
        value={endDate}
        onChange={onEndDateChange}
        label="To"
        placeholder="End date"
        minDate={startDate || undefined}
        className="w-40"
      />
    </div>
  );
}