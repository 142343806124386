import { Settings2, ToggleLeft, ToggleRight, AlertCircle } from 'lucide-react';
import { cn } from '../../../lib/utils';
import type { Feature, FeatureSettings } from '../../../types/feature';

interface FeatureListProps {
  features: Feature[];
  settings: Record<string, FeatureSettings>;
  onConfigureFeature: (feature: Feature) => void;
  onOverrideFeature: (feature: Feature) => void;
}

export function FeatureList({
  features,
  settings,
  onConfigureFeature,
  onOverrideFeature
}: FeatureListProps) {
  const renderLimitInfo = (feature: Feature, featureSettings?: FeatureSettings) => {
    if (!feature.hasLimits) return null;

    const limit = featureSettings?.limits?.[feature.id] || feature.defaultLimit;
    const isOverridden = featureSettings?.limits?.[feature.id] !== undefined;

    return (
      <span className={cn(
        "text-sm",
        isOverridden ? "text-brand-600 font-medium" : "text-gray-500"
      )}>
        Limit: {limit} {feature.unit}
        {isOverridden && " (Custom)"}
      </span>
    );
  };

  return (
    <div className="bg-white shadow-sm rounded-lg border border-gray-200">
      <ul className="divide-y divide-gray-200">
        {features.map((feature) => {
          const featureSettings = settings[feature.id];
          const isEnabled = featureSettings?.enabled ?? feature.defaultEnabled;
          const isOverridden = featureSettings?.enabled !== undefined;

          return (
            <li key={feature.id} className="p-4 hover:bg-gray-50">
              <div className="flex items-center justify-between">
                <div className="flex-1 min-w-0">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center space-x-3">
                      <feature.icon className="w-5 h-5 text-gray-400" />
                      <div>
                        <h3 className="text-sm font-medium text-gray-900">
                          {feature.name}
                          {isOverridden && (
                            <span className="ml-2 text-xs text-brand-600">(Overridden)</span>
                          )}
                        </h3>
                        <p className="text-sm text-gray-500">{feature.description}</p>
                      </div>
                    </div>
                    {renderLimitInfo(feature, featureSettings)}
                  </div>
                </div>

                <div className="flex items-center space-x-4 ml-4">
                  <button
                    onClick={() => onOverrideFeature(feature)}
                    className={cn(
                      "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-brand-500 focus:ring-offset-2",
                      isEnabled ? "bg-brand-600" : "bg-gray-200"
                    )}
                  >
                    <span className="sr-only">
                      {isEnabled ? 'Disable' : 'Enable'} {feature.name}
                    </span>
                    <span
                      className={cn(
                        "pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out",
                        isEnabled ? "translate-x-5" : "translate-x-0"
                      )}
                    />
                  </button>

                  <button
                    onClick={() => onConfigureFeature(feature)}
                    className="p-2 text-gray-400 hover:text-gray-500 rounded-full hover:bg-gray-100"
                  >
                    <Settings2 className="w-5 h-5" />
                  </button>
                </div>
              </div>

              {/* Warning for disabled required features */}
              {feature.required && !isEnabled && (
                <div className="mt-2 flex items-center text-sm text-yellow-600">
                  <AlertCircle className="w-4 h-4 mr-1" />
                  This is a required feature. Disabling it may affect system functionality.
                </div>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
}