import { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { Loader } from 'lucide-react';

export default function AcceptInvite() {
  const { getAccessTokenSilently, loginWithRedirect, isAuthenticated, isLoading } = useAuth0();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);
  const [isProcessing, setIsProcessing] = useState(true);
  const invitationToken = searchParams.get('token');

  useEffect(() => {
    const acceptInvitation = async () => {
      if (!invitationToken) {
        setError('Invalid invitation link');
        setIsProcessing(false);
        return;
      }

      try {
        if (!isAuthenticated) {
          // Store token and redirect to login
          sessionStorage.setItem('invitationToken', invitationToken);
          await loginWithRedirect({
            appState: { returnTo: `/accept-invite?token=${invitationToken}` }
          });
          return;
        }

        // Get access token
        const token = await getAccessTokenSilently();

        // Accept invitation
        const response = await axios.post(
          `${import.meta.env.VITE_API_URL}/api/invitations/accept/${invitationToken}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          }
        );

        // Force token refresh to get updated claims
        await getAccessTokenSilently({ cacheMode: 'off' });

        // Redirect to dashboard
        navigate('/dashboard', { 
          replace: true,
          state: { organizationId: response.data.organizationId }
        });
      } catch (error: any) {
        console.error('Error accepting invitation:', error);
        setError(error.response?.data?.message || 'Failed to accept invitation');
        setIsProcessing(false);
      }
    };

    if (!isLoading) {
      acceptInvitation();
    }
  }, [isAuthenticated, isLoading]);

  if (isLoading || isProcessing) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50">
        <div className="text-center">
          <Loader className="w-8 h-8 animate-spin text-brand-600 mx-auto" />
          <h2 className="mt-4 text-lg font-medium text-gray-900">Processing invitation...</h2>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50">
        <div className="max-w-md w-full px-4">
          <div className="text-center">
            <h2 className="text-2xl font-bold text-gray-900">Invalid Invitation</h2>
            <p className="mt-2 text-sm text-gray-600">{error}</p>
            <button
              onClick={() => navigate('/')}
              className="mt-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-brand-600 hover:bg-brand-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
            >
              Return Home
            </button>
          </div>
        </div>
      </div>
    );
  }

  return null;
}