import { Routes, Route, Navigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Toaster } from 'react-hot-toast';
import Layout from './components/Layout';
import Login from './components/Login';
import Dashboard from './pages/Dashboard';
import Settings from './pages/Settings';
import OnboardingFlow from './pages/OnboardingFlow';
import Users from './pages/Users';
import Home from './pages/Home';
import LoadingScreen from './components/LoadingScreen';
import ErrorBoundary from './components/ErrorBoundary';
import ProtectedRoute from './components/ProtectedRoute';
import { TenantProvider } from './context/TenantContext';
import { OrganizationProvider } from './context/OrganizationContext';
import { ToastProvider } from './context/ToastContext';
import OrganizationSettings from './pages/OrganizationSettings';
import AcceptInvite from './pages/AcceptInvite';
import { TasksPage } from './pages/tasks/TasksPage';
import { TaskDetailsPage } from './pages/tasks/TaskDetailsPage';
import { TaskSharePage } from './pages/tasks/TaskSharePage';
import { SubscriptionPage } from './pages/SubscriptionPage';
import { PermissionGate } from './components/permissions';
import { TASK_PERMISSIONS, ORGANIZATION_PERMISSIONS } from './types/permission';

export function App() {
  const { isLoading, isAuthenticated, user } = useAuth0();

  if (isLoading) {
    return <LoadingScreen />;
  }

  // Check if user needs onboarding
  const needsOnboarding = isAuthenticated && 
    user?.['https://auth-sync-api/needsOnboarding'] === true && 
    !user?.['https://auth-sync-api/invitationToken']; // Skip onboarding for invited users

  return (
    <ErrorBoundary>
      <ToastProvider>
        <OrganizationProvider>
          <TenantProvider>
            <Routes>
              {/* Public routes */}
              <Route path="/" element={
                isAuthenticated ? (
                  needsOnboarding ? 
                    <Navigate to="/onboarding" replace /> : 
                    <Navigate to="/dashboard" replace />
                ) : (
                  <Home />
                )
              } />
              
              <Route path="/login" element={
                isAuthenticated ? (
                  needsOnboarding ? 
                    <Navigate to="/onboarding" replace /> : 
                    <Navigate to="/dashboard" replace />
                ) : (
                  <Login />
                )
              } />

              {/* Accept Invite route */}
              <Route path="/accept-invite" element={<AcceptInvite />} />

              {/* Shared Task route - public access */}
              <Route path="/tasks/share/:token" element={<TaskSharePage />} />

              {/* Onboarding route */}
              <Route
                path="/onboarding"
                element={
                  <ProtectedRoute>
                    {needsOnboarding ? (
                      <OnboardingFlow />
                    ) : (
                      <Navigate to="/dashboard" replace />
                    )}
                  </ProtectedRoute>
                }
              />

              {/* Protected routes with Layout */}
              <Route element={<Layout />}>
                <Route
                  path="/dashboard"
                  element={
                    <ProtectedRoute>
                      {needsOnboarding ? (
                        <Navigate to="/onboarding" replace />
                      ) : (
                        <Dashboard />
                      )}
                    </ProtectedRoute>
                  }
                />

                {/* Task Routes */}
                <Route
                  path="/tasks"
                  element={
                    <ProtectedRoute>
                      <PermissionGate 
                        permission={TASK_PERMISSIONS.READ_ASSIGNED}
                        fallback={<Navigate to="/dashboard" replace />}
                      >
                        <TasksPage />
                      </PermissionGate>
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/tasks/:taskId"
                  element={
                    <ProtectedRoute>
                      <PermissionGate 
                        permission={TASK_PERMISSIONS.READ_ASSIGNED}
                        fallback={<Navigate to="/tasks" replace />}
                      >
                        <TaskDetailsPage />
                      </PermissionGate>
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/subscription"
                  element={
                    <ProtectedRoute>
                      <PermissionGate
                        permission={ORGANIZATION_PERMISSIONS.MANAGE_BILLING}
                        fallback={<Navigate to="/dashboard" replace />}
                      >
                        <SubscriptionPage />
                      </PermissionGate>
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/settings/*"
                  element={
                    <ProtectedRoute>
                      {needsOnboarding ? (
                        <Navigate to="/onboarding" replace />
                      ) : (
                        <Settings />
                      )}
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/organization-settings"
                  element={
                    <ProtectedRoute>
                      {needsOnboarding ? (
                        <Navigate to="/onboarding" replace />
                      ) : (
                        <OrganizationSettings />
                      )}
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/users"
                  element={
                    <ProtectedRoute>
                      {needsOnboarding ? (
                        <Navigate to="/onboarding" replace />
                      ) : (
                        <Users />
                      )}
                    </ProtectedRoute>
                  }
                />
              </Route>

              {/* Catch all route */}
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>

            {/* Global Toast Configuration */}
            <Toaster
              position="top-right"
              toastOptions={{
                duration: 5000,
                style: {
                  background: '#fff',
                  color: '#363636',
                  padding: '16px',
                  borderRadius: '8px',
                  boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
                },
                success: {
                  style: {
                    background: 'rgb(56, 48, 245)',
                    color: '#fff',
                  },
                  iconTheme: {
                    primary: '#fff',
                    secondary: 'rgb(56, 48, 245)',
                  },
                },
                error: {
                  style: {
                    background: '#DC2626',
                    color: '#fff',
                  },
                  iconTheme: {
                    primary: '#fff',
                    secondary: '#DC2626',
                  },
                },
              }}
            />
          </TenantProvider>
        </OrganizationProvider>
      </ToastProvider>
    </ErrorBoundary>
  );
}