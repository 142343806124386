import { useState } from 'react';
import { Loader2 } from 'lucide-react';
import Modal from '../../common/Modal';
import { Input } from '../../common/Input';
import type { Feature, FeatureSettings } from '../../../types/feature';

interface FeatureSettingsModalProps {
  isOpen: boolean;
  onClose: () => void;
  feature: Feature;
  settings: FeatureSettings;
  onSave: (settings: FeatureSettings) => Promise<void>;
}

export function FeatureSettingsModal({
  isOpen,
  onClose,
  feature,
  settings,
  onSave
}: FeatureSettingsModalProps) {
  const [formData, setFormData] = useState<FeatureSettings>(settings || {
    enabled: feature.defaultEnabled,
    limits: {},
    config: {}
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      await onSave(formData);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={`Configure ${feature.name}`}
    >
      <form onSubmit={handleSubmit} className="space-y-6">
        {/* Feature Limits */}
        {feature.hasLimits && (
          <div>
            <h3 className="text-sm font-medium text-gray-700 mb-2">Limits</h3>
            <div className="space-y-4">
              {Object.entries(feature.limits || {}).map(([key, config]) => (
                <Input
                  key={key}
                  type="number"
                  label={config.label}
                  value={formData.limits?.[key] || config.default}
                  onChange={(e) => setFormData(prev => ({
                    ...prev,
                    limits: {
                      ...prev.limits,
                      [key]: parseInt(e.target.value)
                    }
                  }))}
                  min={config.min}
                  max={config.max}
                  required
                />
              ))}
            </div>
          </div>
        )}

        {/* Feature Configuration */}
        {feature.hasConfig && (
          <div>
            <h3 className="text-sm font-medium text-gray-700 mb-2">Configuration</h3>
            <div className="space-y-4">
              {Object.entries(feature.config || {}).map(([key, config]) => (
                <div key={key}>
                  {config.type === 'boolean' && (
                    <label className="flex items-center">
                      <input
                        type="checkbox"
                        checked={formData.config?.[key] || false}
                        onChange={(e) => setFormData(prev => ({
                          ...prev,
                          config: {
                            ...prev.config,
                            [key]: e.target.checked
                          }
                        }))}
                        className="h-4 w-4 text-brand-600 focus:ring-brand-500 border-gray-300 rounded"
                      />
                      <span className="ml-2 text-sm text-gray-700">{config.label}</span>
                    </label>
                  )}

                  {config.type === 'string' && (
                    <Input
                      type="text"
                      label={config.label}
                      value={formData.config?.[key] || ''}
                      onChange={(e) => setFormData(prev => ({
                        ...prev,
                        config: {
                          ...prev.config,
                          [key]: e.target.value
                        }
                      }))}
                    />
                  )}

                  {config.type === 'number' && (
                    <Input
                      type="number"
                      label={config.label}
                      value={formData.config?.[key] || ''}
                      onChange={(e) => setFormData(prev => ({
                        ...prev,
                        config: {
                          ...prev.config,
                          [key]: parseInt(e.target.value)
                        }
                      }))}
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
        )}

        {/* Actions */}
        <div className="flex justify-end space-x-3">
          <button
            type="button"
            onClick={onClose}
            className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={isSubmitting}
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-brand-600 hover:bg-brand-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500 disabled:opacity-50"
          >
            {isSubmitting ? (
              <>
                <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                Saving...
              </>
            ) : (
              'Save Changes'
            )}
          </button>
        </div>
      </form>
    </Modal>
  );
}