import { useState } from 'react';
import { Bell } from 'lucide-react';
import { useNotifications } from '../../../hooks/useNotifications';
import { NotificationList } from '../NotificationList';
import { cn } from '../../../lib/utils';
import type { NotificationBellProps } from './types';

export function NotificationBell({ className }: NotificationBellProps) {
  const [isOpen, setIsOpen] = useState(false);
  const { unreadCount, isConnected, notifications } = useNotifications();

  console.log('[NotificationBell] Current state:', {
    unreadCount,
    isConnected,
    notificationCount: notifications.length,
    notifications
  });

  return (
    <div className="relative">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={cn(
          "relative p-2 text-gray-500 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500 rounded-full",
          className
        )}
      >
        <Bell className="h-5 w-5" />
        {unreadCount > 0 && (
          <span className="absolute top-0 right-0 block h-2 w-2 rounded-full bg-red-500 ring-2 ring-white" />
        )}
        {!isConnected && (
          <span className="absolute bottom-0 right-0 block h-2 w-2 rounded-full bg-yellow-500 ring-2 ring-white" />
        )}
      </button>

      {isOpen && (
        <NotificationList
          notifications={notifications}
          onClose={() => setIsOpen(false)}
          className="absolute right-0 mt-2"
        />
      )}
    </div>
  );
}