import { useState } from 'react';
import { Loader2 } from 'lucide-react';
import Modal from '../../common/Modal/Modal';
import { cn } from '../../../lib/utils';

interface DuplicateTaskModalProps {
  isOpen: boolean;
  onClose: () => void;
  onDuplicate: (data: { title: string; description: string }) => Promise<void>;
  initialData: {
    title: string;
    description: string;
  };
}

export function DuplicateTaskModal({
  isOpen,
  onClose,
  onDuplicate,
  initialData
}: DuplicateTaskModalProps) {
  const [title, setTitle] = useState(initialData.title);
  const [description, setDescription] = useState(initialData.description);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    e.stopPropagation(); // Prevent event bubbling
    
    if (!title.trim() || !description.trim()) {
      setError('Title and description are required');
      return;
    }

    setIsSubmitting(true);
    setError('');

    try {
      await onDuplicate({
        title: title.trim(),
        description: description.trim()
      });
    } catch (error) {
      console.error('Failed to duplicate task:', error);
      setError('Failed to duplicate task. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const inputClasses = cn(
    "block w-full rounded-md shadow-sm sm:text-sm",
    "border border-gray-300 focus:border-brand-500 focus:ring-brand-500",
    "placeholder:text-gray-400",
    "h-10 py-2 px-3"
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Duplicate Task"
    >
      <form onSubmit={handleSubmit} className="space-y-6" onClick={e => e.stopPropagation()}>
        <div>
          <label htmlFor="title" className="block text-sm font-medium text-gray-700 mb-1">
            Title
            <span className="text-red-500 ml-1">*</span>
          </label>
          <input
            id="title"
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className={inputClasses}
            required
            autoFocus
          />
        </div>

        <div>
          <label htmlFor="description" className="block text-sm font-medium text-gray-700 mb-1">
            Description
            <span className="text-red-500 ml-1">*</span>
          </label>
          <textarea
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            rows={4}
            className={cn(
              inputClasses,
              "h-auto min-h-[100px]"
            )}
            required
          />
        </div>

        {error && (
          <div className="rounded-md bg-red-50 p-4">
            <p className="text-sm text-red-600">{error}</p>
          </div>
        )}

        <div className="flex justify-end space-x-3">
          <button
            type="button"
            onClick={onClose}
            disabled={isSubmitting}
            className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={isSubmitting}
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-brand-600 hover:bg-brand-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500 disabled:opacity-50"
          >
            {isSubmitting ? (
              <>
                <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                Duplicating...
              </>
            ) : (
              'Duplicate Task'
            )}
          </button>
        </div>
      </form>
    </Modal>
  );
}