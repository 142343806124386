import { useState } from 'react';
import { Grid, Loader2, AlertCircle, Plus } from 'lucide-react';
import { cn } from '../../../../lib/utils';
import { TaskGridProps } from './types';
import { TaskCard } from '../../TaskCard';
import { QuickSubtaskForm } from '../../QuickSubtaskForm';
import { PermissionGate } from '../../../permissions';
import { TASK_PERMISSIONS } from '../../../../types/permission';

export function TaskGrid({
  tasks,
  onTaskClick,
  onTaskEdit,
  onTaskDelete,
  isLoading,
  error,
  className
}: TaskGridProps) {
  const [isAddingTask, setIsAddingTask] = useState(false);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center py-8">
        <Loader2 className="w-8 h-8 text-brand-600 animate-spin" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="rounded-md bg-red-50 p-4">
        <div className="flex">
          <AlertCircle className="h-5 w-5 text-red-400" />
          <div className="ml-3">
            <h3 className="text-sm font-medium text-red-800">{error}</h3>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={cn(
      'grid gap-4',
      'grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4',
      className
    )}>
      {tasks.map((task) => (
        <div key={task.id} className="h-full">
          <TaskCard
            task={task}
            onClick={() => onTaskClick?.(task)}
            onDelete={() => onTaskDelete?.(task.id)}
            className="h-full"
            isCompact
          />
        </div>
      ))}
    </div>
  );
}