import { AlertCircle } from 'lucide-react';
import { cn } from '../../lib/utils';
import type { SubscriptionPlan } from '../../types/subscription';

interface UsageStatsProps {
  usage: Record<string, number>;
  plan: SubscriptionPlan;
}

export function UsageStats({ usage, plan }: UsageStatsProps) {
  const getUsagePercentage = (feature: string, count: number) => {
    const limit = plan.features[feature]?.limit;
    if (!limit) return 0;
    return Math.min((count / limit) * 100, 100);
  };

  return (
    <div className="bg-white shadow-sm rounded-lg border border-gray-200">
      <div className="px-6 py-5 border-b border-gray-200">
        <h3 className="text-lg font-medium text-gray-900">Feature Usage</h3>
      </div>
      <div className="px-6 py-5">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {Object.entries(usage).map(([feature, count]) => {
            const featureConfig = plan.features[feature];
            if (!featureConfig?.enabled) return null;

            const percentage = getUsagePercentage(feature, count);
            const isNearLimit = percentage >= 80;

            return (
              <div key={feature} className="space-y-2">
                <div className="flex items-center justify-between">
                  <span className="text-sm font-medium text-gray-700">
                    {feature}
                  </span>
                  <span className="text-sm text-gray-500">
                    {count} / {featureConfig.limit || '∞'} {featureConfig.unit}
                  </span>
                </div>
                <div className="relative w-full h-2 bg-gray-200 rounded-full overflow-hidden">
                  <div
                    className={cn(
                      'absolute h-full rounded-full transition-all duration-300',
                      isNearLimit ? 'bg-yellow-500' : 'bg-brand-500'
                    )}
                    style={{ width: `${percentage}%` }}
                  />
                </div>
                {isNearLimit && (
                  <div className="flex items-center text-yellow-700 text-xs">
                    <AlertCircle className="w-4 h-4 mr-1" />
                    Approaching limit
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}