import React from 'react';
import { List, Grid, Columns, GanttChart } from 'lucide-react';
import { cn } from '../../../../lib/utils';
import { ViewSelectorProps, ViewType } from './types';

export function ViewSelector({
  view,
  onViewChange,
  className
}: ViewSelectorProps) {
  const views: Array<{
    id: ViewType;
    icon: React.ComponentType<{ className?: string }>;
    label: string;
  }> = [
    { id: 'list', icon: List, label: 'List' },
    { id: 'grid', icon: Grid, label: 'Grid' },
    { id: 'kanban', icon: Columns, label: 'Kanban' },
    { id: 'timeline', icon: GanttChart, label: 'Timeline' }
  ];

  return (
    <div className={cn('flex items-center space-x-2 relative z-[35]', className)}>
      {views.map(({ id, icon: Icon, label }) => (
        <button
          key={id}
          onClick={() => onViewChange(id)}
          className={cn(
            'inline-flex items-center px-3 py-2 rounded-md text-sm font-medium',
            view === id
              ? 'bg-brand-50 text-brand-700'
              : 'text-gray-500 hover:text-gray-700 hover:bg-gray-50'
          )}
        >
          <Icon className="w-4 h-4 mr-2" />
          {label}
        </button>
      ))}
    </div>
  );
}