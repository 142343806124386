import React, { useEffect } from 'react';
import { format } from 'date-fns';
import { Bell, Check, CheckCheck, X } from 'lucide-react';
import { useNotifications } from '../../../hooks/useNotifications';
import { useClickOutside } from '../../../hooks/useClickOutside';
import { cn } from '../../../lib/utils';
import { NotificationType } from '../../../types/notification';
import type { NotificationListProps } from './types';

export function NotificationList({ notifications, onClose, className }: NotificationListProps) {
  const { markAsRead, markAllAsRead } = useNotifications();
  const ref = useClickOutside(onClose);

  console.log('[NotificationList] Rendering notifications:', notifications);

  useEffect(() => {
    const handleEscape = (e: KeyboardEvent) => {
      if (e.key === 'Escape') onClose();
    };

    document.addEventListener('keydown', handleEscape);
    return () => document.removeEventListener('keydown', handleEscape);
  }, [onClose]);

  const getNotificationIcon = (type: NotificationType) => {
    switch (type) {
      case NotificationType.TASK_CREATED:
      case NotificationType.TASK_UPDATED:
      case NotificationType.TASK_ASSIGNED:
        return <Bell className="h-5 w-5 text-brand-600" />;
      case NotificationType.TASK_COMMENTED:
        return <Bell className="h-5 w-5 text-blue-600" />;
      case NotificationType.FILE_UPLOADED:
        return <Bell className="h-5 w-5 text-green-600" />;
      case NotificationType.USER_INVITED:
      case NotificationType.USER_JOINED:
        return <Bell className="h-5 w-5 text-purple-600" />;
      case NotificationType.CUSTOM_FIELD_UPDATED:
        return <Bell className="h-5 w-5 text-orange-600" />;
      default:
        return <Bell className="h-5 w-5 text-gray-600" />;
    }
  };

  return (
    <div
      ref={ref}
      className={cn(
        "bg-white rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 max-h-[80vh] flex flex-col w-96",
        className
      )}
    >
      {/* Header */}
      <div className="p-4 border-b border-gray-200 flex items-center justify-between">
        <h3 className="text-lg font-medium text-gray-900">Notifications</h3>
        <div className="flex items-center space-x-2">
          <button
            onClick={markAllAsRead}
            className="text-sm text-gray-500 hover:text-gray-700"
            title="Mark all as read"
          >
            <CheckCheck className="h-4 w-4" />
          </button>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-500"
          >
            <X className="h-5 w-5" />
          </button>
        </div>
      </div>

      {/* Notification List */}
      <div className="flex-1 overflow-y-auto">
        {notifications && notifications.length > 0 ? (
          <div className="divide-y divide-gray-200">
            {notifications.map((notification) => (
              <div
                key={notification.id}
                className={cn(
                  "p-4 hover:bg-gray-50 transition-colors",
                  !notification.read && "bg-brand-50"
                )}
              >
                <div className="flex items-start space-x-3">
                  <div className="flex-shrink-0">
                    {getNotificationIcon(notification.type)}
                  </div>
                  <div className="flex-1 min-w-0">
                    <p className="text-sm font-medium text-gray-900">
                      {notification.title}
                    </p>
                    <p className="mt-1 text-sm text-gray-500">
                      {notification.message}
                    </p>
                    <p className="mt-1 text-xs text-gray-400">
                      {format(new Date(notification.createdAt), 'PPp')}
                    </p>
                    {notification.metadata?.taskUrl && (
                      <a
                        href={notification.metadata.taskUrl}
                        className="mt-2 text-sm text-brand-600 hover:text-brand-700"
                      >
                        View Task
                      </a>
                    )}
                  </div>
                  {!notification.read && (
                    <button
                      onClick={() => markAsRead(notification.id)}
                      className="flex-shrink-0 p-1 rounded-full text-gray-400 hover:text-gray-500"
                      title="Mark as read"
                    >
                      <Check className="h-4 w-4" />
                    </button>
                  )}
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="p-4 text-center text-gray-500">
            No notifications
          </div>
        )}
      </div>
    </div>
  );
}