import { io, Socket } from 'socket.io-client';

class WebSocketService {
  private static instance: WebSocketService;
  private socket: Socket | null = null;
  private connectionAttempts = 0;
  private readonly MAX_RECONNECTION_ATTEMPTS = 5;
  private isConnecting = false;
  private readonly DEBUG = process.env.NODE_ENV === 'development';

  private constructor() {}

  static getInstance(): WebSocketService {
    if (!WebSocketService.instance) {
      WebSocketService.instance = new WebSocketService();
    }
    return WebSocketService.instance;
  }

  async connect(token: string, auth0Id: string, organizationId: number): Promise<Socket> {
    // If already connecting, wait for existing connection
    if (this.isConnecting) {
      return new Promise((resolve, reject) => {
        const checkInterval = setInterval(() => {
          if (this.socket?.connected) {
            clearInterval(checkInterval);
            resolve(this.socket);
          }
          if (this.connectionAttempts >= this.MAX_RECONNECTION_ATTEMPTS) {
            clearInterval(checkInterval);
            reject(new Error('Connection failed'));
          }
        }, 100);
      });
    }

    // If already connected, return existing socket
    if (this.socket?.connected) {
      this.log('Using existing connection');
      return this.socket;
    }

    // Clean up any existing socket
    this.disconnect();

    this.log('Initializing new connection');
    this.isConnecting = true;
    
    this.socket = io(import.meta.env.VITE_API_URL, {
      auth: { token },
      transports: ['websocket'],
      reconnectionAttempts: this.MAX_RECONNECTION_ATTEMPTS,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      autoConnect: false,
      query: {
        auth0Id,
        organizationId: organizationId.toString()
      }
    });

    return new Promise((resolve, reject) => {
      if (!this.socket) {
        this.isConnecting = false;
        reject(new Error('Failed to initialize socket'));
        return;
      }

      const timeout = setTimeout(() => {
        this.disconnect();
        this.isConnecting = false;
        reject(new Error('Connection timeout'));
      }, 10000);

      this.socket.on('connect', () => {
        this.log('Connected successfully');
        clearTimeout(timeout);
        this.connectionAttempts = 0;
        this.isConnecting = false;
        
        // Join user's rooms
        this.socket?.emit('join', {
          auth0Id,
          organizationId
        });
        
        resolve(this.socket as Socket);
      });

      this.socket.on('connect_error', (error) => {
        console.error('[WebSocket] Connection error:', error);
        this.connectionAttempts++;
        
        if (this.connectionAttempts >= this.MAX_RECONNECTION_ATTEMPTS) {
          clearTimeout(timeout);
          this.disconnect();
          this.isConnecting = false;
          reject(new Error('Max reconnection attempts reached'));
        }
      });

      this.socket.on('disconnect', (reason) => {
        this.log('Disconnected:', reason);
        if (reason === 'io server disconnect' || reason === 'io client disconnect') {
          // Disconnected intentionally, don't reconnect
          this.disconnect();
        }
      });

      this.socket.on('error', (error: Error) => {
        console.error('[WebSocket] Socket error:', error);
      });

      // Start connection
      this.socket.connect();
    });
  }

  disconnect(): void {
    if (this.socket) {
      this.log('Closing connection');
      this.socket.removeAllListeners();
      this.socket.disconnect();
      this.socket = null;
      this.isConnecting = false;
    }
  }

  getSocket(): Socket | null {
    return this.socket;
  }

  private log(...args: any[]) {
    if (this.DEBUG) {
      console.log('[WebSocket]', ...args);
    }
  }
}

export const webSocketService = WebSocketService.getInstance();