import { ArrowUp, ArrowDown } from 'lucide-react';
import { cn } from '../../../../lib/utils';
import type { VelocityData } from './types';

interface VelocityMetricProps {
  data: VelocityData;
}

export function VelocityMetric({ data }: VelocityMetricProps) {
  const percentChange = ((data.current - data.previous) / data.previous) * 100;
  const isPositive = percentChange > 0;

  return (
    <div>
      <h4 className="text-sm font-medium text-gray-700 mb-4">
        Team Velocity
      </h4>
      <div className="flex items-end space-x-2">
        <span className="text-2xl font-bold text-gray-900">
          {data.current.toFixed(1)}
        </span>
        <span className="text-sm text-gray-500 mb-1">
          tasks/week
        </span>
        <div className={cn(
          'flex items-center text-sm ml-2',
          isPositive ? 'text-green-600' : 'text-red-600'
        )}>
          {isPositive ? (
            <ArrowUp className="w-4 h-4 mr-1" />
          ) : (
            <ArrowDown className="w-4 h-4 mr-1" />
          )}
          {Math.abs(percentChange).toFixed(1)}%
        </div>
      </div>
      <p className="mt-1 text-sm text-gray-500">
        vs previous {data.periodLength} days
      </p>
    </div>
  );
}