import { useState, useEffect, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { io, Socket } from 'socket.io-client';
import { NotificationType, NotificationPayload } from '../types/notification';
import { webSocketService } from '../services/websocket';

export function useNotifications() {
  const { getAccessTokenSilently, user } = useAuth0();
  const [notifications, setNotifications] = useState<NotificationPayload[]>([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [isConnected, setIsConnected] = useState(false);

  useEffect(() => {
    let mounted = true;

    const initializeSocket = async () => {
      if (!user?.sub || !user['https://auth-sync-api/organizationId']) {
        console.log('[Notifications] Missing user info, skipping socket initialization');
        return;
      }

      try {
        const token = await getAccessTokenSilently();
        const socket = await webSocketService.connect(
          token,
          user.sub,
          user['https://auth-sync-api/organizationId']
        );

        socket.on('notification', (notification: NotificationPayload) => {
          console.log('[Notifications] Received new notification:', notification);
          if (mounted) {
            setNotifications(prev => {
              const exists = prev.some(n => n.id === notification.id);
              if (exists) return prev;
              return [notification, ...prev];
            });
            setUnreadCount(prev => prev + 1);
          }
        });

        socket.on('pending_notifications', (pendingNotifications: NotificationPayload[]) => {
          console.log('[Notifications] Received pending notifications:', pendingNotifications);
          if (mounted) {
            setNotifications(pendingNotifications);
            setUnreadCount(pendingNotifications.filter(n => !n.read).length);
          }
        });

        socket.on('notification_updated', (data: { id: string; read: boolean }) => {
          if (mounted) {
            setNotifications(prev => 
              prev.map(n => n.id === data.id ? { ...n, read: data.read } : n)
            );
            if (data.read) {
              setUnreadCount(prev => Math.max(0, prev - 1));
            }
          }
        });

        socket.on('all_notifications_read', () => {
          if (mounted) {
            setNotifications(prev => prev.map(n => ({ ...n, read: true })));
            setUnreadCount(0);
          }
        });

        setIsConnected(true);
      } catch (error) {
        console.error('[Notifications] Failed to initialize socket:', error);
        setIsConnected(false);
      }
    };

    initializeSocket();

    return () => {
      mounted = false;
      webSocketService.disconnect();
    };
  }, [user, getAccessTokenSilently]);

  const markAsRead = useCallback(async (notificationId: string) => {
    try {
      const socket = webSocketService.getSocket();
      socket?.emit('mark_read', notificationId);
      setNotifications(prev => 
        prev.map(n => n.id === notificationId ? { ...n, read: true } : n)
      );
      setUnreadCount(prev => Math.max(0, prev - 1));
    } catch (error) {
      console.error('[Notifications] Failed to mark notification as read:', error);
    }
  }, []);

  const markAllAsRead = useCallback(async () => {
    try {
      const userId = user?.sub ? parseInt(user.sub.split('|')[1]) : undefined;
      if (!userId) return;

      const socket = webSocketService.getSocket();
      socket?.emit('mark_all_read', userId);
      setNotifications(prev => prev.map(n => ({ ...n, read: true })));
      setUnreadCount(0);
    } catch (error) {
      console.error('[Notifications] Failed to mark all notifications as read:', error);
    }
  }, [user]);

  return {
    notifications,
    unreadCount,
    isConnected,
    markAsRead,
    markAllAsRead
  };
}