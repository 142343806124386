import { useAuth0 } from '@auth0/auth0-react';
import { ROLES, UserRole } from '../types/auth';
import { PermissionType, PermissionOptions } from '../types/permission';

export function usePermissions() {
  const { user } = useAuth0();
  const userRole = user?.['https://auth-sync-api/role'] as UserRole || 'member';
  const organizationId = user?.['https://auth-sync-api/organizationId'];
  const userId = user?.sub ? parseInt(user.sub.split('|')[1]) : undefined;

  console.log('[usePermissions] User context:', {
    userRole,
    organizationId,
    userId,
    user
  });

  const isOrgAdmin = userRole === 'owner' || userRole === 'admin';

  // Get permissions for user's role
  const rolePermissions = ROLES[userRole]?.permissions || [];

  console.log('[usePermissions] Role permissions:', {
    role: userRole,
    permissions: rolePermissions
  });

  const hasPermission = (
    permission: PermissionType | PermissionType[],
    options: PermissionOptions = {}
  ): boolean => {
    const permissions = Array.isArray(permission) ? permission : [permission];

    console.log('[usePermissions] Checking permission:', {
      requested: permissions,
      options,
      userRole,
      isOrgAdmin,
      hasPermission: permissions.some(p => rolePermissions.includes(p))
    });

    // If org admin override is allowed and user is org admin, grant permission
    if (options.allowOrgAdmin && isOrgAdmin) {
      console.log('[usePermissions] Granting access due to admin override');
      return true;
    }

    // Check ownership if required
    if (options.requireOwnership && options.ownerId && userId !== undefined) {
      if (options.ownerId !== userId) {
        console.log('[usePermissions] Ownership check failed');
        return false;
      }
    }

    // Check if user has any of the required permissions
    return permissions.some(p => rolePermissions.includes(p));
  };

  const hasAnyPermission = (
    permissions: PermissionType[],
    options: PermissionOptions = {}
  ): boolean => {
    const result = permissions.some(permission => hasPermission(permission, options));
    console.log('[usePermissions] Checking any permissions:', {
      permissions,
      options,
      result
    });
    return result;
  };

  const hasAllPermissions = (
    permissions: PermissionType[],
    options: PermissionOptions = {}
  ): boolean => {
    const result = permissions.every(permission => hasPermission(permission, options));
    console.log('[usePermissions] Checking all permissions:', {
      permissions,
      options,
      result
    });
    return result;
  };

  return {
    userRole,
    isOrgAdmin,
    organizationId,
    userId,
    hasPermission,
    hasAnyPermission,
    hasAllPermissions,
    permissions: rolePermissions
  };
}