import { memo } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { cn } from '../../lib/utils';
import {
  TaskOverviewWidget,
  MyTasksWidget,
  TeamWorkloadWidget,
  TimelineWidget,
  AnalyticsWidget,
  RecentActivityWidget,
  PriorityTasksWidget,
  CustomReportsWidget,
  CommentThreadWidget
} from './widgets';
import type { DashboardLayout } from '../../types/dashboard';

interface DashboardGridProps {
  layout: DashboardLayout;
}

const widgetComponents = {
  taskOverview: TaskOverviewWidget,
  myTasks: MyTasksWidget,
  teamWorkload: TeamWorkloadWidget,
  timeline: TimelineWidget,
  analytics: AnalyticsWidget,
  recentActivity: RecentActivityWidget,
  priorityTasks: PriorityTasksWidget,
  customReports: CustomReportsWidget,
  commentThread: CommentThreadWidget
};

export const DashboardGrid = memo(({ layout }: DashboardGridProps) => {
  return (
    <>
      {layout.filter(widget => widget.visible).map((widget, index) => {
        const Widget = widgetComponents[widget.type];
        if (!Widget) return null;

        return (
          <Draggable key={widget.id} draggableId={widget.id} index={index}>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                className={cn(
                  'transition-shadow duration-200',
                  snapshot.isDragging && 'shadow-lg',
                  widget.size === 'large' && 'col-span-2'
                )}
              >
                <Widget />
              </div>
            )}
          </Draggable>
        );
      })}
    </>
  );
});

DashboardGrid.displayName = 'DashboardGrid';