import React from 'react';
import { format } from 'date-fns';
import { Loader2, AlertCircle } from 'lucide-react';
import { useTaskHistory } from '../../../hooks/task/useTaskHistory';
import { TaskHistoryProps } from './types';

export function TaskHistory({ taskId }: TaskHistoryProps) {
  const { history, isLoading, error } = useTaskHistory(taskId);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center py-8">
        <Loader2 className="w-8 h-8 text-brand-600 animate-spin" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="rounded-md bg-red-50 p-4">
        <div className="flex">
          <AlertCircle className="h-5 w-5 text-red-400" />
          <div className="ml-3">
            <h3 className="text-sm font-medium text-red-800">{error}</h3>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flow-root">
      <ul role="list" className="-mb-8">
        {history.map((entry, entryIdx) => (
          <li key={entry.id}>
            <div className="relative pb-8">
              {entryIdx !== history.length - 1 ? (
                <span
                  className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                  aria-hidden="true"
                />
              ) : null}
              <div className="relative flex space-x-3">
                <div>
                  <span className="h-8 w-8 rounded-full bg-brand-100 flex items-center justify-center ring-8 ring-white">
                    <span className="text-sm font-medium text-brand-600">
                      {entry.user.firstName?.[0] || entry.user.email[0]}
                    </span>
                  </span>
                </div>
                <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                  <div>
                    <p className="text-sm text-gray-500">
                      <span className="font-medium text-gray-900">
                        {entry.user.firstName && entry.user.lastName
                          ? `${entry.user.firstName} ${entry.user.lastName}`
                          : entry.user.email}
                      </span>{' '}
                      {entry.type === 'created' && 'created this task'}
                      {entry.type === 'updated' && (
                        <>
                          updated {entry.field} from{' '}
                          <span className="font-medium">{entry.oldValue}</span> to{' '}
                          <span className="font-medium">{entry.newValue}</span>
                        </>
                      )}
                      {entry.type === 'commented' && 'added a comment'}
                      {entry.type === 'attachment' && 'added an attachment'}
                      {entry.type === 'subtask' && 'added a subtask'}
                    </p>
                  </div>
                  <div className="whitespace-nowrap text-right text-sm text-gray-500">
                    <time dateTime={entry.createdAt}>
                      {format(new Date(entry.createdAt), 'PPp')}
                    </time>
                  </div>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}