import { 
    CheckCircle2, 
    AlertCircle, 
    MessageSquare, 
    FileText,
    UserPlus,
    Edit,
    Trash2,
    LucideIcon
  } from 'lucide-react';
  import { ActivityType } from '../types';
  
  export function getActivityIcon(type: ActivityType): LucideIcon {
    switch (type) {
      case 'task_completed':
        return CheckCircle2;
      case 'task_blocked':
        return AlertCircle;
      case 'comment_added':
        return MessageSquare;
      case 'file_uploaded':
        return FileText;
      case 'user_invited':
        return UserPlus;
      case 'task_updated':
        return Edit;
      case 'task_deleted':
        return Trash2;
      default:
        return FileText;
    }
  }