import { DatePicker } from '../DatePicker';
import { CustomSelect } from '../CustomSelect';
import { FileUpload } from '../FileUpload';
import { cn } from '../../../lib/utils';
import type { CustomFieldInputProps } from './types';
import type { CustomFieldOption } from '../../../types/task';

export function CustomFieldInput({
  field,
  value,
  onChange,
  error,
  isDisabled,
  required,
  className,
  isUploading,
  uploadProgress
}: CustomFieldInputProps) {
  const inputClasses = cn(
    "block w-full rounded-md shadow-sm sm:text-sm",
    "border border-gray-300 focus:border-brand-500 focus:ring-brand-500",
    "placeholder:text-gray-400",
    "h-10 py-2 px-3" // Added fixed height and padding to match title field
  );

  const renderInput = () => {
    switch (field.type) {
      case 'text':
      case 'url':
      case 'email':
      case 'phone':
        return (
          <input
            type={field.type === 'text' ? 'text' : field.type}
            value={value as string || ''}
            onChange={(e) => onChange(e.target.value)}
            disabled={isDisabled}
            required={required}
            placeholder={field.description}
            className={inputClasses}
          />
        );

      case 'textarea':
        return (
          <textarea
            value={value as string || ''}
            onChange={(e) => onChange(e.target.value)}
            disabled={isDisabled}
            required={required}
            placeholder={field.description}
            rows={3}
            className={cn(
              inputClasses,
              "h-auto py-2" // Override height for textarea but keep consistent padding
            )}
          />
        );

      case 'number':
      case 'currency':
      case 'percentage':
        return (
          <div className="relative">
            {field.config.formatting?.prefix && (
              <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-gray-500">
                {field.config.formatting.prefix}
              </span>
            )}
            <input
              type="number"
              value={value as number || ''}
              onChange={(e) => onChange(Number(e.target.value))}
              disabled={isDisabled}
              required={required}
              min={field.config.validation?.min}
              max={field.config.validation?.max}
              step={field.type === 'currency' ? '0.01' : '1'}
              placeholder={field.description}
              className={cn(
                inputClasses,
                field.config.formatting?.prefix && 'pl-7',
                field.config.formatting?.suffix && 'pr-7'
              )}
            />
            {field.config.formatting?.suffix && (
              <span className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-500">
                {field.config.formatting.suffix}
              </span>
            )}
          </div>
        );

      case 'select':
        return (
          <CustomSelect
            options={(field.config.options || []).map((opt: CustomFieldOption) => ({
              value: opt.value,
              label: opt.label,
              description: opt.description
            }))}
            value={value as string}
            onChange={(val) => onChange(val)}
            isDisabled={isDisabled}
            required={required}
            error={error}
            placeholder={field.description}
          />
        );

      case 'multiselect':
        return (
          <CustomSelect
            options={(field.config.options || []).map((opt: CustomFieldOption) => ({
              value: opt.value,
              label: opt.label,
              description: opt.description
            }))}
            value={value as string[]}
            onChange={(val) => onChange(val)}
            isMulti
            isDisabled={isDisabled}
            required={required}
            error={error}
            placeholder={field.description}
          />
        );

      case 'radio':
        return (
          <div className="space-y-2">
            {(field.config.options || []).map((option: CustomFieldOption) => (
              <label key={option.value} className="flex items-center">
                <input
                  type="radio"
                  value={option.value}
                  checked={value === option.value}
                  onChange={(e) => onChange(e.target.value)}
                  disabled={isDisabled}
                  required={required}
                  className="h-4 w-4 text-brand-600 focus:ring-brand-500 border-gray-300"
                />
                <span className="ml-2 text-sm text-gray-700">{option.label}</span>
              </label>
            ))}
          </div>
        );

      case 'checkbox':
        return (
          <label className="flex items-center">
            <input
              type="checkbox"
              checked={value as boolean || false}
              onChange={(e) => onChange(e.target.checked)}
              disabled={isDisabled}
              required={required}
              className="h-4 w-4 text-brand-600 focus:ring-brand-500 border-gray-300 rounded"
            />
            <span className="ml-2 text-sm text-gray-700">
              {field.description}
            </span>
          </label>
        );

      case 'date':
      case 'datetime':
        return (
          <DatePicker
            value={value ? new Date(value as string) : null}
            onChange={(date) => onChange(date?.toISOString() || null)}
            isDisabled={isDisabled}
            required={required}
            error={error}
            showTime={field.type === 'datetime'}
            placeholder={field.description}
          />
        );

      case 'file':
      case 'image':
        const accept = field.type === 'image' 
          ? 'image/*'
          : field.config.validation?.accept || undefined;
        
        const maxSize = field.config.validation?.maxSize 
          ? parseInt(String(field.config.validation.maxSize)) * 1024 * 1024 // Convert MB to bytes
          : undefined;

        return (
          <FileUpload
            value={value as File[] || null}
            onChange={onChange}
            multiple={field.config.validation?.multiple || false}
            accept={accept}
            maxSize={maxSize}
            error={error}
            isDisabled={isDisabled}
            required={required}
            placeholder={field.description}
            isUploading={isUploading}
            progress={uploadProgress}
          />
        );

      default:
        return (
          <div className="text-sm text-gray-500">
            Unsupported field type: {field.type}
          </div>
        );
    }
  };

  return (
    <div className={className}>
      <label className="block text-sm font-medium text-gray-700 mb-1">
        {field.name}
        {required && <span className="text-red-500 ml-1">*</span>}
      </label>
      {renderInput()}
      {error && (
        <p className="mt-1 text-sm text-red-600">{error}</p>
      )}
    </div>
  );
}