import { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ChevronLeft } from 'lucide-react';
import { useTask, useTaskMutations } from '../../hooks/task';
import { TaskDetails } from '../../components/task/TaskDetails';
import { PermissionGate } from '../../components/permissions';
import { TASK_PERMISSIONS } from '../../types/permission';
import { TaskForm } from '../../components/task/TaskForm';
import { MagicLink } from '../../components/task/MagicLink';
import Modal from '../../components/common/Modal/Modal';
import { AlertDialog } from '../../components/common/AlertDialog';
import { useToast } from '../../context/ToastContext';
import type { Task } from '../../types/task';

export function TaskDetailsPage() {
  const { taskId } = useParams<{ taskId: string }>();
  const navigate = useNavigate();
  const { showToast } = useToast();
  const { task, isLoading, error } = useTask(taskId ? parseInt(taskId) : null);
  const { updateTask, deleteTask } = useTaskMutations();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const handleBack = () => {
    navigate('/tasks');
  };

  const handleEdit = () => {
    setIsEditModalOpen(true);
  };

  const handleUpdate = async (data: any) => {
    if (!task) return;
    
    setIsSubmitting(true);
    try {
      await updateTask(task.id, data);
      setIsEditModalOpen(false);
      showToast('Task updated successfully', 'success');
      window.location.reload();
    } catch (error) {
      console.error('Failed to update task:', error);
      showToast('Failed to update task', 'error');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDelete = () => {
    setShowDeleteConfirm(true);
  };

  const confirmDelete = async () => {
    if (!task) return;

    try {
      await deleteTask(task.id);
      showToast('Task deleted successfully', 'success');
      navigate('/tasks');
    } catch (error) {
      console.error('Failed to delete task:', error);
      showToast('Failed to delete task', 'error');
    } finally {
      setShowDeleteConfirm(false);
    }
  };

  const handleDuplicate = (duplicatedTask: Task) => {
    navigate(`/tasks/${duplicatedTask.id}`);
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center min-h-[400px]">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-brand-600"></div>
      </div>
    );
  }

  if (error || !task) {
    return (
      <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="bg-red-50 p-4 rounded-md">
          <div className="flex">
            <div className="ml-3">
              <h3 className="text-sm font-medium text-red-800">
                {error || 'Task not found'}
              </h3>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <button
        onClick={handleBack}
        className="mb-6 inline-flex items-center text-sm text-gray-500 hover:text-gray-700"
      >
        <ChevronLeft className="w-4 h-4 mr-1" />
        Back to Tasks
      </button>

      <div className="space-y-6">
        <PermissionGate 
          permission={[
            TASK_PERMISSIONS.UPDATE_ASSIGNED,
            TASK_PERMISSIONS.DELETE_ASSIGNED
          ]}
          options={{ ownerId: task.createdBy }}
        >
          <TaskDetails
            task={task}
            onEdit={handleEdit}
            onDelete={handleDelete}
            onDuplicate={handleDuplicate}
          />
        </PermissionGate>

        <PermissionGate permission={TASK_PERMISSIONS.READ_ASSIGNED}>
          <MagicLink taskId={task.id} className="mt-6" />
        </PermissionGate>
      </div>

      {/* Edit Task Modal */}
      <Modal
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        title="Edit Task"
      >
        <TaskForm
          initialData={task}
          onSubmit={handleUpdate}
          onCancel={() => setIsEditModalOpen(false)}
          isSubmitting={isSubmitting}
        />
      </Modal>

      {/* Delete Confirmation Dialog */}
      <AlertDialog
        isOpen={showDeleteConfirm}
        onClose={() => setShowDeleteConfirm(false)}
        onConfirm={confirmDelete}
        title="Delete Task"
        description="Are you sure you want to delete this task? This action cannot be undone and will also delete all subtasks, comments, and attachments."
        confirmLabel="Delete"
        cancelLabel="Cancel"
        variant="danger"
      />
    </div>
  );
}