import { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { CustomField } from '../../types/task';

const customFieldsCache = new Map<number, {
  fields: CustomField[];
  timestamp: number;
}>();

const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes

export function useCustomFields(organizationId?: number, activeOnly: boolean = false) {
  const { getAccessTokenSilently } = useAuth0();
  const [customFields, setCustomFields] = useState<CustomField[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchCustomFields = async () => {
      if (!organizationId) return;

      // Check cache first
      const cached = customFieldsCache.get(organizationId);
      if (cached && Date.now() - cached.timestamp < CACHE_DURATION) {
        setCustomFields(activeOnly 
          ? cached.fields.filter(field => field.status === 'active')
          : cached.fields
        );
        setIsLoading(false);
        return;
      }

      setIsLoading(true);
      setError(null);

      try {
        const token = await getAccessTokenSilently();
        const response = await axios.get(
          `${import.meta.env.VITE_API_URL}/api/custom-fields`,
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
        
        // Update cache
        customFieldsCache.set(organizationId, {
          fields: response.data,
          timestamp: Date.now()
        });
        
        // Filter active fields only if requested
        setCustomFields(activeOnly 
          ? response.data.filter((field: CustomField) => field.status === 'active')
          : response.data
        );
      } catch (error: any) {
        setError(error.response?.data?.message || 'Failed to fetch custom fields');
        console.error('Error fetching custom fields:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCustomFields();
  }, [organizationId, activeOnly, getAccessTokenSilently]);

  const invalidateCache = () => {
    if (organizationId) {
      customFieldsCache.delete(organizationId);
    }
  };

  const refreshCustomFields = async () => {
    invalidateCache();
    setIsLoading(true);
    setError(null);

    try {
      const token = await getAccessTokenSilently();
      const response = await axios.get(
        `${import.meta.env.VITE_API_URL}/api/custom-fields`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      
      if (organizationId) {
        customFieldsCache.set(organizationId, {
          fields: response.data,
          timestamp: Date.now()
        });
      }
      
      // Filter active fields only if requested
      setCustomFields(activeOnly 
        ? response.data.filter((field: CustomField) => field.status === 'active')
        : response.data
      );
    } catch (error: any) {
      setError(error.response?.data?.message || 'Failed to fetch custom fields');
      console.error('Error fetching custom fields:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return { 
    customFields, 
    isLoading, 
    error,
    invalidateCache,
    refreshCustomFields
  };
}