import { Plus } from 'lucide-react';

interface DashboardEmptyStateProps {
  onCustomize: () => void;
}

export function DashboardEmptyState({ onCustomize }: DashboardEmptyStateProps) {
  return (
    <div className="text-center py-12">
      <h3 className="mt-2 text-sm font-semibold text-gray-900">No widgets visible</h3>
      <p className="mt-1 text-sm text-gray-500">
        Add widgets to your dashboard to get started
      </p>
      <div className="mt-6">
        <button
          onClick={onCustomize}
          className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-brand-600 hover:bg-brand-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
        >
          <Plus className="w-4 h-4 mr-2" />
          Customize Dashboard
        </button>
      </div>
    </div>
  );
}