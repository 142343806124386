import { useMemo } from 'react';
import { useTaskList } from '../../../hooks/task';
import { DashboardWidget } from '../DashboardWidget';
import { TaskCard } from '../../task/TaskCard';
import { useAuth0 } from '@auth0/auth0-react';

export function MyTasksWidget() {
  const { user } = useAuth0();
  const { tasks } = useTaskList({ assignedToMe: true });

  const groupedTasks = useMemo(() => {
    return tasks.reduce((acc, task) => {
      acc[task.status] = [...(acc[task.status] || []), task];
      return acc;
    }, {} as Record<string, typeof tasks>);
  }, [tasks]);

  return (
    <DashboardWidget title="My Tasks">
      <div className="space-y-4">
        {Object.entries(groupedTasks).map(([status, tasks]) => (
          <div key={status}>
            <h4 className="text-sm font-medium text-gray-700 mb-2 capitalize">
              {status.replace('_', ' ')}
            </h4>
            <div className="space-y-2">
              {tasks.map(task => (
                <TaskCard
                  key={task.id}
                  task={task}
                  isCompact
                />
              ))}
            </div>
          </div>
        ))}
      </div>
    </DashboardWidget>
  );
}