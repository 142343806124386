import { ReactNode, useState, useRef, useEffect } from 'react';
import { ChevronDown, ChevronUp, Maximize2, Minimize2 } from 'lucide-react';
import { cn } from '../../lib/utils';

interface DashboardWidgetProps {
  title: string;
  children: ReactNode;
  className?: string;
  maxHeight?: number;
  defaultExpanded?: boolean;
  showExpandButton?: boolean;
}

export function DashboardWidget({ 
  title, 
  children, 
  className,
  maxHeight = 400,
  defaultExpanded = false,
  showExpandButton = true
}: DashboardWidgetProps) {
  const [isExpanded, setIsExpanded] = useState(defaultExpanded);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [hasOverflow, setHasOverflow] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const checkOverflow = () => {
      if (contentRef.current) {
        const hasContentOverflow = contentRef.current.scrollHeight > maxHeight;
        setHasOverflow(hasContentOverflow && showExpandButton);
      }
    };

    checkOverflow();
    const resizeObserver = new ResizeObserver(checkOverflow);
    if (contentRef.current) {
      resizeObserver.observe(contentRef.current);
    }

    return () => resizeObserver.disconnect();
  }, [maxHeight, showExpandButton]);

  return (
    <div 
      className={cn(
        'bg-white rounded-lg border border-gray-200 transition-all duration-300',
        isHovered && 'shadow-md',
        isFullscreen && 'fixed inset-4 z-50 overflow-auto',
        className
      )}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {/* Header */}
      <div className={cn(
        'px-6 py-4 border-b border-gray-200',
        'flex items-center justify-between',
        'transition-colors duration-200',
        isHovered && 'bg-gray-50'
      )}>
        <h3 className="text-lg font-medium text-gray-900">{title}</h3>
        <div className="flex items-center space-x-2">
          {showExpandButton && (
            <button
              onClick={() => setIsFullscreen(!isFullscreen)}
              className="p-1 text-gray-400 hover:text-gray-600 rounded-full hover:bg-gray-100 transition-colors"
            >
              {isFullscreen ? (
                <Minimize2 className="w-4 h-4" />
              ) : (
                <Maximize2 className="w-4 h-4" />
              )}
            </button>
          )}
        </div>
      </div>
      
      {/* Content */}
      <div 
        className={cn(
          'overflow-hidden transition-all duration-300',
          !isExpanded && !isFullscreen && 'max-h-[400px]'
        )}
      >
        <div 
          ref={contentRef} 
          className={cn(
            'p-6',
            isHovered && 'bg-white/50'
          )}
        >
          {children}
        </div>
      </div>

      {/* Expand/Collapse Button */}
      {hasOverflow && !isFullscreen && (
        <div className={cn(
          'px-6 py-2 border-t border-gray-200',
          'transition-colors duration-200',
          isHovered && 'bg-gray-50'
        )}>
          <button
            onClick={() => setIsExpanded(!isExpanded)}
            className="w-full flex items-center justify-center text-sm text-gray-500 hover:text-gray-700 transition-colors"
          >
            {isExpanded ? (
              <>
                <ChevronUp className="w-4 h-4 mr-1" />
                Show Less
              </>
            ) : (
              <>
                <ChevronDown className="w-4 h-4 mr-1" />
                Show More
              </>
            )}
          </button>
        </div>
      )}
    </div>
  );
}