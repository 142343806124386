import { Link, useLocation } from 'react-router-dom';
import { 
  LayoutDashboard, 
  Users,
  Settings, 
  Building,
  HelpCircle,
  Menu,
  CheckSquare,
  CreditCard
} from 'lucide-react';
import { PermissionGate } from './permissions';
import { TASK_PERMISSIONS, USER_PERMISSIONS, ORGANIZATION_PERMISSIONS } from '../types/permission';
import { usePermissions } from '../hooks/usePermissions';

interface SidebarProps {
  isOpen: boolean;
  toggleSidebar: () => void;
}

export default function Sidebar({ isOpen, toggleSidebar }: SidebarProps) {
  const location = useLocation();
  const { userRole, permissions } = usePermissions();

  console.log('[Sidebar] Current state:', {
    userRole,
    permissions,
    currentPath: location.pathname
  });

  const menuItems = [
    { 
      path: '/dashboard', 
      icon: LayoutDashboard, 
      label: 'Dashboard' 
    },
    { 
      path: '/tasks', 
      icon: CheckSquare, 
      label: 'Tasks',
      permission: TASK_PERMISSIONS.READ_ASSIGNED
    },
    { 
      path: '/users', 
      icon: Users, 
      label: 'Team', 
      permission: USER_PERMISSIONS.MANAGE_USERS
    },
    { 
      path: '/organization-settings', 
      icon: Building, 
      label: 'Organization', 
      permission: ORGANIZATION_PERMISSIONS.MANAGE_ORGANIZATION
    },
    {
      path: '/subscription',
      icon: CreditCard,
      label: 'Subscription',
      permission: ORGANIZATION_PERMISSIONS.MANAGE_BILLING
    },
    { 
      path: '/settings', 
      icon: Settings, 
      label: 'Settings' 
    },
    { 
      path: '/support', 
      icon: HelpCircle, 
      label: 'Help & Support' 
    },
  ];

  return (
    <div className={`fixed top-0 left-0 h-full bg-white shadow-lg transition-all duration-300 ${isOpen ? 'w-64' : 'w-20'}`}>
      <div className="flex items-center justify-between h-16 px-4 border-b">
        <Link to="/" className="flex items-center space-x-2">
          {isOpen ? (
            <>
              <Building className="w-8 h-8 text-brand-600" />
              <div className="text-brand-700 font-bold text-xl">SaaS App</div>
            </>
          ) : (
            <Building className="w-8 h-8 text-brand-600" />
          )}
        </Link>
        <button onClick={toggleSidebar} className="p-2 rounded-lg hover:bg-gray-100">
          <Menu className="w-5 h-5 text-gray-500" />
        </button>
      </div>

      <nav className="p-4">
        <ul className="space-y-2">
          {menuItems.map((item) => {
            const Icon = item.icon;
            const isActive = location.pathname === item.path;
            
            console.log('[Sidebar] Menu item:', {
              path: item.path,
              permission: item.permission,
              isActive
            });

            const menuItem = (
              <Link
                to={item.path}
                className={`flex items-center space-x-2 p-3 rounded-lg transition-colors ${
                  isActive 
                    ? 'bg-brand-50 text-brand-700' 
                    : 'text-gray-600 hover:bg-gray-50'
                }`}
              >
                <Icon className="w-5 h-5" />
                {isOpen && <span>{item.label}</span>}
              </Link>
            );

            return (
              <li key={item.path}>
                {item.permission ? (
                  <PermissionGate permission={item.permission}>
                    {menuItem}
                  </PermissionGate>
                ) : (
                  menuItem
                )}
              </li>
            );
          })}
        </ul>
      </nav>
    </div>
  );
}