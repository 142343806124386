import { forwardRef } from 'react';
import { cn } from '../../../lib/utils';

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  error?: string;
  className?: string;
}

export const Input = forwardRef<HTMLInputElement, InputProps>(({
  label,
  error,
  className,
  ...props
}, ref) => {
  return (
    <div className={className}>
      {label && (
        <label 
          htmlFor={props.id} 
          className="block text-sm font-medium text-gray-700 mb-1"
        >
          {label}
          {props.required && <span className="text-red-500 ml-1">*</span>}
        </label>
      )}
      <input
        ref={ref}
        {...props}
        className={cn(
          "block w-full rounded-md shadow-sm sm:text-sm",
          "border border-gray-300 focus:border-brand-500 focus:ring-brand-500",
          "placeholder:text-gray-400",
          "h-12 px-4", // Increased height and padding
          error && "border-red-300 focus:border-red-500 focus:ring-red-500"
        )}
      />
      {error && (
        <p className="mt-1 text-sm text-red-600">{error}</p>
      )}
    </div>
  );
});

Input.displayName = 'Input';