import { DashboardWidget } from '../../DashboardWidget';
import { PermissionGate } from '../../../permissions';
import { USER_PERMISSIONS } from '../../../../types/permission';
import { WorkloadDateRange } from './components/WorkloadDateRange';
import { WorkloadCard } from './components/WorkloadCard';
import { useTeamWorkload } from './hooks/useTeamWorkload';

export function TeamWorkloadWidget() {
  const {
    workloadData,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    isLoading
  } = useTeamWorkload();

  return (
    <PermissionGate permission={USER_PERMISSIONS.MANAGE_USERS}>
      <DashboardWidget 
        title="Team Workload"
        maxHeight={500}
        showExpandButton={true}
      >
        <div className="space-y-4">
          {/* Date Range Picker */}
          <WorkloadDateRange
            startDate={startDate}
            endDate={endDate}
            onStartDateChange={setStartDate}
            onEndDateChange={setEndDate}
          />
          
          {/* Team Member List */}
          <div className="space-y-3 overflow-y-auto max-h-[350px]">
            {isLoading ? (
              <div className="animate-pulse space-y-3">
                {[...Array(3)].map((_, i) => (
                  <div key={i} className="h-20 bg-gray-100 rounded-lg" />
                ))}
              </div>
            ) : workloadData.length > 0 ? (
              workloadData.map((data) => (
                <WorkloadCard key={data.user.id} data={data} />
              ))
            ) : (
              <div className="text-center py-8 text-gray-500">
                No team members found
              </div>
            )}
          </div>
        </div>
      </DashboardWidget>
    </PermissionGate>
  );
}