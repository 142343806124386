import { createContext, useContext, useCallback, ReactNode } from 'react';
import toast from 'react-hot-toast';
import { ToastType } from '../components/common/Toast/types';

interface ToastContextType {
  showToast: (message: string, type?: ToastType) => void;
}

const ToastContext = createContext<ToastContextType | undefined>(undefined);

export function ToastProvider({ children }: { children: ReactNode }) {
  const showToast = useCallback((message: string, type: ToastType = 'success') => {
    switch (type) {
      case 'success':
        toast.success(message);
        break;
      case 'error':
        toast.error(message);
        break;
      case 'warning':
      case 'info':
        toast(message, {
          icon: type === 'warning' ? '⚠️' : 'ℹ️',
          style: {
            backgroundColor: type === 'warning' ? '#FEF3C7' : '#E0F2FE',
            color: type === 'warning' ? '#92400E' : '#1E40AF'
          }
        });
        break;
      default:
        toast(message);
    }
  }, []);

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
    </ToastContext.Provider>
  );
}

export function useToast() {
  const context = useContext(ToastContext);
  if (context === undefined) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
}