import { Building, Users, Building2, CreditCard, Mail, Settings2, Sliders } from 'lucide-react';
import { ORGANIZATION_PERMISSIONS } from '../../../types/permission';
import type { NavigationCategory } from './types';

export const navigationConfig: NavigationCategory[] = [
  {
    category: 'Organization',
    icon: Building,
    items: [
      { id: 'details', name: 'Details' },
      { id: 'sub-organizations', name: 'Sub-Organizations', icon: Building2 }
    ]
  },
  {
    category: 'Members',
    icon: Users,
    items: [
      { id: 'members', name: 'Team Members' },
      { id: 'invitations', name: 'Pending Invitations' }
    ]
  },
  {
    category: 'Configuration',
    icon: Settings2,
    items: [
      { id: 'custom-fields', name: 'Custom Fields' },
      { 
        id: 'features', 
        name: 'Features', 
        icon: Sliders,
        requiresPermission: true,
        permission: ORGANIZATION_PERMISSIONS.MANAGE_ORGANIZATION
      }
    ]
  },
  {
    category: 'Communications',
    icon: Mail,
    items: [
      { id: 'email', name: 'Email Settings' },
      { id: 'templates', name: 'Email Templates' }
    ]
  },
  {
    category: 'Billing',
    icon: CreditCard,
    items: [
      { id: 'billing', name: 'Subscription & Usage' }
    ]
  }
];