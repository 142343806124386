import { z } from 'zod';

export const SubtaskFormSchema = z.object({
  title: z.string()
    .min(3, 'Title must be at least 3 characters')
    .max(200, 'Title must be less than 200 characters'),
  description: z.string()
    .min(1, 'Description is required'),
  status: z.enum(['todo', 'in_progress', 'blocked', 'completed'] as const),
  priority: z.enum(['low', 'medium', 'high'] as const),
  visibility: z.enum(['public'] as const).default('public'),
  dueDate: z.string().nullable(),
  assignedTo: z.number().nullable()
});