import { cn } from '../../../lib/utils';
import { navigationConfig } from './config';
import { NavigationItem } from './NavigationItem';
import type { SettingsNavigationProps } from './types';

export function SettingsNavigation({ activeTab, onTabChange }: SettingsNavigationProps) {
  return (
    <nav className="w-64 bg-white border-r border-gray-200">
      <div className="h-full py-6">
        {navigationConfig.map((category) => (
          <div key={category.category} className="px-3 mb-6">
            <h3 className="flex items-center px-3 text-xs font-semibold text-gray-500 uppercase tracking-wider">
              <category.icon className="w-4 h-4 mr-2" />
              {category.category}
            </h3>
            <div className="mt-2 space-y-1">
              {category.items.map((item) => (
                <NavigationItem
                  key={item.id}
                  item={item}
                  isActive={activeTab === item.id}
                  onClick={() => onTabChange(item.id)}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
    </nav>
  );
}