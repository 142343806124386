import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { Task } from '../../types/task';
import { SubtaskFormData } from '../../components/task/SubtaskForm/types';

export function useSubtaskMutations(taskId: number) {
  const { getAccessTokenSilently } = useAuth0();
  const endpoint = `${import.meta.env.VITE_API_URL}/api/tasks/${taskId}/subtasks`;

  const createSubtask = async (data: SubtaskFormData): Promise<Task> => {
    console.log('[useSubtaskMutations] Creating subtask:', {
      taskId,
      data,
      endpoint
    });

    try {
      const token = await getAccessTokenSilently();
      
      // Ensure subtask specific fields are set
      const payload = {
        ...data,
        parentId: taskId,
        visibility: 'public' // Subtasks are always public within their parent task
      };

      console.log('[useSubtaskMutations] Request payload:', payload);

      const response = await axios.post(
        endpoint,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );

      console.log('[useSubtaskMutations] Response:', {
        status: response.status,
        data: response.data
      });

      return response.data;
    } catch (error: any) {
      console.error('[useSubtaskMutations] Error creating subtask:', {
        error: error.response?.data || error.message,
        status: error.response?.status
      });
      throw new Error(error.response?.data?.message || 'Failed to create subtask');
    }
  };

  const updateSubtask = async (subtaskId: number, data: Partial<SubtaskFormData>): Promise<Task> => {
    console.log('[useSubtaskMutations] Updating subtask:', {
      taskId,
      subtaskId,
      data
    });

    try {
      const token = await getAccessTokenSilently();
      
      // Ensure subtask specific fields are preserved
      const payload = {
        ...data,
        parentId: taskId,
        visibility: 'public'
      };

      const response = await axios.put(
        `${endpoint}/${subtaskId}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );

      console.log('[useSubtaskMutations] Update response:', {
        status: response.status,
        data: response.data
      });

      return response.data;
    } catch (error: any) {
      console.error('[useSubtaskMutations] Error updating subtask:', {
        error: error.response?.data || error.message,
        status: error.response?.status
      });
      throw new Error(error.response?.data?.message || 'Failed to update subtask');
    }
  };

  const deleteSubtask = async (subtaskId: number): Promise<void> => {
    console.log('[useSubtaskMutations] Deleting subtask:', {
      taskId,
      subtaskId
    });

    try {
      const token = await getAccessTokenSilently();
      await axios.delete(
        `${endpoint}/${subtaskId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      console.log('[useSubtaskMutations] Subtask deleted successfully');
    } catch (error: any) {
      console.error('[useSubtaskMutations] Error deleting subtask:', {
        error: error.response?.data || error.message,
        status: error.response?.status
      });
      throw new Error(error.response?.data?.message || 'Failed to delete subtask');
    }
  };

  const duplicateSubtask = async (subtaskId: number): Promise<Task> => {
    console.log('[useSubtaskMutations] Duplicating subtask:', {
      taskId,
      subtaskId
    });

    try {
      const token = await getAccessTokenSilently();
      const response = await axios.post(
        `${endpoint}/${subtaskId}/duplicate`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );

      console.log('[useSubtaskMutations] Duplication response:', {
        status: response.status,
        data: response.data
      });

      return response.data;
    } catch (error: any) {
      console.error('[useSubtaskMutations] Error duplicating subtask:', {
        error: error.response?.data || error.message,
        status: error.response?.status
      });
      throw new Error(error.response?.data?.message || 'Failed to duplicate subtask');
    }
  };

  return {
    createSubtask,
    updateSubtask,
    deleteSubtask,
    duplicateSubtask
  };
}