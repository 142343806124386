import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ChevronLeft, Loader2, AlertCircle } from 'lucide-react';
import { TaskDetails } from '../../components/task/TaskDetails';
import type { Task } from '../../types/task';

export function TaskSharePage() {
  const { token } = useParams<{ token: string }>();
  const navigate = useNavigate();
  const [task, setTask] = useState<Task | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchTask = async () => {
      try {
        const response = await axios.get(
          `${import.meta.env.VITE_API_URL}/api/tasks/share/${token}`
        );
        setTask(response.data);
      } catch (error: any) {
        setError(error.response?.data?.message || 'Failed to load task');
      } finally {
        setIsLoading(false);
      }
    };

    fetchTask();
  }, [token]);

  const handleBack = () => {
    navigate('/tasks');
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center min-h-[400px]">
        <Loader2 className="w-8 h-8 animate-spin text-brand-600" />
      </div>
    );
  }

  if (error || !task) {
    return (
      <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="bg-red-50 p-4 rounded-md">
          <div className="flex">
            <AlertCircle className="h-5 w-5 text-red-400" />
            <div className="ml-3">
              <h3 className="text-sm font-medium text-red-800">
                {error || 'Task not found'}
              </h3>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <button
        onClick={handleBack}
        className="mb-6 inline-flex items-center text-sm text-gray-500 hover:text-gray-700"
      >
        <ChevronLeft className="w-4 h-4 mr-1" />
        Back to Tasks
      </button>

      <div className="bg-yellow-50 border border-yellow-100 rounded-lg p-4 mb-6">
        <p className="text-sm text-yellow-800">
          You are viewing this task through a shared link. Some actions may be restricted.
        </p>
      </div>

      <TaskDetails 
        task={task}
        readOnly={false} // Set to false to allow guest comments
      />
    </div>
  );
}