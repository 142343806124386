import { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { TaskHistoryEntry } from '../../components/task/TaskHistory/types';

export function useTaskHistory(taskId: number) {
  const { getAccessTokenSilently } = useAuth0();
  const { token } = useParams<{ token?: string }>();
  const [history, setHistory] = useState<TaskHistoryEntry[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchHistory = async () => {
      setIsLoading(true);
      setError(null);

      try {
        let response;
        if (token) {
          // Use magic link endpoint
          response = await axios.get(
            `${import.meta.env.VITE_API_URL}/api/tasks/share/${token}/history`
          );
        } else {
          // Use authenticated endpoint
          const authToken = await getAccessTokenSilently();
          response = await axios.get(
            `${import.meta.env.VITE_API_URL}/api/tasks/${taskId}/history`,
            {
              headers: {
                Authorization: `Bearer ${authToken}`
              }
            }
          );
        }
        setHistory(response.data);
      } catch (error: any) {
        setError(error.response?.data?.message || 'Failed to fetch history');
        console.error('Error fetching history:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchHistory();
  }, [taskId, token, getAccessTokenSilently]);

  return {
    history,
    isLoading,
    error
  };
}