import { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { Task } from '../../types/task';

export function useTask(taskId: number | null) {
  const { getAccessTokenSilently } = useAuth0();
  const [task, setTask] = useState<Task | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!taskId) return;

    const fetchTask = async () => {
      setIsLoading(true);
      setError(null);

      try {
        const token = await getAccessTokenSilently();
        const response = await axios.get(
          `${import.meta.env.VITE_API_URL}/api/tasks/${taskId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
        setTask(response.data);
      } catch (error: any) {
        setError(error.response?.data?.message || 'Failed to fetch task');
        console.error('Error fetching task:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTask();
  }, [taskId, getAccessTokenSilently]);

  return { task, isLoading, error };
}