import React from 'react';
import { Shield } from 'lucide-react';
import { ROLES } from '../types/auth';
import { useAuth0 } from '@auth0/auth0-react';

interface UserRoleSelectProps {
  currentRole: string;
  userId: string;
  onRoleChange: (userId: string, newRole: string) => void;
  isOwner: boolean;
}

export default function UserRoleSelect({ currentRole, userId, onRoleChange, isOwner }: UserRoleSelectProps) {
  const { user } = useAuth0();
  const userRole = user?.['https://auth-sync-api/role'] || 'member';

  // Determine which roles can be assigned based on current user's role
  const availableRoles = Object.entries(ROLES).filter(([role, _]) => {
    if (userRole === 'owner') return role !== 'owner'; // Owner can assign any role except owner
    if (userRole === 'admin') return !['owner', 'admin'].includes(role); // Admin can only assign lower roles
    return false; // Other roles cannot assign roles
  });

  const handleRoleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newRole = e.target.value;
    if (window.confirm(`Are you sure you want to change this user's role to ${newRole}?`)) {
      onRoleChange(userId, newRole);
    }
  };

  const canModifyRole = !isOwner && (userRole === 'owner' || (userRole === 'admin' && currentRole !== 'admin'));

  return (
    <div className="flex items-center space-x-2">
      <Shield className="w-4 h-4 text-brand-600" />
      <select
        value={currentRole}
        onChange={handleRoleChange}
        disabled={!canModifyRole}
        className={`block w-full pl-3 pr-10 py-2 text-sm border-gray-300 focus:outline-none focus:ring-brand-500 focus:border-brand-500 rounded-md ${
          !canModifyRole ? 'bg-gray-50 cursor-not-allowed' : ''
        }`}
      >
        <option value={currentRole}>
          {ROLES[currentRole as keyof typeof ROLES].name.charAt(0).toUpperCase() + 
           ROLES[currentRole as keyof typeof ROLES].name.slice(1)}
        </option>
        {canModifyRole && availableRoles.map(([role, definition]) => (
          role !== currentRole && (
            <option key={role} value={role}>
              {definition.name.charAt(0).toUpperCase() + definition.name.slice(1)}
            </option>
          )
        ))}
      </select>
    </div>
  );
}