import { useState } from 'react';
import { format } from 'date-fns';
import { 
  Calendar, 
  User, 
  Eye,
  Edit2, 
  Trash2, 
  MessageSquare,
  ChevronDown,
  Copy,
  Link2
} from 'lucide-react';
import { cn } from '../../../lib/utils';
import { getTaskDisplayId } from '../../../utils/formatters';
import { TaskDetailsProps } from './types';
import { TASK_STATUSES, TASK_PRIORITIES, TASK_VISIBILITY } from '../../../types/task';
import { TaskComments } from '../TaskComments';
import { TaskHistory } from '../TaskHistory';
import { TaskSubtasks } from '../TaskSubtasks';
import { useTabState } from '../../../hooks/useTabState';
import { useTaskMutations } from '../../../hooks/task';
import { useToast } from '../../../context/ToastContext';
import { MagicLink } from '../MagicLink';
import Modal from '../../common/Modal';

export function TaskDetails({ 
  task, 
  onEdit, 
  onDelete,
  onDuplicate,
  className,
  readOnly = false
}: TaskDetailsProps) {
  const [activeTab, setActiveTab] = useTabState('taskDetails', 'subtasks');
  const { duplicateTask } = useTaskMutations();
  const { showToast } = useToast();
  const [showMagicLinkModal, setShowMagicLinkModal] = useState(false);

  // Early return if task is not loaded
  if (!task) {
    return null;
  }

  const status = TASK_STATUSES[task.status];
  const priority = TASK_PRIORITIES[task.priority];
  const visibility = TASK_VISIBILITY[task.visibility];

  const tabs = [
    { id: 'subtasks', label: 'Subtasks', icon: ChevronDown },
    { id: 'comments', label: 'Comments', icon: MessageSquare },
    { id: 'history', label: 'History', icon: Calendar }
  ] as const;

  const handleDuplicate = async () => {
    try {
      const duplicatedTask = await duplicateTask(task.id);
      showToast('Task duplicated successfully', 'success');
      onDuplicate?.(duplicatedTask);
    } catch (error) {
      console.error('Failed to duplicate task:', error);
      showToast('Failed to duplicate task', 'error');
    }
  };

  // Safely access customFieldValues with fallback
  const customFieldValues = task.customFieldValues || {};

  return (
    <div className={cn('bg-white rounded-lg border border-gray-200', className)}>
      {/* Header */}
      <div className="px-6 py-4 border-b border-gray-200">
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center space-x-2">
            {/* Task ID */}
            <div className="px-2 py-1 bg-gray-100 rounded text-xs font-mono text-gray-600">
              {getTaskDisplayId(task)}
            </div>
            <span
              className={cn(
                'inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium',
                `bg-${status.color}-100 text-${status.color}-800`
              )}
            >
              {status.label}
            </span>
            <span
              className={cn(
                'inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium',
                `bg-${priority.color}-100 text-${priority.color}-800`
              )}
            >
              {priority.label}
            </span>
          </div>
          {!readOnly && (
            <div className="flex items-center space-x-2">
              <button
                onClick={() => setShowMagicLinkModal(true)}
                className="p-1 text-gray-400 hover:text-gray-500"
                title="Share link"
              >
                <Link2 className="w-4 h-4" />
              </button>
              <button
                onClick={handleDuplicate}
                className="p-1 text-gray-400 hover:text-gray-500"
                title="Duplicate task"
              >
                <Copy className="w-4 h-4" />
              </button>
              {onEdit && (
                <button
                  onClick={onEdit}
                  className="p-1 text-gray-400 hover:text-gray-500"
                  title="Edit task"
                >
                  <Edit2 className="w-4 h-4" />
                </button>
              )}
              {onDelete && (
                <button
                  onClick={onDelete}
                  className="p-1 text-gray-400 hover:text-red-500"
                  title="Delete task"
                >
                  <Trash2 className="w-4 h-4" />
                </button>
              )}
            </div>
          )}
        </div>

        <h2 className="text-xl font-semibold text-gray-900 mb-2">
          {task.title}
        </h2>

        <div className="flex items-center space-x-4 text-sm text-gray-500">
          {task.dueDate && (
            <div className="flex items-center">
              <Calendar className="w-4 h-4 mr-1" />
              {format(new Date(task.dueDate), 'PPP')}
            </div>
          )}
          <div className="flex items-center">
            <User className="w-4 h-4 mr-1" />
            {task.assignedTo ? (
              task.assignee ? 
                `${task.assignee.firstName || ''} ${task.assignee.lastName || ''}`.trim() || task.assignee.email
                : 'Loading...'
            ) : (
              'Unassigned'
            )}
          </div>
          <div className="flex items-center">
            <Eye className="w-4 h-4 mr-1" />
            {visibility.label}
          </div>
        </div>
      </div>

      {/* Description */}
      <div className="px-6 py-4 border-b border-gray-200">
        <h3 className="text-sm font-medium text-gray-900 mb-2">Description</h3>
        <div className="prose prose-sm max-w-none">
          {task.description}
        </div>
      </div>

      {/* Custom Fields */}
      {Object.keys(customFieldValues).length > 0 && (
        <div className="px-6 py-4 border-b border-gray-200">
          <h3 className="text-sm font-medium text-gray-900 mb-2">Additional Fields</h3>
          <dl className="grid grid-cols-2 gap-4">
            {Object.entries(customFieldValues).map(([fieldId, value]) => (
              <div key={fieldId}>
                <dt className="text-sm font-medium text-gray-500">{fieldId}</dt>
                <dd className="mt-1 text-sm text-gray-900">{String(value)}</dd>
              </div>
            ))}
          </dl>
        </div>
      )}

      {/* Tabs */}
      <div className="border-b border-gray-200">
        <nav className="flex -mb-px">
          {tabs.map((tab) => {
            const Icon = tab.icon;
            return (
              <button
                key={tab.id}
                onClick={() => setActiveTab(tab.id)}
                className={cn(
                  'flex-1 px-4 py-3 text-sm font-medium text-center border-b-2',
                  activeTab === tab.id
                    ? 'border-brand-500 text-brand-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                )}
              >
                <Icon className="w-4 h-4 mx-auto mb-1" />
                {tab.label}
              </button>
            );
          })}
        </nav>
      </div>

      {/* Tab Content */}
      <div className="p-6">
        {activeTab === 'subtasks' && <TaskSubtasks taskId={task.id} readOnly={readOnly} />}
        {activeTab === 'comments' && <TaskComments taskId={task.id} readOnly={readOnly} />}
        {activeTab === 'history' && <TaskHistory taskId={task.id} />}
      </div>

      {/* Magic Link Modal */}
      <Modal
        isOpen={showMagicLinkModal}
        onClose={() => setShowMagicLinkModal(false)}
        title="Share Task"
      >
        <MagicLink taskId={task.id} />
      </Modal>
    </div>
  );
}