import { useMemo } from 'react';
import { useTaskList } from '../../../hooks/task';
import { TaskStatus, TASK_STATUSES } from '../../../types/task';
import { DashboardWidget } from '../DashboardWidget';
import { cn } from '../../../lib/utils';

export function TaskOverviewWidget() {
  const { tasks } = useTaskList();

  const stats = useMemo(() => {
    const total = tasks.length;
    const byStatus = tasks.reduce((acc, task) => {
      acc[task.status] = (acc[task.status] || 0) + 1;
      return acc;
    }, {} as Record<TaskStatus, number>);

    return {
      total,
      completed: byStatus.completed || 0,
      inProgress: byStatus.in_progress || 0,
      blocked: byStatus.blocked || 0,
      todo: byStatus.todo || 0
    };
  }, [tasks]);

  return (
    <DashboardWidget title="Task Overview">
      <div className="grid grid-cols-2 gap-4">
        {Object.entries(TASK_STATUSES).map(([status, config]) => {
          const count = stats[status as TaskStatus] || 0;
          const percentage = stats.total ? Math.round((count / stats.total) * 100) : 0;

          return (
            <div key={status} className="p-4 bg-gray-50 rounded-lg">
              <div className="flex justify-between items-center mb-2">
                <span className="text-sm font-medium text-gray-700">
                  {config.label}
                </span>
                <span className="text-sm text-gray-500">
                  {percentage}%
                </span>
              </div>
              <div className="w-full bg-gray-200 rounded-full h-2">
                <div
                  className={cn(
                    'h-2 rounded-full',
                    `bg-${config.color}-500`
                  )}
                  style={{ width: `${percentage}%` }}
                />
              </div>
              <div className="mt-1 text-sm text-gray-600">
                {count} tasks
              </div>
            </div>
          );
        })}
      </div>
    </DashboardWidget>
  );
}