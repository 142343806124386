import { User, MessageSquare } from 'lucide-react';
import { cn } from '../../../../lib/utils';
import { TASK_STATUSES, TASK_PRIORITIES } from '../../../../types/task';
import { getTaskDisplayId } from '../../../../utils/formatters';
import type { Task } from '../../../../types/task';

interface TimelineTaskProps {
  task: Task;
  onClick?: () => void;
}

export function TimelineTask({ task, onClick }: TimelineTaskProps) {
  const status = TASK_STATUSES[task.status];
  const priority = TASK_PRIORITIES[task.priority];

  return (
    <div
      onClick={onClick}
      className={cn(
        'p-2 rounded-md text-sm cursor-pointer transition-all',
        'hover:ring-2 hover:ring-brand-500 hover:ring-opacity-50',
        `bg-${status.color}-50`
      )}
    >
      {/* Task Header */}
      <div className="flex items-center justify-between mb-1">
        <span className="text-xs font-mono text-gray-500">
          {getTaskDisplayId(task)}
        </span>
        <span className={cn(
          'px-1.5 py-0.5 rounded-full text-xs font-medium',
          `bg-${priority.color}-100 text-${priority.color}-800`
        )}>
          {priority.label}
        </span>
      </div>

      {/* Task Title */}
      <div className="font-medium text-gray-900 truncate mb-1">
        {task.title}
      </div>

      {/* Task Metadata */}
      <div className="flex items-center justify-between text-xs text-gray-500">
        <div className="flex items-center space-x-2">
          {task.assignee ? (
            <div className="flex items-center">
              <User className="w-3 h-3 mr-1" />
              <span className="truncate max-w-[100px]">
                {task.assignee.firstName || task.assignee.email}
              </span>
            </div>
          ) : (
            <div className="flex items-center text-gray-400">
              <User className="w-3 h-3 mr-1" />
              <span>Unassigned</span>
            </div>
          )}
        </div>

        {task.comments && task.comments.length > 0 && (
          <div className="flex items-center">
            <MessageSquare className="w-3 h-3 mr-1" />
            <span>{task.comments.length}</span>
          </div>
        )}
      </div>
    </div>
  );
}