import { z } from 'zod';
import { CustomFieldType } from '../../../types/task';

export const CustomFieldFormSchema = z.object({
  name: z.string()
    .min(2, 'Name must be at least 2 characters')
    .max(100, 'Name must be less than 100 characters'),
  description: z.string()
    .min(1, 'Description is required'),
  type: z.enum([
    'text', 'textarea', 'richtext', 'url', 'email', 'phone',
    'number', 'currency', 'percentage', 'rating',
    'date', 'time', 'datetime', 'duration',
    'select', 'multiselect', 'radio', 'checkbox',
    'user', 'multiuser', 'formula', 'location', 'file', 'image'
  ] as const),
  required: z.boolean(),
  config: z.object({
    options: z.array(z.object({
      label: z.string(),
      value: z.string()
    })).optional(),
    validation: z.object({
      min: z.number().optional(),
      max: z.number().optional(),
      pattern: z.string().optional(),
      unique: z.boolean().optional()
    }).optional(),
    formatting: z.object({
      prefix: z.string().optional(),
      suffix: z.string().optional(),
      decimals: z.number().optional(),
      currency: z.string().optional()
    }).optional(),
    display: z.object({
      placeholder: z.string().optional(),
      hint: z.string().optional(),
      width: z.string().optional()
    }).optional()
  }).default({})
});