import { useState } from 'react';
import { Loader2, AlertCircle } from 'lucide-react';
import { TaskKanbanProps } from './types';
import { KanbanColumn } from './KanbanColumn';
import { TASK_STATUSES, TaskStatus } from '../../../../types/task';
import { PermissionGate } from '../../../permissions';
import { TASK_PERMISSIONS } from '../../../../types/permission';

export default function TaskKanban({
  tasks,
  onTaskClick,
  onTaskStatusChange,
  isLoading,
  error
}: TaskKanbanProps) {
  const [draggingTaskId, setDraggingTaskId] = useState<number | null>(null);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center py-8">
        <Loader2 className="w-8 h-8 text-brand-600 animate-spin" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="rounded-md bg-red-50 p-4">
        <div className="flex">
          <AlertCircle className="h-5 w-5 text-red-400" />
          <div className="ml-3">
            <h3 className="text-sm font-medium text-red-800">{error}</h3>
          </div>
        </div>
      </div>
    );
  }

  const columns = Object.keys(TASK_STATUSES) as TaskStatus[];

  const getColumnTasks = (status: TaskStatus) => {
    return tasks.filter(task => task.status === status);
  };

  const handleDrop = (taskId: number, newStatus: TaskStatus) => {
    if (onTaskStatusChange) {
      onTaskStatusChange(taskId, newStatus);
    }
  };

  return (
    <div className="flex space-x-4 h-[calc(100vh-12rem)] overflow-hidden">
      {columns.map(status => (
        <KanbanColumn
          key={status}
          status={status}
          tasks={getColumnTasks(status)}
          onDrop={(taskId) => handleDrop(taskId, status)}
          onTaskClick={onTaskClick}
          isDraggingOver={draggingTaskId !== null}
        />
      ))}
    </div>
  );
}